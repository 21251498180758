import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import { Pagination, Typography, PaginationItem } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


  const PaginationList = (props) => {
 
  const handleChange = (event, value) => {
      props.setCurrentPage(value);
      props.fetchData(props.searchValue,props.selectedMenu,props.sortOrder,value)
  };


  return (
     <div className='pagination-list'> 
    
    <Box
                sx={{
                    margin: "auto",
                    width: "fit-content",
                    alignItems: "center",
                }}
            >
                <Typography className="pagination-message" fontSize={14} align="center">
                    Page: {props.currentPage} of {props.nPages}
                </Typography>
          
                <Pagination count={props.nPages} page={props.currentPage} 
                     color="primary" onChange={handleChange}  siblingCount={1} boundaryCount={1}/>
            </Box>

    

    </div>


  );
}

export default PaginationList;
