
import React from "react";

import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import Modal from "react-modal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from "../services/api";
import { UserContext } from "../contexts/UserContext";

import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function Register(props) {



  const [passwordVisible, setPasswordVisible] = useState(false);


  const [inputs, setInputs] = useState({});


  const [isResendOTPButtonVisible,setIsResendOTPButtonVisible]= useState(false)
  
  const [timeLeft, setTimeLeft] = useState(null);
  
const [showOTPField, setShowOTPField] = useState(false)

  const [errorMessage, setErrorMessage] = useState("");
  

  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  useLayoutEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!loading) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [loading]);



  useEffect(() => {
    if(timeLeft===0){
    
       setIsResendOTPButtonVisible(true)
       setTimeLeft(null)
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
  
  function handleChangeCheckbox(e) {
     setChecked(e.target.checked);
  }

  const navigate = useNavigate();




  function validatePhoneAndSendOTP(event) {
    setButtonDisabled(true);
    setLoading(true)
   
    event.preventDefault();
    api
      .validatePhoneAndSendOTP(inputs)
      .then(({ data }) => {
        if(data.error){
          setErrorMessage(data.error);
          setButtonDisabled(false)
          setLoading(false)
          
          return;
        }
        if(data.success){
          setErrorMessage("");
          setInputs((prevState) => ({ ...prevState, verification_key: data.verification_key }));
          setShowOTPField(true)
          setIsResendOTPButtonVisible(false)
          setTimeLeft(5)
          setButtonDisabled(false)
     
          setLoading(false)
        }
      
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error);
        setButtonDisabled(false)
    
        setLoading(false)
      //
      });

  }


  function handleUserRegistration(event) {
    setButtonDisabled(true)
    setLoading(true)
    event.preventDefault();
   
    api
      .register(inputs)
      .then(({ data }) => {
        // console.log(data);
        if(data.error){
          setErrorMessage(data.error);
          setButtonDisabled(false)
          setLoading(false)
          return;
        }
       
        if(data.success){
          console.log(data)
          localStorage.setItem("customerId", JSON.stringify(data.user._id));
          localStorage.setItem("account_id", JSON.stringify(data.user.account._id));
          localStorage.setItem("account_name", JSON.stringify(data.user.account.name));
          localStorage.setItem("phoneNumber", JSON.stringify(data.user.phoneNumber));
          //localStorage.setItem("updatedPoints", JSON.stringify(data.user.account.points));
         // localStorage.setItem("instruments", JSON.stringify(data.user.account.subscribed_instruments));
         // localStorage.setItem("selectedQuantityForTransaction", JSON.stringify(1));
          localStorage.setItem("expirationtime", data.expirationtime);
          console.log("navigating")
          setButtonDisabled(false)
          setLoading(false)
          navigate("/trade");
        }

      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Some error occured");
        setButtonDisabled(false)
        setLoading(false)
        
      });

      }
  // Password Show and hide
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  // Password Show and hide

  return (
  <>  
   {loading && <div className="loader"></div>}
    <div
    className="bg-dark text-white px-4 registration"
    style={{ minHeight: "100vh", paddingTop: "90px" }}
  >
   
<button className="login-back-btn"  onClick={() => props.setDisplayAuthPage("Login")}><ArrowBackIcon/> Back</button>



 <form>
<div className="login-form">


   <div className="register-main " >
        <h4 className="mb-5">Register</h4>
       <div className="error-msg">
        {errorMessage}
        </div>
      </div>

      {!showOTPField &&   
      <div className="py-3 register-sec">
   
        <label>Display Name </label>
        <div className="d-block mb-3">
          <input
            name="displayName"
            className="d-block p-1 bg-light rounded"
          
            label="displayName"
            type="text"
            placeholder="Display Name"
            onChange={handleChange}
            value={inputs.displayName || ''}
            
          /> </div>
           <label>Mobile Number</label>
        <div className="d-block mb-3 phone-field">
        <span className="phone-f">+91</span>

          <input
            name="phoneNumber"
            className="d-block p-1 bg-light rounded"
            type="number"
            onChange={handleChange}
            value={inputs.phoneNumber || ''}
            placeholder=""
            required
          />
          </div>
        
          <label>Password</label>
          <div className="d-block mb-3 password-field">
          <input
            name="password"
            className="d-block p-1 bg-light rounded"
          
            label="password"
            type={passwordVisible ? "text" : "password"}
            placeholder="more than 6 digits alphanumeric"
            onChange={handleChange}
            value={inputs.password || ''}
            required
          /> 
              <span className="password-v" onClick={togglePasswordVisibility}>
        {passwordVisible ? <VisibilityIcon/> : <VisibilityOffIcon/>} 
      </span>
          </div>
           <label> Referral Code (optional)</label>
          <div className="d-block mb-3">
          <input
            name="referralCode"
            className="d-block p-1 bg-light rounded"
          
            label="referralCode"
            type="text"
            placeholder="Have a Referral code ?"
            onChange={handleChange}
            value={inputs.referralCode || ''}
            
          /> </div>
   <div className="d-block mt-2 confiramtion">
<input
            name="emailSubsrciption"
            className="d-block p-1 bg-light rounded"
          
            label="emailSubsrciption"
            type="checkbox"
            onChange={handleChangeCheckbox}
            value={inputs.emailSubsrciption}
            checked = {checked}
            
          /> <span>I want to receive important notifications via WhatsApp & SMS
I agree to <a href="#">terms and condition and privacy policy</a> </span></div>

        <Button  onClick={(event) => validatePhoneAndSendOTP(event)}
            variant="contained"
            className="btn btn-primary reg-btn main-btn"
            type="submit"
            disabled={isButtonDisabled}
          
          >
            Continue
          </Button>




      </div>  }

      {showOTPField &&        <div style={{marginTop: "30px"}}>
        OTP
    <div className="d-block mt-2">
          <input
            name="otp"
            className="d-block p-1 bg-light rounded"
          placeholder="OTP"
            label="otp"
            type="text"
            onChange={handleChange}
            value={inputs.otp || ''}
            
          /> </div> 

<div className="resent-row">
<span className="sent-otp my-3" style={{display: "block"}}><CheckCircleIcon/> OTP Sent</span> 
<span className="resendd">{!isResendOTPButtonVisible && <span className="resending-line">
       Resend OTP in  {timeLeft} seconds
      </span>}
{isResendOTPButtonVisible && 
<span onClick={(event) => validatePhoneAndSendOTP(event) } className="resend-link">Resend OTP</span>}</span>
</div> 

<div className="otp-btn-wrap row align-items-center">


<div className="col-md-6 col-6">
        <a
            variant="contained"
            className="btn btn-primary main-btn resend-btn"
            onClick={(event) => verifyPhoneOTP(event)}
            
          >
            Back
          </a>
          </div>
<div className="col-md-6 col-6">
<Button
            variant="contained"
            className="btn btn-primary main-btn "
            onClick={(event) => handleUserRegistration(event)}
            disabled={isButtonDisabled}
            
          >
            Register
          </Button>
          </div>
          </div>



    </div> }

    </div> 

  

    </form>



 
 

   

    </div>
    </>
  );
}
