import { useEffect } from 'react';
const Zoom = (zoomLevel) => {
    useEffect(() => {
      const initialValue = document.body.style.zoom;
      
      // Change zoom level on mount
      document.body.style.zoom = zoomLevel;
  
      return () => {
        // Restore default value
        document.body.style.zoom = initialValue;
      };
    }, []);
    return <></>;
  };

  export default Zoom