import axios from "axios";
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


const instance = axios.create({
 // baseURL: "https://backend-ftx.onrender.com/api",
  //baseURL: "https://api.xcelfintech.in/api"
 //baseURL: "http://localhost:8000/api",
 baseURL: process.env.REACT_APP_API_BASE_URL
});

const externalInstance = axios.create({
  baseURL: "https://api.coincap.io",
});

// Request interceptor for API calls
/**axios.interceptors.request.use(
  async config => {
    const token = await getTokenSilently();
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);**/

// Response interceptor for API calls
/**axios.interceptors.response.use(
  response => {
    return response.data;
  },
  async function(error) {
    if (error.response?.status === 401 || error?.error === 'login_required') {
     
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((response) => {
  return response;
}, (error) => { // Anything except 2XX goes to here
  console.log(error)
  const status = error.response?.status || 500;
  if (status === 401 || status === 400) {
       window.location = window.location.protocol + "//" + window.location.host + "/login"
  } else {
      return Promise.reject(error); // Delegate error to calling side
  }
});
**/

const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {

      const resInterceptor = response => {
          return response;
      }

      const errInterceptor = error => {

          if (error.response.status === 401 || error.response.status === 400) {
            console.log("redirecting to login")
              navigate('/login',{state:{display_message:"Session Expired"}});
          }

          return Promise.reject(error);
      }


      const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);

      setIsSet(true)

      return () => instance.interceptors.response.eject(interceptor);

      

  }, [navigate])

  return isSet && children;
}





function login(data) {

  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/signin", data, config);
}



function logout(data) {

  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/signout", data, config);
}

function loginGoogle(access_token) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${access_token}`,
    },
    params: {
      access_token: access_token,
    },
  };
  return instance.get("/googleSignIn", config);
}

function register(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/signup", data, config);
}

function validatePhoneAndSendOTP(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/validatePhoneAndSendOTP", data, config);
}

function changePassword(data, customerId, token) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
  };
  data.id = customerId;
  return instance.post("/changepassword", data, config);
}

function updatePassword(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/updatePassword", data, config);
}


function forgotPassword(data, customerId, token) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
  };
  return instance.post("/forgotpassword", data, config);
}


function updateDarkMode(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  
  return instance.post("/updateDarkMode", data, config);
}

function sendOTPToPhone(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/sendOTPToPhone", data, config);
}

function verifyPhoneOTP(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/verifyPhoneOTP", data, config);
}

function resetPassword(data, customerId, token) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
  };

  
  return instance.post("/resetpassword", data, config);
}

function getAccountLists() {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
    params: {
     // customerid: customerid,
    },
  };
  return instance.get("/getAccountLists", config);
}

function getAccount(account_id) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
    params: {
      account_id: account_id,
    },
  };
  return instance.get("/getAccount", config);
}


function getOrders(data, token) {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
    params: {
      customerid: data.customerid,
      account_id: data.account_id,
      status: data.status,
    },
  };
  return instance.get("/getorders", config);
}

function getClosedTrades(data) {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
    params: {
      account_id: data.account_id,
      pageNum:data.pageNum,
      getNpages:data.getNpages,
      sort_property:data.sort_property,
      sort_order:data.sort_order,
      search_value:data.search_value
    },
  };
  return instance.get("/getClosedTrades", config);
}

function getHoldings(data) {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
    params: {
      account_id: data.account_id,
      status: data.status,
    },
  };
  return instance.get("/getholdings", config);
}

function getPositions(data) {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
    params: {
      account_id: data.account_id,
      status: data.status,
    },
  };
  return instance.get("/getPositions", config);
}

function getAccountPnLDetails() {
  //console.log(data);
  let account_id
  if(localStorage.getItem("account_id")){
    account_id = JSON.parse(localStorage.getItem("account_id"))
  }

  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
    params: {
      account_id: account_id,
    },
  };
  return instance.get("/getAccountPnLDetails", config);
}

function getPendingOrders(data) {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
    params: {
      account_id: data.account_id,
      status: data.status,
    },
  };
  return instance.get("/getPendingOrders", config);
}

function getExecutedOrders(data) {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },

    params: {
      account_id: data.account_id,
      pageNum:data.pageNum,
      getNpages:data.getNpages,
      sort_property:data.sort_property,
      sort_order:data.sort_order,
      search_value:data.search_value
    },
  };
  return instance.get("/getExecutedOrders", config);
}

function getUser(customerid, token) {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
  };
  return instance.get(`/user/${customerid}`, config);
}

function placeOrder(data, token) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
  };
  return instance.post("/placeorder", data, config);
}

function submitOrder(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/submitorder", data, config);
}

function cancelOrder(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/cancelOrder", data, config);
}


function closeOrder(data, token) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
  };
  return instance.post("/closeorder", data, config);
}

function addInstrumentToWatchlist(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/addInstrumentToWatchlist", data, config);
}

function removeInstrumentFromWatchlist(data) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.post("/removeInstrumentFromWatchlist", data, config);
}

function getTradeFeeAndTaxes(data) {
  
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
    params: {
      account_id: data.account_id,
      trade_id:data.trade_id
    },
  };
  return instance.get("/getTradeFeeAndTaxes", config);
}


function getAssetRates(data, token) {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
    params: {
      customerid: data.customerid,
      // "status":data.status
    },
  };
  return instance.get("/getassetrates", config);
}

function searchInstruments(searchValue) {
  const token = JSON.parse(localStorage.getItem('token'));
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
    params: {
      searchValue: searchValue,
      // "status":data.status
    },
  };
  return instance.get("/searchinstruments", config);
}



function getAssetLatestRates(data) {


  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
    params: {
      account_id: data.account_id,
      pageNum:data.pageNum,
      sort_property:data.sort_property,
      sort_order:data.sort_order,
      search_value:data.search_value,
      assetType: data.displayPage,
      asset_name:data.selectedAssetName,
      option_type:data.selectedOptionType,
      expiry_month:data.selectedExpiryMonth,

     
    },
  };
  return instance.get("getAssetLatestRates", config);
}

function getLatestRatesForWatchList(assetType) {
  let account_id
   if(localStorage.getItem("account_id")){
     account_id = JSON.parse(localStorage.getItem("account_id"))
   }
   
   const config = {
     withCredentials: true,
     headers: {
       "api-key": "yyt2-bh63-5d6t-77hg",
     },
     params: {
       assetType: assetType,
       account_id:account_id,
     },
   };
   return instance.get("getAssetLatestRates", config);
 }

function fetchLatestPriceForSelectedAsset(asset_symbol) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
     // Authorization: `Bearer ${token}`,
    },
    params: {
      asset_symbol: asset_symbol,
    },
  };
  return instance.get("fetchLatestPriceForSelectedAsset", config);
}


function loadSiteConfigurationFrontend() {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
     // Authorization: `Bearer ${token}`,
    },
  };
  return instance.get("loadSiteConfigurationFrontend", config);
}




function getlatestRates(token) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
  };
  return instance.get("/getlatestrates", config);
}

function getlatestRatesForFTT(token) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
  };
  return instance.get("/getlatestRatesForFTT", config);
}

function getAssetForexRates(token) {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
      Authorization: `Bearer ${token}`,
    },
  };
  return instance.get("/getassetforexrates", config);
}

function getAssetRatesExternalApi() {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      // "api-key": "yyt2-bh63-5d6t-77hg",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Headers": "*",
      //"Access-Control-Allow-Methods": "*"
      //'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
    },
    params: {
      // "customerid":data.customerid,
      // "status":data.status
    },
    raxConfig: {
      retry: 3,
      retryDelay: 500,
    },
  };
  //return externalInstance.get(`/v2/rates/${assetcode}`, config);
  return externalInstance.get(`/v2/assets`, config);
}

function getCurrencyConversionRate(currencyCode) {
  //console.log(data);
  const config = {
    withCredentials: true,
    headers: {
      // "api-key": "yyt2-bh63-5d6t-77hg",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Headers": "*",
      //"Access-Control-Allow-Methods": "*"
      //'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
    },
    params: {
      // "customerid":data.customerid,
      // "status":data.status
    },
    raxConfig: {
      retry: 3,
      retryDelay: 500,
    },
  };
  //return externalInstance.get(`/v2/rates/${assetcode}`, config);
  return externalInstance.get(`/v2/rates/${currencyCode}`, config);
}

function getPrivateTournamentData() {
  const config = {
    withCredentials: true,
    headers: {
      "api-key": "yyt2-bh63-5d6t-77hg",
    },
  };
  return instance.get("/tournaments/63a4279f3695ec0015b41a74", config);
}
function getTournaments(customerid) {
  const config = {
    withCredentials: true,};
  return instance.get(`/tournaments?customerid=${customerid}`, config);
}
function getRanking(tournamentId) {
  const config = {
    withCredentials: true,};
  return instance.get(`/tournaments/${tournamentId}/rankings`, config);
}

function getSingleTournament(tournament_id) {
  let customerid = JSON.parse(localStorage.getItem("customerId"));
  const config = {
    withCredentials: true,};
  return instance.get(`/tournaments/${tournament_id}?customerid=${customerid}`, config);
}

const api = {
  login,

  logout,
  register,
  changePassword,
  updatePassword,
  forgotPassword,
  sendOTPToPhone,
  resetPassword,
  getAccountLists,
  getAccount,
  getOrders,
  getClosedTrades,
  getHoldings,
  getPositions,
  getPendingOrders,
  getExecutedOrders,
  placeOrder,
  submitOrder,
  closeOrder,
  cancelOrder,
  getUser,
  getTradeFeeAndTaxes,
  getAssetRates,
  getlatestRates,
  getAssetRatesExternalApi,
  getCurrencyConversionRate,
  getAssetForexRates,
  getAssetLatestRates,
  getLatestRatesForWatchList,
  getlatestRatesForFTT,
  getPrivateTournamentData,
  getTournaments,
  getRanking,getSingleTournament,
  fetchLatestPriceForSelectedAsset,
  searchInstruments,
  addInstrumentToWatchlist,
  removeInstrumentFromWatchlist,
  getAccountPnLDetails,
  validatePhoneAndSendOTP,
  verifyPhoneOTP,
  updateDarkMode,
  loadSiteConfigurationFrontend
  
};
export default api;
export { AxiosInterceptor }
