import React, { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

import useWindowSize from "../components/useWindowSize";
import { Button, Grid, Typography } from "@mui/material";

function LoginRegister() {
  const [displayAuthPage, setDisplayAuthPage] = useState("Login");

  const windowSize = useWindowSize();
  console.log(windowSize.width);

  return (
    <>
      <div className="desktop-wrap">
        {windowSize.width >= 768 && (
          <div className="desktop-landing">
            <div className="desktop-inner">
              <Grid container alignItems="center" justifyContent="right">
                <Grid xs={12} md={5} className="trading-content">
                  <Typography variant="h3" component="h3" mb={1}>
                    Welcome to FTX
                  </Typography>

                  <Typography variant="p" component="p" mb={3}>
                    A trading platform to achieve your short and long term
                    goals.
                  </Typography>

                  <Button variant="contained" className="main-btn">Start trading</Button>
                </Grid>

                <Grid xs={12} md={6} className="trading-img">
                  <img
                    src="/images/funded-trading.png"
                    className="img-responsive"
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        )}

        <div className="forms">
          {displayAuthPage === "Login" && (
            <Login setDisplayAuthPage={setDisplayAuthPage} />
          )}
          {displayAuthPage === "Register" && (
            <Register setDisplayAuthPage={setDisplayAuthPage} />
          )}
          {displayAuthPage === "ForgotPassword" && (
            <ForgotPassword setDisplayAuthPage={setDisplayAuthPage} />
          )}
        </div>
      </div>
    </>
  );
}

export default LoginRegister;
