import { Image } from 'react-bootstrap';
import ForwardIcon from '@mui/icons-material/Forward';
import { useState, useContext, useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';

const SearchResultsRow = (props) => {
  let assetImage, assetImageExists;
  const [isClick, setIsClick] = useState(false);
  if (
    props.assetRow.group === 'crypto' ||
    props.assetRow.group === 'ftt' ||
    props.assetRow.group === 'forex' ||
    props.assetRow.group === 'stocks'
  ) {
    assetImage = (
      <Image
        className='rounded w-[24px] h-[24px]'
        style={{ height: 28, paddingRight: 10, marginTop: 7 }}
        src={getAssetImages(props.assetRow)}
        loading='lazy'
      />
    );
  }


   var  assetItemAddedToWatchlistDisplay = <AddIcon/>
     if ((props.watchlistarray.filter(e => e.symbol === props.assetRow.symbol).length > 0)) {
      assetItemAddedToWatchlistDisplay = <CheckIcon/>
    }
  
 

  function getAssetImages(assetRow) {
    let symbol = '';
    if (assetRow.symbol) {
      symbol = assetRow.symbol.toLowerCase();
    }
    let url = `https://assets.coincap.io/assets/icons/${symbol}@2x.png`;
    if (assetRow.group === 'crypto')
      url = `https://storage.googleapis.com/prod_stocks_logo/stocks_logo/cryptos/${assetRow.symbol}.png`;
    if (assetRow.group === 'stocks')
      url = `https://storage.googleapis.com/stocks_logo/stocks/${assetRow.symbol}.png`;
    //console.log(url)
    return url;
  }

  const priceChange = (
  Number((props.assetRow.price - props.assetRow.close).toFixed(2))
  )
  const percentage = (
    Number(((priceChange/props.assetRow.close)*100)).toFixed(2));


  function getCssClass() {
      return percentage.charAt(0) == '-' ? 'negative' : 'positive';
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // console.log(props.index);
    // if (props.index === 0) {
    //   setSelectedAsset(props.assetRow);
    //   openSidebar();
    // }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    <div className='asset-padding all-assets line '>
      <div>
        <div
          className={`d-flex w-100 ${props.index > 0 && 'mt-1 mb-1'}`}
          key={props.assetRow.index}
        >
          <div
            className='d-flex justify-content-between w-100 stock-wrap '
          >
            <div className='d-flex col-5 image-block assets-img'>
              <div className='d-flex '>{assetImage}</div>
              <div className='d-block'>
                <span className='d-block  text-capitalize title big-text'>
                  {props.assetRow.display_name}
                </span>

                {  (props.assetRow.display_name).includes("CE") || (props.assetRow.display_name).includes("PE") || (props.assetRow.display_name).includes("FUT") ? 

                  <>
                  <span className='title small-text company ' style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                    {props.assetRow.name}
                  </span>
                  </> 

                  :
                  <>
                  <span className='title small-text company' style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                      {props.assetRow.symbol}
                    </span>
                    </> 

                  }


                  {/**       <span className='d-block title small-text sector'>

              {props.displayPage !== "Stocks" && props.assetRow.exchange}


              {props.displayPage === "Stocks" && props.assetRow.sector}

              </span>**/}


              
              </div>
            </div>
      
   
            <div className='mid_btn col-3 asset-row-btns'>
              <button
                className='buy-initial'
                onClick={() => { props.handleSelectionChange(`${props.assetRow.symbol}`,"buy") }}
              >
                B
              </button>
              <button
                className='sell-initial'
                onClick={() => { props.handleSelectionChange(`${props.assetRow.symbol}`,"shortsell") }}
              >
                S
              </button>
              <button
                className='check-initial plus'
                onClick={() => {
               
                  props.addInstrumentToWatchlist(
                    props.assetRow
                  );
                }}
              >
              
              {assetItemAddedToWatchlistDisplay}
              </button>
            </div>
        
       <div className='d-flex flex-column col-4 asset-price'>
              <div className='d-block text-end'>
                <p className={`d-block price`}>₹{props.assetRow.price}</p>
              </div>
              <div className='d-flex price-block'>
                <p className={`d-block ${getCssClass()} title`}>
                  {priceChange}
                </p>
                <bdi>|</bdi>
                <p className={`d-block ${getCssClass()} title`}>
                  {percentage}%{' '}
                </p>
                <span>
                  {' '}
                  {percentage.charAt(0) === '-' ? (
                    <ForwardIcon
                      sx={{ transform: 'rotate(90deg)', fill: 'red' }}
                    />
                  ) : (
                    <ForwardIcon
                      sx={{ transform: 'rotate(-90deg)', fill: 'green' }}
                    />
                  )}
                </span>
              </div>
              <div>
         
          
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default SearchResultsRow;
