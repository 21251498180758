import { Image } from "react-bootstrap";
import React, {useState } from 'react';
import useWindowSize from '../components/useWindowSize';
import api from "../services/api";
const PendingOrderRowDisplay = (props) => {



  const [showCancelButton, setShowCancelButton] = useState(() => {
    if (props.order.status === "pending" || props.order.status === "limit_pending"  ) {
      return true;
    } else {
      return false
    }
   
  });

  const [orderCancelled, setOrderCancelled] = useState(false)




  
  
  const windowSize = useWindowSize(); 
  let assetImage;
  assetImage = (
    <Image
      className="rounded w-[24px] h-[24px]"
      style={{ height: 30, paddingRight: 5 }}
      src={`https://storage.googleapis.com/stocks_logo/stocks/${props.order.symbol}.png`}
      loading="lazy"
    />
  );

  const orderPriceType = props.order.orderPriceType;

  props.order.amount_invested = (props.order.limit_price) ? Number(props.order.limit_price * props.order.quantity).toFixed(2) : Number(props.order.entryprice * props.order.quantity).toFixed(2)
  
  // Define the classes based on the orderPriceType
  const classes = orderPriceType === 'market' ? `mob_set o-type v limit` : `mob_set o-type v`;

  const handleCancelOrder = (order_id, event) => {
    
    event.preventDefault();
  
    
    let account_id = JSON.parse(localStorage.getItem('account_id'));
   
    api
      .cancelOrder({account_id:account_id, cancel_order_id:order_id})
      .then(({ data }) => {
       
        if (data.error) {
          setErrorMessage('Some Error Occured');
          console.log(error);
          return;
        }

        if (data.success) {
          setShowCancelButton(false)
          setOrderCancelled(true)
          props.setPendingOrdersCount(Prev => Prev -1 )
        }
      })
      .catch((error) => {
        console.log(error);
      });
    
  };
  
  // if order type === limit then show limit_price otherwise show entryprice
  return (
    <div className="">
      <div className="card mb-2 closed-box">
        <div className="card-body pending-body">
          <div className="custom_panding_heading">
            <div className="d-flex open-order-title">
          {/** <div className="d-flex">{assetImage}</div> */} 
            <div className="">
            <h5 className="card-subtitle text-muted holding-subtitle ">{props.order.asset_name}
            {  (props.order.asset_name).includes("CE") || (props.order.asset_name).includes("PE") || (props.order.asset_name).includes("FUT") ? 
             <></>
             :
             <> ({props.order.symbol}) </>  }
            </h5>
              <h5 className="card-title assest-name ">{<span className="card-subtitle display-time hide-pc">
                {windowSize.width < 768 && props.order.entryTimeString}
              </span> }</h5>
            
            </div>
            </div>
            <div className="tags-badges">
            <div className={classes}>
            {props.order.orderPriceType  && (
                <span className="badge bg-opacity-25 text-warning border-warning  text-bg-warning limit-btn pending-limit_btnn">
                  {" "}
                  {props.order.orderPriceType}
                </span>
              )}
            </div>
            <div className='mob_set o-type'>
            
            <span className='badge bg-opacity-25 text-warning border-warning  text-bg-warning  limit-btn pending-limit_btnn'>
              {' '}
              {props.order.order_time_type}
            </span>
          </div>
            <div className="mob_set lp-type">
              {props.order.transactionType === "buy" ? (
                <>
                  <div className=" buy-btn ">
                    Buy
                  </div>
                </>
              ) : (
                <>
                  <div className="short_cell-btn ">
                    Short sell
                  </div>
                </>
              )}

             
            </div>
            </div>
          </div>
      

          <div className="card-text d-flex pending-details">
        { windowSize.width > 768 && <div className='col-4 d-flex justify-content-center align-items-center flex-column hide-mbl'>
              <p className='detail'>Executed Time</p>
              <p className='value'>{props.order.entryTimeString}</p>
            </div> }
            <div className="col-4 d-flex justify-content-center align-items-center flex-column">
              <p className="detail">Quantity</p>
              <p className="value">{props.order.quantity}</p>
            </div>
            <div className="col-4 d-flex justify-content-center align-items-center flex-column">
              <p className="detail">Price</p>
              <p className="value">₹{Number(props.order.entryprice).toFixed(2)}</p>
            </div>
            <div className="col-4 d-flex justify-content-center align-items-center flex-column">
              <p className="detail">LTP</p>
              <p className="value">₹{Number(props.order.ltp).toFixed(2)}</p>
            </div>
            { windowSize.width > 768 &&    <div className="col-4 d-flex justify-content-center align-items-center flex-column">
              <p className="detail">Amount</p>
              <p className="value">
                ₹
               { props.order.amount_invested}
              </p>
            </div>}
           
           {showCancelButton && <div>
           <button className="cancel-btn" onClick={(event) => handleCancelOrder(props.order._id, event)}>Cancel</button>
            </div>}

            {orderCancelled && 
          <div>
            <span className="cancelled-btn">Cancelled</span>
            </div>}
          
          </div>
        </div>
      </div>
    </div>
  );
};
export default PendingOrderRowDisplay;
