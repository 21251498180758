import React, { useState } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Login";
import "./App.css";
import Register from "./pages/Register";
import ChangePassword from "./pages/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

import Tournament from "./pages/Tournament/Tournament";
import Trade from "./pages/Trade";
import { UserContextProvider } from "./contexts/UserContext";


import RequireAuth from "./components/RequireAuth";

import { AxiosInterceptor } from './services/api'
import LoginRegister from "./pages/LoginRegister";

function App() {



  return (
    <Router>
      <AxiosInterceptor>
      <div className="App">
          <Routes>
            <Route path="/login" element={<LoginRegister/>} />
            <Route path="/login_register" element={<LoginRegister/>} />
            <Route path="/register" element={<LoginRegister/>} />
            <Route path="/forgotpassword" element={<LoginRegister />} />
            <Route path="/" element={<RequireAuth><Trade /></RequireAuth>} />
           <Route path="/trade"element={ <RequireAuth><Trade/></RequireAuth>}
          />
          </Routes>

       
        
      </div>
      </AxiosInterceptor>
    </Router>
  );
}

export default App;
