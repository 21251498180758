import SweetAlert from 'react-bootstrap-sweetalert';
function WebsocketExpiredAlert(props) {
return (
<SweetAlert
             
onConfirm={() => {
    //props.setReinitiateWebsocket(prev => prev + 1)
    window.location.reload(false)
}}
onCancel={() => {props.setWebsocketExpired(false)}}
confirmBtnText='Reload Page'
confirmBtnStyle={{
  backgroundColor: '#0094ff',
  fontSize: '12px',
  boxShadow: 'none',
  borderColor: '#6046cf',
  width: '100%',
  marginBottom:'10px',
  marginRight: '0px'

}}

style={{ backgroundColor: '#24262e', color: 'white' }}
customIcon='images/success.png'
custom
title='Reload Page for Latest Rates'
className="succ-img"
showCloseButton={true}


>

</SweetAlert> 

);
}

export default WebsocketExpiredAlert;