import React, { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import Grid from "@mui/material/Grid";
import "./Tournament.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import TLogo from "../../../src/pages/Tournament/tourna.png";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import api from "../../services/api";
import Popover from "@mui/material/Popover";

import Drawer from "@mui/material/Drawer";
import Header from "../../components/Header";

function dateDifference(date2, date1) {
  // var date1 = new Date();
  var diff = date2 - date1;
  var diffInSeconds = diff / 1000;
  var days = Math.floor(diffInSeconds / (60 * 60 * 24));
  var hours = Math.floor((diffInSeconds % (60 * 60 * 24)) / (60 * 60));
  var minutes = Math.floor((diffInSeconds % (60 * 60)) / 60);
  var seconds = Math.floor(diffInSeconds % 60);
  let string = `${days}d  ${hours}h  ${minutes}m ${seconds}s`;
  return string;
}

export const CheckDate = ({ date_from, date_to }) => {
  const dateToCheck = new Date(date_from); // The date to check
  const currentDate = new Date(); // The current date and time

  if (currentDate > dateToCheck) {
    return `Until end: ${dateDifference(new Date(`${date_to}`), new Date())}`
  } else {
    return `Until start: ${dateDifference(new Date(`${date_from}`), new Date())}`
  }
}

const Tournament = (props) => {
  const { user, setUser } = useContext(UserContext);
  let accountsList = JSON.parse(localStorage.getItem("accounts"));
  let account_id = JSON.parse(localStorage.getItem("account_id"));

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [Progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    if (Progress < 100) {
      setTimeout(() => {
        setProgress(Progress + 5);
      }, 500);
    }
  }, [Progress]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "#fff",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData1(name1, calories1, value1, term1) {
    return { name1, calories1, value1, term1 };
  }

  const [selectedTournament, setselectedTournament] = useState(null);
  useEffect(() => {
    // console.log('selectedTournamentselectedTournamentselectedTournament', selectedTournament)
  }, [selectedTournament])
  const [PrivateTournamentData, setPrivateTournamentData] = useState(null);
  useEffect(() => {
    // console.log("PrivateTournamentDataPrivateTournamentData", PrivateTournamentData)
  }, [PrivateTournamentData])
  const func_Set_Tournament = async (id, anchor, value, e) => {
    // console.log('idid', id)
    let response = await api.getSingleTournament(id)

    // console.log(response.data);
    // const object = PrivateTournamentData.find(
    //   (obj) => obj.tournament._id === id
    // );
    setselectedTournament(response.data);
    toggleDrawer(anchor, value, e);
  };

  const func_clear_selected_Tournament = () => {
    setselectedTournament(null);
  };

  const GetPrivateTournamentData = async (customerId) => {
    const response = await api.getTournaments(customerId);
    if (response?.data?.data?.tournamentsArray) {
      setPrivateTournamentData(response?.data?.data?.tournamentsArray);
    } else {
      console.log("something went wrong");
    }
  };

  const [Rankings, setRankings] = useState(null);
  const GetRanking = async (tournamentId) => {
    const response = await api.getRanking(tournamentId);
    if (response?.data?.data?.rankings) {
      setRankings(response?.data?.data?.rankings);
    }
  };
  useEffect(() => {
    if (selectedTournament !== null) {
      GetRanking(selectedTournament.data.tournament.tournament_id);
    }
  }, [selectedTournament]);
  const [untillStartTime, setuntilStartTime] = useState(
    dateDifference(dateDifference(new Date(`${'2023-02-10T16:00:00.000Z'}`), new Date()))
  );
  useEffect(() => {
    setTimeout(() => {
      setuntilStartTime(dateDifference(new Date(`${'2023-02-10T16:00:00.000Z'}`), new Date()));
    }, 1000);
  }, [untillStartTime]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  const list1 = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "100vw" }}
      role="presentation"
    //   onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="single-tournament">
        <Card
          onClick={(e) => {
            toggleDrawer(anchor, true, e);
          }}
        >
          <CardMedia
            sx={{ height: 380 }}
            image={
              selectedTournament && selectedTournament.data.tournament.banner_content
            }
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h3" component="div">
              {selectedTournament && selectedTournament.data.tournament.name}
              <span>Maximum balance at the end of the tournament</span>
            </Typography>

            <Typography className="tournament-timer" component="div">
              {/* Until start: {dateDifference(new Date(`${selectedTournament && selectedTournament.data.tournament.date_from}`), new Date())} */}
              {/* {PrivateTournamentData && PrivateTournamentData.date_from} */}
              <CheckDate
                date_from={selectedTournament && selectedTournament.data.tournament.date_from}
                date_to={selectedTournament && selectedTournament.data.tournament.date_to}
              />
            </Typography>
          </CardContent>
          <CardActions>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {selectedTournament && selectedTournament?.user_involved ?
                <Grid item xs={12} sx={{ "text-align": "right" }}>
                  <Link>
                    Tourament Open
                  </Link>
                </Grid>
                :
                <Grid item xs={12} sx={{ "text-align": "right" }}>
                  <Link>
                    SIGN UP FOR ₹
                    {selectedTournament &&
                      selectedTournament.data.tournament.participation_fees}
                  </Link>
                </Grid>}
            </Grid>
          </CardActions>
        </Card>


        {
          selectedTournament && selectedTournament.user_involved ? <Grid container className="user-details-active">
            <Grid item xs={2} style={{ textAlign: "center" }}>
              <img src={TLogo} />
            </Grid>

            <Grid item xs={7}>
              <h6>amitnirnjan9@gmail.com</h6>
              <span> Place: {selectedTournament &&
                selectedTournament.user_position}/{selectedTournament &&
                  selectedTournament.users_count} | Balance: {selectedTournament &&
                    selectedTournament.user_balance}</span>

            </Grid>

            <Grid item xs={3} style={{ textAlign: "right" }}>
              <h3><big>₹ {selectedTournament &&
                selectedTournament.data.tournament.prize_fund}</big></h3>
              <span>Prize</span>
            </Grid>
          </Grid> : null
        }



        <div className="tournament-terms">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                backgroundColor: "#2a2b30",
                color: "#fff",
              }}
            >

              <Typography>Conditions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid
                  container
                  className="terms-row"
                  justifyContent={"center"}
                  sx={{ "text-align": "center" }}
                >
                  <Grid item xs={4}>
                    <EmojiEventsOutlinedIcon />
                    <h3>
                      ₹
                      {selectedTournament &&
                        selectedTournament.data.tournament.prize_fund}
                    </h3>
                    <span>Prize fund</span>
                  </Grid>
                  <Grid item xs={4} className="starting-balance">
                    <PaidOutlinedIcon />
                    <h3>
                      ₹
                      {selectedTournament &&
                        selectedTournament.data.tournament.initial_balance}
                    </h3>
                    <span>Starting balance</span>
                  </Grid>

                  <Grid item xs={4}>
                    <CurrencyExchangeOutlinedIcon />
                    <h3>
                      ₹
                      {selectedTournament &&
                        selectedTournament.data.tournament.rebuy_fees}
                    </h3>
                    <span>Rebuy cost</span>
                  </Grid>

                  <Grid item xs={12}>
                    <br></br>
                    <TableContainer component={Paper}>
                      <Table
                        aria-label="customized table"
                        sx={{
                          backgroundColor: "#2a2b30",
                          color: "#ffffff",
                          border: 0,
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Place</StyledTableCell>
                            <StyledTableCell align="right">
                              Prize
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody
                          sx={{
                            backgroundColor: "#2a2b30",
                            color: "#ffffff",
                            border: 0,
                          }}
                        >
                          {selectedTournament &&
                            selectedTournament.data.tournament.prizes.map(
                              (item, index) => (
                                <StyledTableRow key={index}>
                                  <StyledTableCell component="th" scope="row">
                                    {index + 1} Place
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {item}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{
                backgroundColor: "#2a2b30",
                color: "#fff",
              }}
            >
              <Typography>Tournament Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems="center" justifyContent="space-between" mb={1}>
                <Grid xs={7} className="active-t-heading">
                  <Typography variant="span">Number of rebuys:</Typography>

                </Grid>
                <Grid xs={5} className="active-t-value" >
                  <Typography variant="span">{selectedTournament && selectedTournament.data.tournament.rebuys_count}</Typography>
                </Grid>
              </Grid>

              <Grid container alignItems="center" justifyContent="space-between" mb={1}>
                <Grid xs={7} className="active-t-heading">
                  <Typography variant="span">Participants:</Typography>

                </Grid>
                <Grid xs={5} className="active-t-value" >
                  <Typography variant="span">{selectedTournament && selectedTournament.data.tournament.users_count}</Typography>
                </Grid>
              </Grid>

              <Grid container alignItems="center" justifyContent="space-between" mb={1}>
                <Grid xs={7} className="active-t-heading">
                  <Typography variant="span">Start:</Typography>

                </Grid>
                <Grid xs={5} className="active-t-value" >
                  <Typography variant="span">  {new Date(selectedTournament && selectedTournament.data.tournament.date_from).toLocaleString()}</Typography>
                </Grid>
              </Grid>

              <Grid container alignItems="center" justifyContent="space-between" mb={1}>
                <Grid xs={5} className="active-t-heading">
                  <Typography variant="span">End:</Typography>

                </Grid>
                <Grid xs={7} className="active-t-value" mb={2}>
                  <Typography variant="span">{new Date(selectedTournament && selectedTournament.data.tournament.date_to).toLocaleString()}</Typography>
                </Grid>
              </Grid>
              <Typography variant="p">
                <b>Conditions</b>
              </Typography>
              <Typography>
                {selectedTournament && selectedTournament.data.tournament.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              sx={{
                backgroundColor: "#2a2b30",
                color: "#fff",
              }}
            >
              <Typography>Leaderboard</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                <br></br>
                <TableContainer component={Paper}>
                  <Table
                    aria-label="customized table"
                    sx={{
                      backgroundColor: "#2a2b30",
                      color: "#ffffff",
                      border: 0,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell align="right">Trader</StyledTableCell>
                        <StyledTableCell align="right">Result</StyledTableCell>
                        <StyledTableCell align="right">Prize</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "#2a2b30",
                        color: "#ffffff",
                        border: 0,
                      }}
                    >
                      {Rankings &&
                        Rankings.map((item, index) => (
                          <StyledTableRow key={item.name}>
                            <StyledTableCell component="th" scope="row">
                              {item?.rank ? item?.rank : "NA"}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {item?.customername ? item?.customername : "NA"}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {item?.balance}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {item?.prizeamount}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </Box>
  );

  function func_setTournamentName() {
    let response;
    if (accountsList.length != 0) {
      response = accountsList.filter((item, index) => {
        if (item._id === account_id) {
          return item.name;
        } else {
          null;
        }
      });
      if (response.length == 0) {
        response.push(accountsList[0]);
      }
    }
    return response;
  }

  const [tournamentName, settournamentName] = useState(
    func_setTournamentName()
  );

  useEffect(() => {
    settournamentName(func_setTournamentName());
  }, [user]);

  useEffect(() => {
    if (tournamentName) {
      GetPrivateTournamentData(tournamentName[0].customerid);
    }
    // GetRanking();
  }, [tournamentName]);

  function updateOpenOrders() {
    let customerid = JSON.parse(localStorage.getItem("customerId"));
    let account_id = JSON.parse(localStorage.getItem("account_id"));
    let token = JSON.parse(localStorage.getItem("token"));
    let customerInfo = {
      customerid: customerid,
      account_id: account_id,
      status: "open",
    };
    api
      .getOrders(customerInfo, token)
      .then(({ data }) => {
        //   console.log(data);
        localStorage.setItem("openOrders", JSON.stringify(data.number));
        setUser((user) => ({
          ...user,
          openOrders: JSON.stringify(data.number),
        }));
      })
      .catch((error) => {
        console.log(error);

        setErrorMessage(error.response.data);
      });
  }

  function setCurrentAccount(accountId, points) {
    localStorage.setItem("account_id", JSON.stringify(accountId));
    localStorage.setItem("updatedPoints", points);
    setUser((user) => ({
      ...user,
      account_id: accountId,
    }));
    setUser((user) => ({
      ...user,
      updatedPoints: points,
    }));

    updateOpenOrders();
    props.doRefresh((prev) => prev + 1);
  }

  const [showaccountSwtichTab, setShowAccountSwitchTab] = useState({
    value: false,
    account_id: null,
    account_points: null,
    accountName: null,
  });
  const handleAccountSwitchTab = (
    value,
    account_id,
    account_points,
    accountName
  ) => {
    setShowAccountSwitchTab({
      value,
      account_id,
      account_points,
      accountName,
    });
  };

  return (
    <div className="dark-theme tournament-page">
     {/**  <Header refresh={props.refresh} doRefresh={props.doRefresh} />*/}
      <>
        <Grid container className="tournament-header" alignItems={"center"}>
          <Grid item xs={2}>
            <img src={TLogo} />
          </Grid>
          <Grid item xs={4}></Grid>

          <Grid item xs={6}>
            <div
              className="user-account"
              aria-describedby={id}
              variant="contained"
              onClick={(e) => {
                toggleDrawer("right", true, e);
              }}
            >
              {tournamentName
                ? tournamentName.length !== 0
                  ? tournamentName[0].name
                  : user.email
                : null}

              <h3>₹{user.updatedPoints}</h3>
            </div>

            {/* <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}

              
            > */}
            <Drawer
              anchor="right"
              open={state["right"]}
              onClose={(e) => toggleDrawer("right", false, e)}
              className="account-slide dark-theme"
            >
              <div className="drawer-heading">
                <h3>Accounts</h3>
                <span
                  onClick={(e) => {
                    toggleDrawer("right", false, e);
                    handleAccountSwitchTab(false, null, null, null);
                  }}
                >
                  <CloseOutlinedIcon />
                </span>
              </div>
              <Box sx={{ width: "100vw" }} role="presentation">
                <div className="account-menu">
                  {showaccountSwtichTab.value && (
                    <div className="account-switch">
                      <small>Account mode changed</small>
                      <EmojiEventsIcon />
                      <p>
                        Account mode changed<br></br>Now Using{" "}
                        {showaccountSwtichTab.accountName}
                      </p>
                      <button
                        className="tradenow-btn"
                        onClick={(e) => {
                          setCurrentAccount(
                            showaccountSwtichTab.account_id,
                            showaccountSwtichTab.account_points
                          );
                          toggleDrawer("right", false, e);
                          handleAccountSwitchTab(false, null, null, null);
                        }}
                      >
                        Trade
                      </button>
                      <small
                        onClick={(e) => {
                          handleAccountSwitchTab(false, null, null, null);
                        }}
                        className="switch-cancel"
                      >
                        Cancel
                      </small>
                    </div>
                  )}
                  {accountsList &&
                    accountsList.map((account) => (
                      <Grid
                        container
                        className={`account-li ${tournamentName !== null &&
                          account.name === tournamentName[0]?.name
                          ? "active-account"
                          : ""
                          }`}
                        alignItems={"center"}
                        // onClick={(e) => toggleDrawer("right", false, e)}
                        onClick={(e) => {
                          // console.log(
                          //   "tournamentName[0].nametournamentName[0].nametournamentName[0].nametournamentName[0].name",
                          //   tournamentName[0].name
                          // );
                          if (
                            tournamentName !== null &&
                            account.name !== tournamentName[0].name
                          ) {
                            handleAccountSwitchTab(
                              true,
                              account._id,
                              account.points,
                              account.name
                            );
                          }
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                        // onClick={() => {
                        //   setCurrentAccount(account._id, account.points);
                        // }}
                        >
                          <span className="d-block username">
                            ACCOUNT: {account.name}
                          </span>

                          <span className="d-block points">
                            BALANCE: {account.points}
                          </span>
                        </Grid>
                      </Grid>
                    ))}
                </div>
              </Box>
              {/* <Typography sx={{ p: 2 }}>
                {accountsList.map((account, index) => {
                  return (
                    <>
                      <span
                        onClick={() => {
                          setCurrentAccount(account._id, account.points);
                        }}
                      >
                        {account.name}
                      </span>
                      <br />
                      <span>{account.points}</span>
                      <br />
                    </>
                  );
                })}
              </Typography> */}
            </Drawer>
          </Grid>
        </Grid>

        <div className="tournament-body">
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              {PrivateTournamentData &&
                PrivateTournamentData.map((item, index) => {
                  // console.log("item.date_toitem.date_toitem.date_to",item.tournament.date_to)
                  return (
                    item.user_involved ?
                      <div className="active-tournament" onClick={
                        (e) => {

                          func_Set_Tournament(
                            item.tournament.tournament_id,
                            anchor,
                            true,
                            e
                          );
                        }
                        // toggleDrawer(anchor, true)
                      }>
                        <Grid container className="active-t-header" alignItems="center" mb={3}>
                          <Grid xs={4}><img src={item && item.tournament.banner_content} /></Grid>
                          <Grid xs={8} className="active-t-titles">
                            <Typography variant="h5"> {item && item.tournament.name}</Typography>
                            <Typography variant="h6">Prize fund <big>₹{item && item.tournament.prize_fund}</big></Typography>
                          </Grid>
                        </Grid>


                        <Grid container alignItems="center" justifyContent="space-between" mb={2}>
                          <Grid xs={5} className="active-t-heading">
                            <Typography variant="span">Place:</Typography>

                          </Grid>
                          <Grid xs={7} className="active-t-value">
                            <Typography variant="span">{item.user_position + "/" + item.users_count}</Typography>
                          </Grid>
                        </Grid>

                        <Grid container alignItems="center" justifyContent="space-between" mb={1}>
                          <Grid xs={5} className="active-t-heading">
                            <Typography variant="span">Balance:</Typography>

                          </Grid>
                          <Grid xs={7} className="active-t-value" >
                            <Typography variant="span">{item && item.user_balance}</Typography>
                          </Grid>
                        </Grid>

                        <Grid container alignItems="center" justifyContent="space-between" pb={1}>
                          <Grid xs={5} className="active-t-heading">
                            <Typography variant="span">Until end:</Typography>

                          </Grid>
                          <Grid xs={7} className="active-t-value">
                            <Typography variant="h6"><span>{dateDifference(new Date(`${item && item.tournament.date_to}`), new Date())}</span></Typography>
                          </Grid>
                        </Grid>
                        <Box sx={{ width: "60%" }} className="active-progress">
                          <LinearProgress
                            variant="determinate"
                            value={Progress}
                          />
                        </Box>

                      </div> :
                      <Card
                        onClick={
                          (e) => {
                            func_Set_Tournament(
                              item.tournament.tournament_id,
                              anchor,
                              true,
                              e
                            );
                          }
                          // toggleDrawer(anchor, true)
                        }
                      >
                        <CardMedia
                          sx={{ height: 180 }}
                          image={item && item.tournament.banner_content}
                          title="green iguana"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h3" component="div">
                            {item && item.tournament.name}
                          </Typography>

                          <Typography
                            className="tournament-timer"
                            component="div"
                          >
                            {/* Until start: {untillStartTime} */}
                            <CheckDate
                              date_from={item && item.tournament.date_from}
                              date_to={item && item.tournament.date_to}
                            />
                          </Typography>
                        </CardContent>
                        <Box sx={{ width: "60%" }}>
                          <LinearProgress
                            variant="determinate"
                            value={Progress}
                          />
                        </Box>
                        <CardActions>
                          <Grid
                            container
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Grid item xs={6} className="tournament-actions">
                              <span>Prize Fund</span>
                              <h1>₹{item && item.tournament.prize_fund}</h1>
                            </Grid>
                            <Grid item xs={6} sx={{ "text-align": "right" }}>
                              <Link>Read more</Link>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                  );
                })}

              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                // onClose={toggleDrawer(anchor, false)}
                onOpen={(e) => {
                  toggleDrawer(anchor, true, e);
                }}
                className="tournament-dark tournament-slide"
              >
                <div className="tournament-heading">
                  <p>
                    Tournaments
                    <span>
                      <ArrowForwardIosOutlinedIcon />
                    </span>
                    Daily Free
                  </p>
                  <span
                    onClick={(e) => {
                      toggleDrawer(anchor, false, e);
                      func_clear_selected_Tournament();
                    }}
                  >
                    <CloseOutlinedIcon />
                  </span>
                </div>
                {list1(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
      </>
    </div>
  );
};

export default Tournament;
