import { createContext, useState, useEffect } from 'react';

import api from '../services/api';

import OrderRowDisplay from '../components/OrderRowDisplay';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Tab, Tabs } from '@mui/material';
import HeaderShowHide from '../components/HeaderShowHide';
import CloseIcon from '@mui/icons-material/Close';  




import PaginationList from '../components/PaginationList';


import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import SortIcon from '@mui/icons-material/Sort';



const ExecutedOrders = (props) => {
  console.log("calling Excecuted order componenet")
  const [executedOrders, setExecutedOrders] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [tabValue, setTabValue] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);
 
  const [currentPage, setCurrentPage] = useState(1);
  
  const [nPages, setNPages] = useState(1)

  const [selectedMenu, setSelectedMenu] = useState('executionTimeString');
  const [sortOrder, setSortOrder] = useState(-1);
  const [selectedOptionName, setSelectedOptionName] = useState('Date (Descending)');



  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSearchClear = (event) => {
    console.log("clearing search text")
    setSearchValue("")
    setCurrentPage(1)
    fetchData("",selectedMenu,sortOrder,1)
   };

 
   const open = Boolean(anchorEl);
   const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
     setAnchorEl(null);
   };

   const handleSort = (option) => {
    setSelectedMenu(option.option_property)
    setSortOrder(option.sort_order)
    setSelectedOptionName(option.option_name)
    setCurrentPage(1)
    fetchData(searchValue,option.option_property,option.sort_order,1)
    handleClose()
    
   };
 

   const options = [
    {option_name: "Date (Ascending)", option_property:"executionTime" , sort_order:1},
    {option_name: "Date (Descending)", option_property:"executionTime" , sort_order:-1},
    {option_name: "Amount Invested(Low to High)", option_property:"investedpoints" , sort_order:1},
    {option_name: "Amount Invested(High to Low)", option_property:"investedpoints" , sort_order:-1},
    {option_name: "Quantity (Low to High)", option_property:"quantity" , sort_order:1},
    {option_name: "Quantity (High to Low)", option_property:"quantity" , sort_order:-1},
    
    
  ];

  
   


  const fetchData = async (search_text,sort_property,sort_order,pageNumber) => {

    setIsLoading(true);
    setLoading(true)
    setError(null);
    /**let pageNumber = 0
      if(initialLoad){
      pageNumber  = 0
      }else {
        pageNumber = currentPage
      }**/




    try {

    
      let account_id = JSON.parse(localStorage.getItem('account_id'));
     
      let inputData = {
        account_id: account_id,
        pageNum:pageNumber,
        sort_property : sort_property,
        sort_order:sort_order
      };

      console.log("hi"+inputData.sort_property)

      if(search_text.length >= 3){
        inputData.search_value=search_text   
      }

    
      api
        .getExecutedOrders(inputData)
        .then(({ data }) => {
          if(data.success){
           
            //setCurrentPage(prevPage => prevPage + 1);
            //setClosedPositions(prevItems => [...prevItems, ...data.trades.closedTrades]);
           
          
         
            setExecutedOrders(data.orders);
           // setPendingOrdersCount(data.pendingOrdersCount)
           // setLoading(false)
            if(data.Npages){
              setNPages(data.Npages)
            }
           // if(data.searchResults){
            //   setCurrentPage(1)
          //  }
           
           // props.setHoldingsCount(data.holdingsCount)
           // props.setPositionsCount(data.positionsCount)
           props.setSubscribeSymbols([])
            setLoading(false)

          }
        })


  } catch (error) {
    console.log(error)
    setError(error);

  } finally {
    setIsLoading(false);
  }

  };


  useEffect(() => {
    fetchData(searchValue,selectedMenu,sortOrder,currentPage);
  }, [props.refresh]);




  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(searchValue.length >= 3){
        setCurrentPage(1)
        fetchData(searchValue,selectedMenu,sortOrder,1);
          
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchValue])



   const handleScrollDesign = () => {
    //https://designcode.io/react-hooks-handbook-usepagebottom-hook
    if(isLoading){
      return
    }
    const offsetHeight = document.documentElement.offsetHeight;
    const innerHeight = window.innerHeight;
    const scrollTop = document.documentElement.scrollTop;
    const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 10;
    if(hasReachedBottom){
      fetchData(false);
    }
  };

  
  const handleScroll = () => {
  
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading) {
      return;
    }
    fetchData(false);
  };

  const handleScrollNotworking = (e) => {
    //https://stackoverflow.com/questions/45585542/detecting-when-user-scrolls-to-bottom-of-div-with-react-js
    if(isLoading){
      return
    }
    const bottom = ((e.target.scrollHeight - e.target.scrollTop) ===  e.target.clientHeight);
    if (bottom) { 
      fetchData(false);
    }
  }

//https://blog.logrocket.com/3-ways-implement-infinite-scroll-react/



  /**useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading]);**/


  
  return (
    <>
    {loading ?    <div className="loader-image"><img src='/images/loader.gif'/></div>  :  

    <>
    <div className='container tab-btns execuded-wrap holding-wrap'>
      <div className='order-open'>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor='inherit'
            aria-label='basic tabs example'
            className='tabs'
          >
            <Tab
              value={0}
              onClick={() => {
           
                props.setDisplayPage('PendingOrders');
              }}
              label={`Open (${props.pendingOrdersCount}) `}
            />
            <Tab
              value={1}
              onClick={() => {
             
                props.setDisplayPage('ExecutedOrders');
              }}
              label='Executed'
            />
              <HeaderShowHide hideHeader={props.hideHeader} setHideHeader={props.setHideHeader}/>
          </Tabs>
        </Box>
      </div>

      
      <div className='d-flex align-items-baseline justify-content-between search-main-orders'>
            <div className='search-box'>
              <input
                type='text'
                className='search-bar'
                name='search'
                placeholder='Search'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span>
                <SearchIcon />
              </span>

              {searchValue  && <span className='order-search-close' onClick={(event) => {

handleSearchClear(event)

}}  >  <CloseIcon/> </span>}
            </div>
           <div className='ms-1'>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <SortIcon sx={{ fill: 'white' }} />
              </IconButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disabled>Sort By</MenuItem>
                {options.map((option) => (
                  <MenuItem
                    key={option.option_name}
                    onClick={() => {

                      handleSort(option)
                     
                    }}
                  >
                    <div className='d-flex justify-content-between w-100'>
                      <span>{option.option_name}</span>
                      <span>
                        {selectedOptionName == option.option_name ? (
                          <CheckCircleTwoToneIcon
                            sx={{ fill: 'green', marginLeft: '10px' }}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </div> 
          </div>
    

      {executedOrders.length == 0 && 
         <div className='d-flex d-block-list'>
          
            <p className='name-header'>No Executed Orders. Place Order From Market Tab</p>
            
          </div>
          
          }
      <div className=''>
        {executedOrders &&
          executedOrders
            .map((executedOrder) => (
              <OrderRowDisplay order={executedOrder} key={executedOrder._id} />
            ))}
      </div>

      <PaginationList  nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} fetchData={fetchData} selectedMenu={selectedMenu} sortOrder={sortOrder} searchValue={searchValue}/>


    </div>
    </>
    }
    </>
  );
};

export default ExecutedOrders;
