
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { Box, Tab, Tabs } from '@mui/material';
import { createContext, useState, useEffect } from 'react';
function StockOptionsBreadcrumb(props) {

  const [value, setValue] = useState(props.displayPage);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


return (
    
       <div className=" assets-listing">
        {/* 
    <Stack direction="row" spacing={2}>
    <Chip label="WatchList" variant={props.displayPage == "WatchList" ? "filled" : "outlined"} color="primary" size="small" clickable onClick={() => props.setDisplayPage("WatchList")}/>
    <Chip label="Stocks" variant={props.displayPage == "Stocks" ? "filled" : "outlined"} color="primary"  size="small" clickable onClick={() => props.setDisplayPage("Stocks")}/>
    <Chip label="Futures" variant={props.displayPage == "StocksFutures" ? "filled" : "outlined"} color="primary" size="small" clickable onClick={() => props.setDisplayPage("StocksFutures")}/>
    <Chip label="Options" variant={props.displayPage == "StocksOptions" ? "filled" : "outlined"} color="primary" size="small" clickable onClick={() => props.setDisplayPage("StocksOptions")}/>

          </Stack>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor='inherit'
            aria-label='basic tabs example'
            className='tabs'
          >
            <Tab
              value={0}
              onClick={() => {
             
                props.setDisplayPage('WatchList');
              }}
              label="WatchList"
            />
            <Tab
              value={1}
              onClick={() => {
                  props.setDisplayPage('Stocks');
              }}
              label='Stocks'
            />
      
          </Tabs>
   
        </Box>*/}

<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
       
        aria-label="secondary tabs example"
         className='tabs'
      >
        <Tab value="WatchList" label="WatchList"    onClick={() => {
             
             props.setDisplayPage('WatchList');
           }}/>
        <Tab value="Stocks" label="Stocks"    onClick={() => {
             
             props.setDisplayPage('Stocks');
           }}/>
        <Tab value="StocksFutures" label="Futures"    onClick={() => {
             
             props.setDisplayPage('StocksFutures');
           }}/>
          {/**   <Tab value="StocksOptions" label="Options"    onClick={() => {
             
             props.setDisplayPage('StocksOptions');
           }}/> */}
      </Tabs>
    </Box>
          </div>

      
    
);

}

export default StockOptionsBreadcrumb;