import { Image } from 'react-bootstrap';
import ForwardIcon from '@mui/icons-material/Forward';
const ClosedPositionDisplayRow = (props) => {
  let assetImage;
  assetImage = (
    <Image
      className='rounded w-[24px] h-[24px]'
      style={{ height: 30, paddingRight: 10}}
      src={`https://storage.googleapis.com/stocks_logo/stocks/${props.order.symbol}.png`}
      loading='lazy'
    />
  );

  // if order type === limit then show limit_price otherwise show entryprice
  return (
    <div className='closed-holding'>
      {/* Transaction Type
      <p>
        {props.order.transactionType}
        {'\n'}{' '}
      </p>
      Order Price Type<p> {props.order.orderPriceType}</p>
      <div className='d-flex align-items-center'>{assetImage}</div>
      symbol
      <p>
        {props.order.symbol}
        {'\n'}
      </p>
      name<p>{props.order.asset_name}</p>
      quantity<p>{props.order.quantity}</p>
      entry price<p>{props.order.entryprice}</p>
      markprice<p>{props.order.markprice}</p>
      entryTimeString <p>{props.order.entryTimeString}</p>
      exitTimeString <p>{props.order.exitTimeString}</p>
      profitlossOnInvestedPoints <p>{props.order.profitlossOnInvestedPoints}</p>
      profitlossPercentOnInvestedPoints{' '}
      <p>{props.order.profitlossPercentOnInvestedPoints}</p>rgb(36, 38, 46) */}
      {props.order && (
        <div className='card mb-2 closed-box'>
          <div className='card-body'>
            <div className='d-flex align-items-center closed-asset-main'>
              <div className='d-flex main-holding-row'>
              {/*   <div className='d-flex'>{assetImage}</div>*/}
                <div className='closed-asset-name'>
                  <h5 className='card-title closed-card-title'>{props.order.asset_name}      <span className='card-subtitle text-muted closed-card-subtitle'>
                    ({props.order.symbol}) 
                  </span></h5>

                </div>
       
             
               
                <div className={`card-title holding-title ${ Number(props.order.profitlossPercentOnInvestedPoints).toFixed(2) < 0 ? "negative" : "positive"}`}>
              
                    {props.order.profitlossPercentOnInvestedPoints && Number(props.order.profitlossPercentOnInvestedPoints).toFixed(2)}
                    {' % '}
                    {props.order.profitlossPercentOnInvestedPoints && props.order.profitlossPercentOnInvestedPoints
                      .toString()
                      .charAt(0) === '-' ? (
                      <ForwardIcon
                        sx={{ transform: 'rotate(90deg)' }}
                      />
                    ) : (
                      <ForwardIcon
                        sx={{ transform: 'rotate(-90deg)'}}
                      />
                    )}
                  </div>
              </div>
            </div>
            
            <div className='flast-n-tags'>
                <span className='t-type'>  {props.order.transactionType}</span>
                <span className='flash-message'>  {props.order.order_time_type}</span>
                </div>

            <div className='card-text d-flex closed-box'>
            <span className='hide-mbl red-btn'>{props.order.transactionType}</span>
              <div className='col-4 d-flex flex-column  text-align-center flex-column '>
                <div className='text-center'>
                  <p className='closed-label'>Entry Price</p>
                  <h6 className='closed-value'>₹{Number(props.order.entryprice).toFixed(2)}</h6 >
                </div>
                <div className='text-center'>
                  <p className='closed-label'>Entry Date</p>
                  <h6  className='closed-value date-text'>{props.order.entryTimeString}</h6 >
                </div>
              </div>
              <div className='col-4 d-flex flex-column  text-align-center1 flex-column'>
                <div className='text-center'>
                <p className='closed-label'>Exit Price</p>
                  <h6  className='closed-value'>₹{Number(props.order.markprice).toFixed(2)}</h6 >
                </div>
                <div className='text-center'>
                <p className='closed-label'>Exit Date</p>
                  <h6  className='closed-value date-text'>{props.order.exitTimeString}</h6 >
                </div>
              </div>
              <div className='col-4 d-flex flex-column  text-align-center flex-column'>
                <div className='text-center'>
                  <p className='closed-label'>Profit Loss</p>
                  <h6  className='closed-value'>
                  <div className={`${ Number(props.order.profitlossOnInvestedPoints).toFixed(2) < 0 ? "negative" : "positive"}`}>
                    {Number(props.order.profitlossOnInvestedPoints).toFixed(2)}
                    </div>
                    </h6 >
                </div>
                <div className='text-center'>
                <p className='closed-label'>Quantity</p>
                  <h6  className='closed-value date-text'>{props.order.quantity}</h6 >
                </div>
              </div>
            </div>
          </div>
        </div>
        
      )}
        {/* <hr className='individual-card'></hr> */}
    </div>
    
  );
};
export default ClosedPositionDisplayRow;
