import React, { createContext, useState, useEffect } from 'react';
import ForwardIcon from '@mui/icons-material/Forward';
import api from '../services/api';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import HoldingRowDisplay from '../components/HoldingRowDisplay';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import SortIcon from '@mui/icons-material/Sort';
import { Box, Tabs, Tab } from '@mui/material';
import { HoldingsSidebar } from '../components/HoldingsSidebar';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import Zoom from '../components/Zoom';

const Dashboard = (props) => {

  console.log("loading Dashboard Comp")
  const [account, setAccount] = useState('');
  const [loading, setLoading] = useState(true);


 let dashboardsubscribeSymbols = []


  useEffect(() => {
    console.log("loading holdings api ")
    setLoading(true)
    api
      .getAccountPnLDetails()
      .then(({ data }) => {
        if(data.success){
          props.setHoldings(data.account.holdings);
          props.setPositions(data.account.positions);
          setAccount(data.account);
         // props.setHoldingsAssetArray(data.holdingsAssetArray)
         // props.setPositionsAssetArray(data.positionsAssetArray)
          setLoading(false)

          const holding_symbols = data.account.holdings.map(object => object.symbol);
          dashboardsubscribeSymbols.push(...holding_symbols);
          const position_symbols = data.account.positions.map(object => object.symbol);
          dashboardsubscribeSymbols.push(...position_symbols);
          
          props.setSubscribeSymbols(dashboardsubscribeSymbols)
          //let subscribeMessage = {"action":"subscribe", "instruments":subscribeSymbols}
          //props.sendWebsocketMessageToServer(subscribeMessage)
        }
      
       
      })
      .catch((error) => {
        console.log(error);
      });


     /* return () => {
        let unsubscribeMessage = {"action":"unsubscribe", "instruments":subscribeSymbols}
        props.sendWebsocketMessageToServer(unsubscribeMessage)
    }*/

  }, [props.refresh]);

 


  let HoldingsUnrealisedPnL = 0.0
  var holdings_invested_amount = 0.0
  var holdings_current_value = 0.0
  let HoldingsPnLPercent = 0.0
  for (let i = 0; i < props.holdings.length; i++) {
    //HoldingsUnrealisedPnL = (Number(HoldingsUnrealisedPnL) + Number(getHoldingPnL(props.holdings[i])))
    HoldingsUnrealisedPnL = Number(HoldingsUnrealisedPnL) + Number(((props.holdings[i].ltp - props.holdings[i].avgPrice) * props.holdings[i].quantity * props.holdings[i].side))
    holdings_invested_amount = (Number(holdings_invested_amount) + Number(props.holdings[i].quantity * props.holdings[i].avgPrice))
    holdings_current_value = (Number(holdings_current_value) +   Number(props.holdings[i].ltp * props.holdings[i].quantity) )
  }

  if(holdings_invested_amount > 0 ){
    HoldingsPnLPercent =   ((Number(HoldingsUnrealisedPnL)/Number(holdings_invested_amount))*100).toFixed(2) 
  }
   

  let PositionsUnrealisedPnL = 0.0
  var positions_invested_amount = 0.0
  var positions_current_value = 0.0
  let PositionsPnLPercent = 0.0
  for (let i = 0; i < props.positions.length; i++) {
    //HoldingsUnrealisedPnL = (Number(HoldingsUnrealisedPnL) + Number(getHoldingPnL(props.holdings[i])))
    PositionsUnrealisedPnL = Number(PositionsUnrealisedPnL) + Number(((props.positions[i].ltp - props.positions[i].avgPrice) * props.positions[i].quantity * props.positions[i].side))
    positions_invested_amount = (Number(positions_invested_amount) + Number(props.positions[i].quantity * props.positions[i].avgPrice))
    positions_current_value = (Number(positions_current_value) +   Number(props.positions[i].ltp * props.positions[i].quantity) )
  }
 
  if(positions_invested_amount > 0){
     PositionsPnLPercent =   ((Number(PositionsUnrealisedPnL)/Number(positions_invested_amount))*100).toFixed(2)
  }
  
  
     const AccountUnrealisedPnL = (Number(HoldingsUnrealisedPnL) + Number(PositionsUnrealisedPnL))

    const AccountPnLPercent =  (((Number(account.realized_pnl) + Number(AccountUnrealisedPnL))/Number(account.start_amount))*100)
  return (
    <>
    {loading ?    <div className="loader-image"><img src='/images/loader.gif'/></div>  :  
     <div className='container tab-btns holding-wrap dashboard-stats dashboard-ftx-wrap'>
      

        <div className='holding-start'>
        <div className='flash-message  dashboard-sec hide-it'><span><DonutSmallIcon/></span> Dashboard</div>

          {account && (
            <div
              className='holding-stats-main d-flex my-3 card'
              style={{ background: 'rgb(10 11 14)' }}
            >
              <div className='row holding-stats'>
                <div className='col-12 d-flex align-items-center justify-content-between holding-assets-info'>
                  <div className='d-flex top-holding dashboard-overview'>
                    {/* <div className='dashboard-money-img'>
                      <img src='/images/bag.png'/>
                      </div>*/}
                    <div className='d-flex flex-column holding-title1-container dashboard-1'>
                      <p className='holding-title1'>Portfolio Value</p>
                     
                      <p className={`holding-title1-value ${ (Number(account.realized_pnl) + Number(AccountUnrealisedPnL)) < 0 ? "negative" : "positive"}`}>
                        ₹
                        {(Number(account.realized_pnl) + Number(AccountUnrealisedPnL) + Number(account.start_amount)).toFixed(2) }
                      </p>
                      
                    </div>
                    <div className='d-flex flex-column holding-title1-container dashboard-2'>
                      <p className='holding-title1'>P&L</p>
                      <p className='holding-title1-value'>
                    <span className={`total-pl ${ (Number(account.realized_pnl) + Number(AccountUnrealisedPnL)) < 0 ? "negative" : "positive"}`}>
                      ₹
                       {(Number(account.realized_pnl) + Number(AccountUnrealisedPnL)).toFixed(2)}
                       </span>
                       <span className={`total-pl ${ AccountPnLPercent < 0 ? "negative" : "positive"}`}>
                       ({Number(AccountPnLPercent).toFixed(2) }{'%'})
                    {AccountPnLPercent
                      .toString()
                      .charAt(0) === '-' ? (
                      <ForwardIcon
                        sx={{ transform: 'rotate(90deg)' }}
                      />
                    ) : (
                      <ForwardIcon
                        sx={{ transform: 'rotate(-90deg)' }}
                      />
                    )}
                      </span>
                    </p>
                    </div>
                  </div>

                  
                </div>
                <div className='card-text d-flex holding-detailsval pl-stats'>
                  <div className='col-6  holding-info-section'>
                    <p className='holding-label'>Invested Amt.</p>
                    <p className='holdig-val'>
                      {' '}
                      ₹{account.invested_amount && Number(account.invested_amount).toFixed(2)}
                    </p>
                  </div>
                  <div className='col-6 holding-info-section'>
                    {' '}
                    <p className='holding-label'>Available Margin</p>
                    <p className='holdig-val'>
                      {' '}
                      ₹{Number(account.points).toFixed(2)}
                    </p>
                  </div>
                  {/* <div className='col-4 holding-info-section'>
                    {' '}
                    <p className='holding-label'>Unrealised P&L</p>
                    <p className='holdig-val'>
                      {' '}
                      ₹{Number(AccountUnrealisedPnL).toFixed(2)}
                    </p>
                  </div> */}
                </div>
                <div className=' card-text holding-detailsval taxes-stats'>
                <div className='col-8 holding-info-section'>
                    {' '}
                    <p className='holding-label'> Realised P&L</p>
                    <p className={`holdig-val ${ account.realized_pnl < 0 ? "negative" : "positive"}`}>
                 
                      {' '}
                      ₹{Number(account.realized_pnl).toFixed(2)}
                    </p>
                  </div>
                  <div className='col-8 holding-info-section'>
                    {' '}
                    <p className='holding-label'> Unrealised P&L</p>
                    <p className={`holdig-val ${ AccountUnrealisedPnL < 0 ? "negative" : "positive"}`}>
                      {' '}
                      ₹{Number(AccountUnrealisedPnL).toFixed(2)}
                    </p>
                  </div>
                  <div className='col-8  holding-info-section'>
                    <p className='holding-label'>Charges & Taxes</p>
                    <p className='holdig-val'>
                      {' '}
                      ₹{Number(account.transaction_charges).toFixed(2)}
                    </p>
                  </div>
                  <div className='col-8 holding-info-section'>
                    {' '}
                    <p className='holding-label'>Pending Ord Amt</p>
                    <p className='holdig-val'>
                      {' '}
                      ₹{Number(account.pending_order_amount).toFixed(2)}
                    </p>
                  </div>
                  
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='dashboard-ftx-inner'>
<div className='dashboard-ftx'>
<div className='flash-message dashboard-sec dashboard-sec-holding'  onClick={() => {props.setDisplayPage('Holdings'); }}   ><span><BusinessCenterIcon/></span> Holdings <span className='number'>({props.holdings.length})</span></div>
      <div className='holding-start  dashboard-holding'>
          {(
            <div
              className='holding-stats-main d-flex mb-3 card'
              style={{ background: 'rgb(10 11 14)' }}
            >
              <div className='holding-stats'>
                <div className='col-10 d-flex align-items-center justify-content-between holding-assets-info'>
                  <div className='col-7 d-flex top-holding'>
              
                    <div className='d-flex flex-column holding-title1-container'>
                      <p className="holding-title1-value">
                      <span className={`${ Number(HoldingsUnrealisedPnL).toFixed(2) < 0 ? "negative" : "positive"}`}>
                      <big>
                        ₹
                        { Number(HoldingsUnrealisedPnL).toFixed(2)}
                      </big>
                      </span>
                        <span className={`${ HoldingsPnLPercent < 0 ? "negative" : "positive"}`}>
                        ({  Number(HoldingsPnLPercent).toFixed(2)  }
                    {'%'})
                    {HoldingsPnLPercent
                      .toString()
                      .charAt(0) === '-' ? (
                      <ForwardIcon
                        sx={{ transform: 'rotate(90deg)'  }}
                      />
                    ) : (
                      <ForwardIcon
                        sx={{ transform: 'rotate(-90deg)' }}
                      />
                    )}
                        </span>
                        </p>
                      <p className='holding-title1'>P&L</p>

                    </div>
                   
                  </div>
                  <div className=' holding-per1  total-stats'>
                  <p className='holding-label dashboard-label'>Invested Amt. <span className='holdig-val'>₹{Number(holdings_invested_amount).toFixed(2)}</span></p>
                  <p className='holding-label dashboard-label'>Current Value <span className='holdig-val'>₹{Number(holdings_current_value).toFixed(2)}</span></p>   
                  </div>

                  
                </div>
        
                
              </div>
            </div>
          )}
        </div>
        </div>
        <div className='dashboard-ftx'>

        <div className='flash-message dashboard-sec dashboard-sec-holding' onClick={() => {props.setDisplayPage('Positions'); }}><span><SwapVertIcon/></span> Positions <span className='number'>({props.positions.length})</span></div>
      <div className='holding-start position-dashboard'>
          {(
            <div
              className='holding-stats-main d-flex mb-2 card'
              style={{ background: 'rgb(10 11 14)' }}
            >
              <div className='row holding-stats'>
                <div className='col-10 d-flex align-items-center justify-content-between holding-assets-info'>
                  <div className='col-7 d-flex top-holding'>
              
                    <div className='d-flex flex-column holding-title1-container'>
                     <p className="holding-title1-value">
                      <span className={` ${ PositionsUnrealisedPnL < 0 ? "negative" : "positive"}`}>
                        <big>
                        ₹
                        { Number(PositionsUnrealisedPnL).toFixed(2)}
                        </big>
                        </span>
                        <span className={`${ PositionsPnLPercent < 0 ? "negative" : "positive"}`}>
                        ({  Number(PositionsPnLPercent).toFixed(2)  }
                    {'%'})
                    {PositionsPnLPercent
                      .toString()
                      .charAt(0) === '-' ? (
                      <ForwardIcon
                        sx={{ transform: 'rotate(90deg)'}}
                      />
                    ) : (
                      <ForwardIcon
                        sx={{ transform: 'rotate(-90deg)' }}
                      />
                    )}
                        </span>
                        </p>
                    
                      <p className='holding-title1'>P&L</p>

                    </div>
                   
                  </div>
                  <div className=' holding-per1 total-stats'>
                  <p className='holding-label dashboard-label'>Invested Amt. <span className='holdig-val'> ₹{Number(positions_invested_amount).toFixed(2)}</span></p>
                  <p className='holding-label dashboard-label'>Current Value <span className='holdig-val'> ₹{Number(positions_current_value).toFixed(2)}</span></p>

                  </div>

                  
                </div>
        
                
              </div>
            </div>
          )}
        </div>
    </div>

    </div>
    </div>
}
</>
  );
};

export default Dashboard;
