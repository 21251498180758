import React, { createContext, useState, useEffect } from 'react';
import ForwardIcon from '@mui/icons-material/Forward';
import api from '../services/api';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import HoldingRowDisplay from '../components/HoldingRowDisplay';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import SortIcon from '@mui/icons-material/Sort';
import { Box, Tabs, Tab } from '@mui/material';
import { HoldingsSidebar } from '../components/HoldingsSidebar';
import HeaderShowHide from '../components/HeaderShowHide';
import LengthDisplayCircle from '../components/LengthDisplayCircle';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
const Holdings = (props) => {

  console.log("loading Holdings Comp")


  const [account, setAccount] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tabValue, setTabValue] = useState(0);
 
  const [selectedMenu, setSelectedMenu] = useState('quantity');
  const [loading, setLoading] = useState(true);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSearchClear = (event) => {
    setSearchValue('')
   };
   const handleSort = (sortName, sortProperty,sortDirection) => {
    setSelectedMenu(sortName)
   
      const sorted = [...props.holdings]; // create a new array & not mutate state
  
      sorted.sort((a, b) => {
        return sortDirection === 1 ? a[sortProperty] - b[sortProperty] : b[sortProperty] - a[sortProperty];
      });
      props.setHoldings(sorted); //re-render
      handleClose()
    
   };

  
  const options = [
    {option_name: "Profit Loss % (Low to High)", option_property:"pnlPercent" , sort_order:1},
    {option_name: "Profit Loss % (High to Low)", option_property:"pnlPercent" , sort_order:-1},
    {option_name: "Amount Invested (Low to High)", option_property:"amount_invested" , sort_order:1},
    {option_name: "Amount Invested (High to Low)", option_property:"amount_invested", sort_order:-1},
    {option_name: "Profit Loss Absolute(Low to High)", option_property:"profitloss" , sort_order:1},
    {option_name: "Profit Loss Absolute (High to Low)", option_property:"profitloss" , sort_order:-1},
    {option_name: "Quantity (Low to High)", option_property:"quantity" , sort_order:1},
    {option_name: "Quantity (High to Low)", option_property:"quantity" , sort_order:-1},
    
  ];


//let subscribeSymbols = []




  useEffect(() => {
    console.log("loading holdings api ")
    
    let account_id = JSON.parse(localStorage.getItem('account_id'));
    //console.log(customerid + token);
    let customerInfo = {
    
      account_id: account_id,
    };
    setLoading(true)
    api
      .getHoldings(customerInfo)
      .then(({ data }) => {
        if(data.success){
          props.setHoldings(data.account.holdings);
          setAccount(data.account);
          props.setPositionsCount(data.account.positionsCount)
          props.setHoldingsCount(data.account.holdings.length)
         // props.setHoldingsAssetArray(data.currentPrice)
          setLoading(false)
         
        
          //subscribeSymbols = data.account.holdings.map(object => object.symbol);
          props.setSubscribeSymbols(data.account.holdings.map(object => object.symbol))


        //  let subscribeMessage = {"action":"subscribe", "instruments":subscribeSymbols}
         // props.sendWebsocketMessageToServer(subscribeMessage)
        }
       
      })
      .catch((error) => {
        console.log(error);
      });


     /* return () => {
        let unsubscribeMessage = {"action":"unsubscribe", "instruments":subscribeSymbols}
        props.sendWebsocketMessageToServer(unsubscribeMessage)
    }*/

  }, [props.refresh]);

 
  let HoldingsUnrealisedPnL = 0.0
  var holdings_invested_amount = 0.0
  var holdings_current_value = 0.0
  let HoldingsPnLPercent = 0.0
  for (let i = 0; i < props.holdings.length; i++) {
    //HoldingsUnrealisedPnL = (Number(HoldingsUnrealisedPnL) + Number(getHoldingPnL(props.holdings[i])))
    HoldingsUnrealisedPnL = Number(HoldingsUnrealisedPnL) + Number(((props.holdings[i].ltp - props.holdings[i].avgPrice) * props.holdings[i].quantity * props.holdings[i].side))
    holdings_invested_amount = (Number(holdings_invested_amount) + Number(props.holdings[i].quantity * props.holdings[i].avgPrice))
    holdings_current_value = (Number(holdings_current_value) +   Number(props.holdings[i].ltp * props.holdings[i].quantity) )
  }

  if(holdings_invested_amount > 0){
    HoldingsPnLPercent =   ((Number(HoldingsUnrealisedPnL)/Number(holdings_invested_amount))*100).toFixed(2)
  }
   

 function getHoldingPnL(holding){
  var currentAssetObjectPrice = holding.avgPrice
  const currentAssetObject = props.holdingsAssetArray.find((obj) => {
     return obj.symbol === holding.symbol;
   });
   if(currentAssetObject){
    currentAssetObjectPrice = currentAssetObject.price
   }

  return Number(((currentAssetObjectPrice - holding.avgPrice) * holding.quantity * holding.side)).toFixed(2)
 }
  
  


 

 function getHoldingsCurrentValue(holding){
     var currentAssetObjectPrice = holding.avgPrice
     const currentAssetObject = props.holdingsAssetArray.find((obj) => {
        return obj.symbol === holding.symbol;
      });
      if(currentAssetObject){
       currentAssetObjectPrice = currentAssetObject.price
      }
   
     return Number(currentAssetObjectPrice * holding.quantity).toFixed(2)
    }
 
  
//const HoldingsPnLPercent =  (HoldingsUnrealisedPnL>0) ? ((Number(HoldingsUnrealisedPnL)/Number(holdings_invested_amount))*100).toFixed(2) : 0

  return (
    <>
   {loading ?    <div className="loader-image"><img src='/images/loader.gif'/></div>  : 
    <div className='container tab-btns holding-wrap all-hold'>
      
      <div>
       
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor='inherit'
            aria-label='basic tabs example'
            className='tabs'
          
          >
            <Tab
              value={0}
              onClick={() => {
              
                props.setDisplayPage('Holdings');
              }}
              label={<LengthDisplayCircle label="Holdings" count={props.holdingsCount}/>}
          
            />{/*<Divider   textAlign="center" sx={{ bgcolor: "#0094FF", borderRightWidth: 5 }}  orientation="vertical"  flexItem />*/}
            <Tab
              value={1}
              onClick={() => {
         
                props.setDisplayPage('Positions');
              }}
              label=  {`Positions (${props.positionsCount}) `}
            
            />
           
                <Tab
              value={2}
              onClick={() => {
         
                props.setDisplayPage('ClosedPositions');
              }}
              label='Closed'
            />
               <HeaderShowHide hideHeader={props.hideHeader} setHideHeader={props.setHideHeader}/>
          </Tabs>
        </Box>
      </div>

      <div className=''>
      <div className='holding-start holding-parent'>
          { (
            <div
              className='holding-stats-main d-flex mt-3 card'
              style={{ background: 'rgb(10 11 14)' }}
            >
              <div className='holding-stats'>
               
                <div className='col-9 card-text d-flex holding-detailsval'>
                  <div className='holding-info-section text-left'>
                    <p className='holding-label'>Invested Amt.</p>
                    <p className='holdig-val'>
                      ₹{holdings_invested_amount.toFixed(2)}
                    </p>
                  </div>
                  <div className='holding-info-section  text-right '>
                    {' '}
                    <p className='holding-label'>Current Value</p>
                    <p className='holdig-val'>
                      {' '}
                      ₹{holdings_current_value.toFixed(2)}
                    </p>
                  </div>
             
                </div>

                <div className='row d-flex align-items-center justify-content-between holding-assets-info mt-2'>
                  <div className='col-4 d-flex top-holding'>
              
                    <div className='d-flex flex-column holding-title1-container'>
                      <p className='holding-title1 pl-title'>P&L</p>

                      
                    </div>
                   
                  </div>
                  <div className='col-6 holding-per1'>
                  <span className={`holding-title1-value pl-value ${ Number(HoldingsUnrealisedPnL).toFixed(2) < 0 ? "negative" : "positive"}`}>
                  
                        ₹
                        {Number(HoldingsUnrealisedPnL).toFixed(2)}
                      </span>
                      <span className={`pl-percent ${ Number(HoldingsPnLPercent).toFixed(2) < 0 ? "negative" : "positive"}`}>
                  { HoldingsPnLPercent }
                    {'%'}
                    {HoldingsPnLPercent
                      .toString()
                      .charAt(0) === '-' ? (
                      <ForwardIcon
                        sx={{ transform: 'rotate(90deg)' }}
                      />
                    ) : (
                      <ForwardIcon
                        sx={{ transform: 'rotate(-90deg)' }}
                      />
                    )}
                    </span>
                  </div>

                  
                </div>
                
              </div>
            </div>
          )}
        </div>
        <>
          <div className='d-flex align-items-baseline holding-search'>
            <div className='search-box' style={{ width: '100%' }}>
              <input
                type='text'
                className='search-bar'
                name='search'
                placeholder='Search'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span>
                <SearchIcon />
              </span>

              {searchValue  && <span className='name-header' onClick={handleSearchClear} >  <CloseIcon/> </span>}
            </div>
           { <div className='ms-1'>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <SortIcon sx={{ fill: 'white' }} />
              </IconButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disabled>Sort By</MenuItem>
                {options.map((option) => (
                  <MenuItem
                    key={option.option_name}
                    onClick={() => {
                      handleSort(option.option_name,option.option_property,option.sort_order)
                    }}
                  >
                    <div className='d-flex justify-content-between w-100'>
                      <span>{option.option_name}</span>
                      <span>
                        {selectedMenu === option.option_name ? (
                          <CheckCircleTwoToneIcon
                            sx={{ fill: 'green', marginLeft: '10px' }}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </div> }
          </div>
        </>

        {props.holdings.length == 0 && 
         <div className='d-flex d-block-list'>
          
            <p className='name-header'>No Holdings. Place Order From Market Tab</p>
            
          </div>
          
          }

        <div className=''>
          
          {props.holdings &&
            props.holdings
              .filter((holding) =>
                holding.symbol.match(new RegExp(searchValue, 'i'))
              )
              .map((holding, index) => (
                <HoldingRowDisplay
                  holding={holding}
                  index={index}
                  account={account}
                  key={holding._id}
                  displayPage={props.displayPage}
                  setDisplayPage={props.setDisplayPage}
                  setSelectedAssetName={props.setSelectedAssetName}
                  selectedQuantityForTransaction={props.selectedQuantityForTransaction}
                  setSelectedQuantityForTransaction={props.setSelectedQuantityForTransaction}
                  orderTimeType={props.orderTimeType}
                  setOrderTimeType={props.setOrderTimeType}
                  transactionType={props.transactionType}
                  setTransactionType={props.setTransactionType}
                  isHolding={true}
               //   holdingsAssetArray={props.holdingsAssetArray}
                  selectedAsset={props.selectedAsset}
                  setSelectedAsset={props.setSelectedAsset}
                  setPreviousPage={props.setPreviousPage}
                  
           
                 
                />

              ))}
        </div>
      </div>
     {/**<HoldingsSidebar
        showSelectedHoldings={showSelectedHoldings}
        setShowSelectedHoldings={setShowSelectedHoldings}
        setSelectedHoldings={setSelectedHoldings}
        selectedHoldings={selectedHoldings}
      /> */} 
    </div>
    }
    </>
  );
};

export default Holdings;
