
import React from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import api from "../services/api";
import { UserContext } from "../contexts/UserContext";
import { Button } from "react-bootstrap";
import VisibilityIcon from '@mui/icons-material/Visibility';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';



export default function Login(props) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [loginInfo, setLoginInfo] = useState({
    phoneNumber: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate()
  let display_message = ""
 const location = useLocation()

 if(location.state && location.state.display_message){
  display_message = location.state.display_message
 }

  function updateState(event) {
    const { name, value } = event.target;
    setLoginInfo((prevState) => ({ ...prevState, [name]: value }));
  }

 
  function loginCustomer(event) {
   
    setButtonDisabled(true)
    setLoading(true)
    event.preventDefault();
    api
      .login(loginInfo)
      .then(({ data }) => {
     
        if(data.error){
          setErrorMessage(data.error);
          setButtonDisabled(false)
          setLoading(false)
          return;
        }
       
        if(data.success){
         // console.log(data)
          setErrorMessage("");
          localStorage.setItem("customerId", JSON.stringify(data.user._id));
          localStorage.setItem("account_id", JSON.stringify(data.user.account._id));
         localStorage.setItem("account_name", JSON.stringify(data.user.account.name));
          localStorage.setItem("phoneNumber", JSON.stringify(data.user.phoneNumber));
        //  localStorage.setItem("updatedPoints", JSON.stringify(data.user.account.points));
          //localStorage.setItem("instruments", JSON.stringify(data.user.account.subscribed_instruments));
          localStorage.setItem("expirationtime", data.expirationtime);
         // localStorage.setItem("selectedQuantityForTransaction", JSON.stringify(1));
          localStorage.setItem("darkMode", JSON.stringify(data.user.darkMode));
          console.log("navigating")
          setButtonDisabled(false)
          setLoading(false)
          navigate("/trade");
        }

      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Some error occured");
        setButtonDisabled(false)
        setLoading(false)
        
      });
  }




  // Password Show and hide
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  // Password Show and hide
  
  return (
    
    <div
      className="bg-dark text-white px-4 login-part"
      style={{ minHeight: "100vh", paddingTop: "90px" }}
    >
      {loading && <div className="loader"></div>}
      <div>
        
        <h4>Login to Platform</h4>
       <div className="error-msg">
        {errorMessage}
        {errorMessage === "" && display_message}
        </div>
      </div>

      <div className="py-3 login-form">
        <form onSubmit={loginCustomer}>
          <div className="d-block phone-field">
            <label>Phone Number</label>
            <span className="phone-f">+91</span>
            <input
              className="d-block p-1 bg-light rounded"
              name="phoneNumber"
              type="number"
              onChange={updateState}
              value={loginInfo.phoneNumber}
              placeholder=""
              required
            />
          </div>
          <div className="d-block mt-2 password-field">
            <label>Password</label>
            <input
        className="d-block p-1 bg-light rounded"
        type={passwordVisible ? "text" : "password"}
        name="password"
        onChange={updateState}
        value={loginInfo.password}
        required
      />
      <span className="password-v" onClick={togglePasswordVisibility}>
        {passwordVisible ? <VisibilityIcon/> : <VisibilityOffIcon/>} 
      </span>
          </div>
       
          <div className="forget-password">
          <a onClick={() => props.setDisplayAuthPage("ForgotPassword") }>Forgot your password?</a>
        </div>


          <Button
            variant="contained"
            className="btn btn-primary main-btn"
            type="submit"
            disabled={isButtonDisabled}
          
          >
            Login
          </Button>
        </form>
   
      </div>

   


 <big style={{textAlign: "center", margin: "15px 0px", display: "block"}}>OR</big>
  

<div className="text-center register-area">
  <span>Don't have an account ?</span>
<Button onClick={() => props.setDisplayAuthPage("Register")}
            variant="contained"
            className="reg-btn main-btn btn-primary"
            type="submit"
          
          >
           Register
          </Button>
          </div>

         
    </div>
  );
}
