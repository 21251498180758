import React from 'react';
import FooterNew from './FooterNew';
import Header from './Header';

import { useState, useContext, useEffect } from 'react';
import useWindowSize from '../components/useWindowSize';

function GlobalLayout(props) {
  const windowSize = useWindowSize(); 
  // console.log("111111111", props.children);
  // console.log("props123", props);
  

  return (
    <div>
      <div
        className='w-100 text-white bg-dark mobile_show'
        style={{ minHeight: '100vh' }}
      >
        {/* <div>
          <main>{props.children}</main>
        </div> */}
      </div>

      {windowSize.width >= 768 ? (
        <>
          <div className='header_stage'>
          <Header refresh={props.refresh} doRefresh={props.doRefresh} flashMessage={props.flashMessage} setFlashMessage={props.setFlashMessage} hideHeader={props.hideHeader} 
          setDisplayPage={props.setDisplayPage}
          />

          </div>
          <div className='desktop_stage'>
            <div className='stage1P1'>
              <FooterNew
              
            
                displayPage={props.displayPage}
                setDisplayPage={props.setDisplayPage}
             
              />
            </div>
          
              <div className='stage1P2'>
                <main>{props.children} </main>
              </div>
           
          </div>
        </>
      ) : (
        <>
          <Header refresh={props.refresh} doRefresh={props.doRefresh} flashMessage={props.flashMessage} setFlashMessage={props.setFlashMessage}
          hideHeader={props.hideHeader}
          setDisplayPage={props.setDisplayPage}
          />

          <main>{props.children}</main>
          <FooterNew
         
         
            displayPage={props.displayPage}
            setDisplayPage={props.setDisplayPage}
          
          />
        </>
      )}
    </div>
  );
}

export default GlobalLayout;
