import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Switch from '@mui/material/Switch';
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { createContext, useState, useEffect, useContext } from "react";
import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { UserContext } from "../contexts/UserContext";
import api from "../services/api";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyIcon from "@mui/icons-material/Key";
import CompareOutlinedIcon from "@mui/icons-material/CompareOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import WalletIcon from "@mui/icons-material/Wallet";
import HistoryIcon from "@mui/icons-material/History";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SubjectIcon from "@mui/icons-material/Subject";
import FlashMessage from "./FlashMessage";
// import Adidas from '../../public/images/adidas.png';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import useWindowSize from '../components/useWindowSize';
const switchlabel = { inputProps: { 'aria-label': 'Dark Mode' } };
const Header = (props) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    password: false,
    wallet: false,
  });

  const [changePasswordInfo, setChangePasswordInfo] = useState({

  });

  const [changePasswordSuccessMessage, setChangePasswordSuccessMessage] = useState("");
  const [isDarkModeToggled, setIsDarkModeToggled] = useState(false);
  const [refreshAccounts, doRefreshAccounts] = useState(0); 

  const windowSize = useWindowSize(); 

 


  useEffect(() => {
    if ((localStorage.getItem("darkMode")!== null) && localStorage.getItem("darkMode")!== "undefined" && !JSON.parse(localStorage.getItem("darkMode")) && !document.body.classList.contains("light-mode")) {
      console.log("toggling")
      document.body.classList.toggle("light-mode");
    }
  }, []);

  function updateState(event) {
    const { name, value } = event.target;
    setChangePasswordInfo((prevState) => ({ ...prevState, [name]: value }));
  }

  function getDarkMode(){
    console.log(JSON.parse(localStorage.getItem("darkMode")))
    if(JSON.parse(localStorage.getItem("darkMode"))=== true){
      return "ON"
    }else {
      return "OFF"
    }
  }


  const toggleDrawer = (anchor, open) => (event) => {
    console.log("anchor", anchor);
    dropdownOpen;
    console.log("anchor", anchor);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    
    if(anchor === "left" && open){
      console.log("INNN")
doRefreshAccounts(prev => prev + 1)
    }
    setState({ ...state, [anchor]: open });
  };






  const [showMenu, setShowMenu] = useState(false);
  //const { user } = JSON.parse(localStorage.getItem("customer"));

  const navigate = useNavigate();
 
  const [accountsList, setAccountsList]= useState([])
  const [currentAccountName, setCurrentAccountName] = useState(JSON.parse(localStorage.getItem('account_name')));


  useEffect(()=>{
    let customerid  = (JSON.parse(localStorage.getItem("customerId")));
    let  token  = (JSON.parse(localStorage.getItem("token")));
    api
    .getAccountLists(customerid, token)
    .then(({ data }) => { 
      console.log(data.accounts.accounts)
      setAccountsList(data.accounts.accounts)
    })
    .catch((error) => {
      console.log(error);
    
    });
  },[refreshAccounts]) 


 
 

  function handleAccountChange(accountId, account_name) {
    localStorage.setItem("account_id", JSON.stringify(accountId));
    localStorage.setItem("account_name", JSON.stringify(account_name));
    setCurrentAccountName(account_name)
    props.doRefresh(prev => prev + 1);
    setShowMenu(false)

    
  }


  
  function handleLogout() {
    api
      .logout({})
      .then(({ data }) => {
        if(data.sucess){
          localStorage.clear();
          navigate("/login");
        }
       
      })
      .catch((error) => {
        console.log(error);
        
      });
    }

  const dropdownOpen = () => {
    setShowMenu((prev) => !prev);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "100vw" }}
      role="presentation"
    >
      <div className="account-menu">
        <Box>
       {/** <Grid container className="account-li" alignItems={"center"}>
            <Grid item xs={2}>
              <AccountCircleOutlinedIcon />
            </Grid>
            <Grid item xs={10}>
              <b>Personal</b>
              <span>Name and personal details</span>
            </Grid>
          </Grid> */  }

          <Grid container className="account-li" alignItems={"center"}>
            <Grid item xs={2}>
              <KeyIcon />
            </Grid>
            <Grid item xs={10} onClick={toggleDrawer("password", true)}>
              <b>Password</b>
              <span>Keep your account secure</span>
            </Grid>
          </Grid>

         {<Grid container className="account-li" alignItems={"center"}>
            <Grid item xs={2}>
              <CompareOutlinedIcon />
            </Grid>
            <Grid item xs={10} onClick={toggleDrawer("theme", true)}>
              <b>Appearance</b>
              <span>Theme color and language</span>
            </Grid>
          </Grid> }
        </Box>
      </div>

      { (
        <button className="dark logout-btn" onClick={handleLogout}>
          <LogoutIcon /> log out
        </button>
      )}
    </Box>
  );

  const list1 = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "100vw" }}
      role="presentation"
     onClick={toggleDrawer(anchor, true)}
     onKeyDown={toggleDrawer(anchor, true)}
    >
      <div className="account-menu">
        {accountsList &&
          accountsList.map((account) => (
            <Grid key={account._id} container className="account-li" alignItems={"center"} 
           
            >
              <Grid item xs={10}  onClick={(event) => handleAccountChange(account._id, account.name)}>
                <span
                  
                  className="d-block username"
                >
                  PORTFOLIO: {account.name}
                </span>

                <span className="d-block points">
                  AVAILABLE MARGIN: {Number(account.points).toFixed(2)}
                </span>
              </Grid>
              <Grid item xs={2} className="more-btn">
                <Button aria-describedby={id} variant="contained" onClick={handleClickMore}>
                <MoreVertIcon/>
                </Button>
                <Popover
                  id={id}
                  className="more-submenu"
                  open={openMore}
                  anchorEl={anchorEl}
                  onClose={handleCloseMore}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 2 }} className="more-menu">
              <DriveFileRenameOutlineIcon/> Rename

                  </Typography>

                  <Typography sx={{ p: 2 }}  className="more-menu">
              <RestartAltIcon/> Reset

                  </Typography>
                </Popover>
              </Grid>
            </Grid>
            

            
          ))}

      </div>
    </Box>
  );

  /* Password */
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  /* Password */
  




  function handleChangePassword(event) {
   
    event.preventDefault();
   
    
    api
      .changePassword(changePasswordInfo)
      .then(({ data }) => {
        if(data.error){
         console.log(data)
            return;
          }
          if(data.success){
            setChangePasswordSuccessMessage("Password Successfuly Changed")
            console.log(data)
          }
      
        

      })
      .catch((error) => {
        console.log(error);
     
      
      });
    
  }


  const Toggleclass1 = (e) => {
    e.preventDefault();
    setIsDarkModeToggled(!isDarkModeToggled);
    document.body.classList.toggle("light-mode");
    const darkMode = JSON.parse(localStorage.getItem("darkMode"));
    localStorage.setItem("darkMode", JSON.stringify(!darkMode));
  
      
    api
      .updateDarkMode({darkMode:!darkMode})
      .then(({ data }) => {
        if(data.error){
         console.log(data.error)
            return;
          }
          if(data.success){
            console.log(data)
          }
      
        

      })
      .catch((error) => {
        console.log(error);
     
      
      });
  };




  // More Menu

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
  };

  const openMore = Boolean(anchorEl);
  const id = openMore ? 'simple-popover' : undefined;



  // Notification


  const [anchorEln, setAnchorEln] = React.useState(null);

  const handleClickn = (event) => {
    setAnchorEln(event.currentTarget);
  };

  const handleSearchIconClick = (event) => {
    props.setDisplayPage("WatchList")
  };


  const handleClosen = () => {
    setAnchorEln(null);
  };

  const openNotify = Boolean(anchorEln);
  const idn = openNotify ? 'simple-popover' : undefined;
  return (
  
   

    <div className="container d-flex justify-content-between pt-2 header-container">

    { props.flashMessage.message ? ( 
    <>
    <FlashMessage flashMessage={props.flashMessage} setFlashMessage={props.setFlashMessage}/>
    </>
    )

    : (
      <> 
      

       {(props.hideHeader && windowSize.height < 1000) ? ( 
        <>
        </>
        )
    
        : (
      
      <>  

   
      {/* <div
        onClick={(e) => {
          if (props.isDesktop) {
            props.handleclasschange("assets", !props.addClass.assets);
          }
        }}
        className="active-trade"
      >
        <img src="https://upload.wikimedia.org/wikipedia/commons/2/24/Adidas_logo.png" />
        <span>Adidas</span>
        <span className="indicator">ST</span>
      </div> */}

  

      <div className="account-sec">
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button 
            onClick={toggleDrawer(anchor, true)}  >
              <Grid container className="accounts" alignItems={"center"}>
                  {/**   <Grid item xs={2}>
             <AccountCircleOutlinedIcon />
                </Grid> */}
              {  <Grid item >
                  <span className="d-block username username-selected"> {currentAccountName}</span>

             {/**       <span className="d-block points">
                    {10000000000}{" "}
               
                  </span>  */}
                </Grid>}
                     <KeyboardArrowDownOutlinedIcon />
              </Grid>
            </Button> 
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
              className="dark-theme account-drawer"
            >
              <div className="drawer-heading">
                <h3>Portfolios</h3>
                <span onClick={toggleDrawer(anchor, false)}>
                  <CloseOutlinedIcon />
                </span>
              </div>
              {list1(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>

      {/* 
      <div onClick={dropdownOpen}>

        <Image src="/images/menu-white.svg" />
        {!user && (
          <div>
            <Link to="/signup">
              {" "}
              <b>Signup</b>
            </Link>
            <Link to="/login">
              <b>Login</b>
            </Link>
          </div>
        )}
      </div> */}

      <div>
      
        {["password"].map((anchor) => (
          <React.Fragment key={anchor}>
          
            <SwipeableDrawer
              anchor={"left"}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
              className="dark-theme full-w"
            >
              <div className="drawer-heading">
              {changePasswordSuccessMessage}
                <h3>Change Password</h3>
                <span onClick={toggleDrawer("password", false)}>
                  <CloseOutlinedIcon />
                </span>
              </div>
              <div className="inner-content">
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <div>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Old Password
                      </InputLabel>
                      <OutlinedInput
                      
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Old Password"
                        onChange={updateState}
                        name="oldPassword"
                        value={changePasswordInfo.oldPassword}
                      />
                    </FormControl>

                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        New Password
                      </InputLabel>
                      <OutlinedInput
                  
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="New Password"
                        onChange={updateState}
                        name="newPassword"
                        value={changePasswordInfo.newPassword}
                      />
                    </FormControl>

                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                      
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Confirm Password"
                        onChange={updateState}
                        name="confirmPassword"
                        value={changePasswordInfo.confirmPassword}
                      />
                    </FormControl>

                    <Button onClick={handleChangePassword} variant="contained" className="change-password">
                      Change password
                    </Button>
                  </div>
                </Box>
              </div>
            </SwipeableDrawer>
          </React.Fragment>
          
        ))}
      </div>

      <div>
      
        {["theme"].map((anchor) => (
          <React.Fragment key={anchor}>
          
            <SwipeableDrawer
              anchor={"left"}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
              className="dark-theme full-w"
            >
              <div className="drawer-heading">
              
                <h3 className="">Change Color Mode</h3>
                <span onClick={toggleDrawer("theme", false)}>
                  <CloseOutlinedIcon />
                </span>
              </div>
              <div className="inner-content">
               
                 <label className="drawer-heading">Dark Mode ({getDarkMode()})</label>
                 
               
             
                 <Switch {...switchlabel} color="success" 
                  checked={JSON.parse(localStorage.getItem("darkMode"))}
                  onChange={Toggleclass1}
                  inputProps={{ 'aria-label': 'controlled' }}
/>



             
                  
              </div>
            </SwipeableDrawer>
          </React.Fragment>
          
        ))}
      </div>



     {/** <div className="wallet-header">
        {["wallet"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button
              className="menu-icon"
              onClick={toggleDrawer("wallet", true)}
            >
              {" "}
              <AccountBalanceWalletIcon />
            </Button>
            <SwipeableDrawer
              anchor={"left"}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
              className="dark-theme full-w"
            >
              <div className="drawer-heading">
                <h3>Payments</h3>
                <span onClick={toggleDrawer("wallet", false)}>
                  <CloseOutlinedIcon />
                </span>
              </div>
              <div className="account-menu">
                <Box>
                  <Grid
                    container
                    className="account-li"
                    alignItems={"center"}
                    onClick={Toggleclass1}
                  >
                    <Grid item xs={2}>
                      <WalletIcon />
                    </Grid>
                    <Grid item xs={10}>
                      <b>Deposite</b>
                    </Grid>
                  </Grid>

                  <Grid container className="account-li" alignItems={"center"}>
                    <Grid item xs={2}>
                      <Image src="/images/withdraw.png" />
                    </Grid>
                    <Grid item xs={10}>
                      <b>Withdraw</b>
                    </Grid>
                  </Grid>

                  <Grid container className="account-li" alignItems={"center"}>
                    <Grid item xs={2}>
                      <ImportExportIcon />
                    </Grid>
                    <Grid item xs={10}>
                      <b>Transfer</b>
                    </Grid>
                  </Grid>
                  <Grid container className="account-li" alignItems={"center"}>
                    <Grid item xs={2}>
                      <HistoryIcon />
                    </Grid>
                    <Grid item xs={10}>
                      <b>Transaction</b>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div> 
      <div className="notification">
      <IconButton
              size="large"
              aria-label="show 5 new notifications"
              color="inherit"
              aria-describedby={idn} 
              onClick={handleClickn}
            >
              <Badge badgeContent={5} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Popover
        id={idn}
        open={openNotify}
        anchorEl={anchorEln}
        onClose={handleClosen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className="notify-box"
      >
        <Typography sx={{ p: 2 }}>
          <h3>Notifications</h3>
          <Box className="notification-wrap">
            No Notifications
          </Box>
        </Typography>
      </Popover>

      </div> */}
      <div className="search-icon-header">
      <IconButton onClick={handleSearchIconClick}
              size="large"
              aria-label="Search"
              color="inherit"
              aria-describedby={idn} 
             
            >
   <SearchIcon  />
            </IconButton>



      </div>
      <div className="account-settings-icon">
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button className="menu-icon" onClick={toggleDrawer(anchor, true)}>
              <SubjectIcon className="menu-bar" />
            </Button>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, true)}
              onOpen={toggleDrawer(anchor, false)}
              className="dark-theme"
            >
              <div className="drawer-heading menuicon">
                <h3>Account Settings</h3>
                <span onClick={toggleDrawer(anchor, false)}>
                  <CloseOutlinedIcon />
                </span>
              </div>
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>
    
    
     
   </> )

}

</>  )  


  }
    </div>
      
  );
};

export default Header;
