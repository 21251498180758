import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
    
const HeaderShowHide = ({hideHeader,setHideHeader}) => {



  // If show is true this will be returned
  return (
    <div className='flash-message show-header'>
    {hideHeader ? 
    <div  onClick={() => {
      setHideHeader(false);
  }}>   <KeyboardArrowDownOutlinedIcon /></div>
     : 
   <div  onClick={() => {
      setHideHeader(true);
}}>   <KeyboardArrowUpIcon /></div>
    }
    </div>   
  )
}

export default HeaderShowHide;