import { useState, useContext, useEffect } from 'react';
import AssetRow from './AssetRow';


import { UserContext } from '../contexts/UserContext';

import SearchIcon from '@mui/icons-material/Search';

import api from '../services/api';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import SortIcon from '@mui/icons-material/Sort';
import StockOptionsBreadcrumb from './StockOptionsBreadcrumb';
import HeaderShowHide from './HeaderShowHide';
import ExpiryMonthDisplay from './ExpiryMonthsDisplay';
import PaginationList from '../components/PaginationList';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


  
function AssetsListing(props) {

 
console.log("inside assres losting")
  const [selectedMenu, setSelectedMenu] = useState('changePercent24Hr');
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const open = Boolean(anchorEl);

 
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [nPages, setNPages] = useState(1)
  const [sortOrder, setSortOrder] = useState(-1);
  const [selectedOptionName, setSelectedOptionName] = useState('Percentage Change (High to Low)');

  let percentChangeDisplayText = '24h changes';

  //let subscribeSymbols = []

  /**
   useEffect(() => {
    setLoading(true)
    api
      .getAssetLatestRates(props.displayPage,props.selectedAssetName,props.selectedOptionType,props.selectedExpiryMonth)
      .then(({ data }) => {
        props.setStocksAssetArray(data);
        setLoading(false)
       //  subscribeSymbols = data.map(a => a.symbol);
       // let subscribeMessage = {"action":"subscribe", "instruments":subscribeSymbols}
       // props.sendWebsocketMessageToServer(subscribeMessage) 
    
      })
      .catch((error) => {
        console.log(error);
      });

     return () => {

        let unsubscribeMessage = {"action":"unsubscribe", "instruments":subscribeSymbols}
         props.sendWebsocketMessageToServer(unsubscribeMessage)
        
    
  
  }, [props.displayPage, props.refresh, props.selectedExpiryMonth]);



  const FIVE_SEC = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      api
      .getAssetLatestRates(props.displayPage,props.selectedAssetName,props.selectedOptionType,props.selectedExpiryMonth)
      .then(({ data }) => {
        props.setStocksAssetArray(data);
      })
      .catch((error) => {
        console.log(error);
      });
    }, FIVE_SEC);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [props.displayPage,props.refresh,props.selectedExpiryMonth])

  **/


  const handleSearchClear = (event) => {
    console.log("clearing search text")
    setSearchValue("")
    setCurrentPage(1)
    fetchData("",selectedMenu,sortOrder,1)
   };

 

   const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
     setAnchorEl(null);
   };

   const handleSort = (option) => {
    setSelectedMenu(option.option_property)
    setSortOrder(option.sort_order)
    setSelectedOptionName(option.option_name)
    setCurrentPage(1)
    fetchData(searchValue,option.option_property,option.sort_order,1)
    handleClose()
    
   };
 

   const options = [
    {option_name: "Price (Low to High)", option_property:"price" , sort_order:1},
    {option_name: "Price (High to Low)", option_property:"price" , sort_order:-1},
    {option_name: "Price Change(Low to High)", option_property:"priceChange" , sort_order:1},
    {option_name: "Price Change (High to Low)", option_property:"priceChange" , sort_order:-1},
    {option_name: "Percentage Change (Low to High)", option_property:"changePercent24Hr" , sort_order:1},
    {option_name: "Percentage Change (High to Low)", option_property:"changePercent24Hr" , sort_order:-1},

    
  ];


   const fetchData = async (search_text,sort_property,sort_order,pageNumber) => {

    
    setLoading(true)
    setError(null);
    /**let pageNumber = 0
      if(initialLoad){
      pageNumber  = 0
      }else {
        pageNumber = currentPage
      }**/




    try {

    
      let account_id = JSON.parse(localStorage.getItem('account_id'));
     
      let inputData = {
        account_id: account_id,

        pageNum:pageNumber,
        sort_property : sort_property,
        sort_order:sort_order,
      
        displayPage:props.displayPage,
       
     
      };

      if(props.displayPage =="StocksOptionsDetails"){
        inputData.selectedAssetName=props.selectedAssetName
        inputData.selectedOptionType=props.selectedOptionType
      }

      if(props.displayPage =="StocksOptionsDetails" || props.displayPage == "StocksFutures" ){
        inputData.selectedExpiryMonth=props.selectedExpiryMonth
       
      }

      if(search_text.length >= 3){
        inputData.search_value=search_text   
      }


      api
        .getAssetLatestRates(inputData)
        .then(({ data }) => {
          
      
         
            //setCurrentPage(prevPage => prevPage + 1);
            //setClosedPositions(prevItems => [...prevItems, ...data.trades.closedTrades]);


           // subscribeSymbols = props.stocksAssetArray.map(object => object.symbol);
            

            //let unsubscribeMessage = {"action":"unsubscribe", "instruments":subscribeSymbols}
            //props.sendWebsocketMessageToServer(unsubscribeMessage)
           
            props.setStocksAssetArray(data);
            if(search_text && search_text.length >= 3){
             setNPages(1)
            } else {
              if(props.displayPage == "StocksFutures" || props.displayPage == "StocksOptions"){
                setNPages(4)
              }else if(props.displayPage == "StocksOptionsDetails"){
                setNPages(1)
              }else {
                setNPages(50)
              }
              
            }
           
            setLoading(false)

          

           // subscribeSymbols = data.map(object => object.symbol);
            props.setSubscribeSymbols(data.map(object => object.symbol))
            
            //let subscribeMessage = {"action":"subscribe", "instruments":subscribeSymbols}
          //  props.sendWebsocketMessageToServer(subscribeMessage)

           // if(data.searchResults){
            //   setCurrentPage(1)
          //  }
           
           // props.setHoldingsCount(data.holdingsCount)
           // props.setPositionsCount(data.positionsCount)
            
          
        })


  } catch (error) {
    console.log(error)
    setError(error);

  } 
  
  };






 useEffect(() => {
    setCurrentPage(1)
    fetchData(searchValue,selectedMenu,sortOrder,currentPage);
 /*   return () => {
      let unsubscribeMessage = {"action":"unsubscribe", "instruments":subscribeSymbols}
      props.sendWebsocketMessageToServer(unsubscribeMessage)
    
  }*/

  }, [props.refresh,props.displayPage, props.selectedExpiryMonth]);



 




  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(searchValue.length >= 3){
        setCurrentPage(1)
        fetchData(searchValue.toUpperCase(),selectedMenu,sortOrder,1);
          
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchValue])
  



  function getAssetLatestRatesOld() {

    api
      .getAssetLatestRates(props.displayPage,props.selectedAssetName,props.selectedOptionType,props.selectedExpiryMonth)
      .then(({ data }) => {
        props.setStocksAssetArray(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }




  return (
  <>
  {loading ?    <div className="loader-image"><img src='/images/loader.gif'/></div>  :  

  <div className='' id='tradeView'>
   
   <div> <StockOptionsBreadcrumb setDisplayPage={props.setDisplayPage} displayPage={props.displayPage}/></div> 
        {( props.displayPage == "StocksOptionsDetails" ? <div className='options-details-back-button' onClick={() => props.setDisplayPage("StocksOptions")}><ArrowBackIcon/> Back</div> :
          <>
               <div className='d-flex align-items-baseline justify-content-between search-main'>
            <div className='search-box' >
              <input
                type='text'
                className='search-bar'
                name='search'
                placeholder='Search'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span>
                <SearchIcon />
              </span>

              {searchValue  && <div  onClick={(event) => {

handleSearchClear(event)

}}  >  <CloseIcon/> </div>}
            </div>
           <div className='ms-1'>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <SortIcon sx={{ fill: 'white' }} />
              </IconButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disabled>Sort By</MenuItem>
                {options.map((option) => (
                  <MenuItem
                    key={option.option_name}
                    onClick={() => {

                      handleSort(option)
                     
                    }}
                  >
                    <div className='d-flex justify-content-between w-100'>
                      <span>{option.option_name}</span>
                      <span>
                        {selectedOptionName == option.option_name ? (
                          <CheckCircleTwoToneIcon
                            sx={{ fill: 'green', marginLeft: '10px' }}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
              
            </div> 
            <HeaderShowHide hideHeader={props.hideHeader} setHideHeader={props.setHideHeader}/>
          </div>
          
           
          </>
        )}

        {/**(
          <div className='d-flex justify-content-between d-block-list assest-padding'>
            <p className='name-header'>Name</p>
            <p className='name-header'>Price, {percentChangeDisplayText}</p>
          </div>
        )*/}

      {(props.displayPage === "StocksFutures" || props.displayPage === "StocksOptionsDetails" )&& 
      
      <ExpiryMonthDisplay selectedExpiryMonth={props.selectedExpiryMonth} setSelectedExpiryMonth={props.setSelectedExpiryMonth} siteObject={props.siteObject} />
      
      }



        {
          props.stocksAssetArray
            .map((assetRow, index) => (
              <AssetRow
               
                assetRow={assetRow}
                index={index}
                key={assetRow.symbol}
                displayPage={props.displayPage}
                setDisplayPage={props.setDisplayPage}
                handleDetailsPageOpen={props.handleDetailsPageOpen}
                handleSelectionChange={props.handleSelectionChange}
                setSelectedAsset={props.setSelectedAsset}
              />
            ))}
      
      <PaginationList  nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} fetchData={fetchData} selectedMenu={selectedMenu} sortOrder={sortOrder} searchValue={searchValue}/>
      </div>
      }
    </>
  );
}

export default AssetsListing;
