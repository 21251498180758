import { useState, useEffect, useContext, useRef } from "react";


import ClosedPositions from "./ClosedPositions";
import api from "../services/api";

import { UserContext } from "../contexts/UserContext";
import GlobalLayout from "../components/GlobalLayout";

import AssetsListing from "../components/AssetsListing";


import Holdings from "./Holdings";
import PendingOrders from "./PendingOrders";
import ExecutedOrders from "./ExecutedOrders";
import Tournament from "./Tournament/Tournament";
import PlaceOrderForm from "./PlaceOrderForm";

import AssetsDisplayWatchList from "../components/AssetsDisplayWatchList";
import SearchResults from "../components/SearchResults";
import Positions from "./Positions";
import Dashboard from "./Dashboard";
import moment from 'moment-timezone';
import WebsocketExpiredAlert from "../components/WebsocketExpiredAlert";
import useWindowSize from '../components/useWindowSize';

function Trade() {

 
  const [refresh, doRefresh] = useState(0);

  const [previousPage, setPreviousPage] = useState("WatchList")
  const [displayPage, setDisplayPage] = useState(localStorage.getItem('displayPage') ? localStorage.getItem('displayPage') : "Dashboard");
 
  const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL
 

  const [holdingsCount,setHoldingsCount]= useState(0)
  const [positionsCount,setPositionsCount]= useState(0)

  const [pendingOrders, setPendingOrders] = useState([]);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  
  const [watchlistarray, setWatchListArray] = useState([]);
  const [stocksAssetArray, setStocksAssetArray] = useState([]);
  const [searchResultsArray, setSearchResultsArray] = useState([]);
  const [parentSearchValue, setParentSearchValue] = useState('');
  const [holdings, setHoldings] = useState([]);
  const [holdingsAssetArray, setHoldingsAssetArray] = useState([]);
  const [positions, setPositions] = useState([]);
  const [positionsAssetArray, setPositionsAssetArray] = useState([]);
  const [pendingOrdersAssetArray, setPendingOrdersAssetArray] = useState([]);

  const [selectedAsset, setSelectedAsset] = useState({});
  const [selectedQuantityForTransaction, setSelectedQuantityForTransaction] = useState(null)
  const [transactionType,setTransactionType]= useState()
  const [orderTimeType,setOrderTimeType]= useState("CNC")
  const [orderPriceType,setOrderPriceType]=useState("market")

  //const [selectedAssetOption, setSelectedAssetOption] = useState({asset_name:"",option_type:"",expiry_month:11});
  const [selectedAssetName, setSelectedAssetName] = useState("");
  const [selectedOptionType,setSelectedOptionType]= useState("")
  const [selectedExpiryMonth,setSelectedExpiryMonth]= useState(null)
  const [flashMessage, setFlashMessage] = useState({});
  const [hideHeader, setHideHeader] = useState(true);
  const [siteObject, setSiteObject] = useState({});
  const [lastMessageSendTime, setLastMessageSendTime] = useState()
  const [reinitiateWebsocket,setReinitiateWebsocket]= useState(0)
  const [websocketExpired,setWebsocketExpired]=useState(false)

  const [subscribeSymbols, setSubscribeSymbols] = useState([])

 
  const pricesWs = useRef();
  const displayPageRef = useRef();


  const windowSize = useWindowSize(); 


  const delay = ms => new Promise(res => setTimeout(res, ms));


  useEffect(() => {

    displayPageRef.current=displayPage
    if(displayPage === "Dashboard" || displayPage === "PlaceOrderForm"){
      setHideHeader(false)
    } else {
      setHideHeader(true)
    }
    
   
  
  }, [displayPage]);

  useEffect(() => {

 // initializeCurrentPriceArray();
  
 

}, [displayPage,refresh]);


useEffect(() => {
  api
  .loadSiteConfigurationFrontend()
  .then(({ data }) => {
    setSiteObject(data)
    localStorage.setItem('siteObject', JSON.stringify(data));
    if(data.toshow_expiry_date[0]){
      setSelectedExpiryMonth(Number(new Date(data.toshow_expiry_date[0]).getMonth()))
      localStorage.setItem('selectedExpiryMonth', Number(new Date(data.toshow_expiry_date[0]).getMonth()));
    }
    
  
  })
  .catch((error) => {
    console.log(error);
  });
 
 }, []);

 useEffect(() => {
  localStorage.setItem('displayPage', displayPage);
 
 }, [displayPage]);


useEffect(() => {

  console.log("33333")
 console.log(pricesWs.current)
 pricesWs.current = null 
 console.log(pricesWs.current)

 pricesWs.current = new WebSocket(websocketUrl);
  //pricesWs.current = new WebSocket(`ws://localhost:8000/ftxwss`);
  
 // pricesWs.current = new WebSocket(`wss://api.xcelfintech.in/ftxwss`);
 
  //startUpdatingCurrentPriceUsingWebsocket()

  pricesWs.current.onmessage = async (msg) => {



    try{
      msg = JSON.parse(msg.data);
  
  
        if(msg.symbol){
          updateAssetPrice(msg.symbol, msg.price);
        }
        if(msg.action && msg.action === "update"){
         doRefresh(prev => prev + 1)
         setFlashMessage({"message":msg.message, "color":msg.color, "belowLine":msg.messageBelowLine})
        }
       

    }catch (error) {
      console.log(error)
    }
  
  };

  
// Register event listeners for the open, close, and message events
pricesWs.current.onopen = async () => {
console.log("WebSocket ready!");
setWebsocketExpired(false)


};

pricesWs.current.onclose = async () => {
console.log("WebSocket closed!");
};

pricesWs.current.onerror = async () => {
};

    return () => {
      console.log("closing websocket")
      
      pricesWs.current.close()
    }

}, [reinitiateWebsocket]);

useEffect(() => {
  const interval_id = setInterval(() => {
    const currentTime = moment()
    const secondsToExpire =  60*5
    const expirationtime = moment(lastMessageSendTime).add(secondsToExpire, 'seconds')
    console.log(currentTime)
    console.log(lastMessageSendTime)
    console.log(expirationtime)

    if(currentTime.isAfter(expirationtime)){
      console.log("closing websocket connection after inactivity of seconds "+secondsToExpire)
      pricesWs.current.close()
      clearInterval(interval_id);
      localStorage.setItem('displayPage', displayPage);
      setWebsocketExpired(true)
      console.log(pricesWs.current)
    }
  }, 60000);
  return () => clearInterval(interval_id);
}, [lastMessageSendTime]);


  // updated the this.state.currentPrice, user details whenever we get a new current price
 //function startUpdatingCurrentPriceUsingWebsocket() {
  useEffect(() => {
    console.log("sending message")
    if(subscribeSymbols.length > 0 ){
      sendSubscribeMessage(subscribeSymbols);
    }
    
    return () => sendUnSubscribeMessage(subscribeSymbols);
  }, [subscribeSymbols]); 
   

 // }


 useEffect(() => {
  
if(windowSize.width < 768) {
  // Change zoom level on mount
  document.body.style.zoom = "115%";
  console.log("zoom level set to"+document.body.style.zoom)
} else {
  document.body.style.zoom = "100%";
  console.log("zoom level set to"+document.body.style.zoom)
}


}, [windowSize.width]);

async function sendWebsocketMessageToServer(message){
  if (pricesWs.current.readyState === WebSocket.OPEN) {
       pricesWs.current.send(JSON.stringify(message));
       setLastMessageSendTime(moment())
 } else {
    setReinitiateWebsocket(prev => prev + 1)
   // await delay(2000);
// add timeout delay    
    if (pricesWs.current.readyState === WebSocket.OPEN) {
      pricesWs.current.send(JSON.stringify(message));
      setLastMessageSendTime(moment())
    }
 }
 console.log(pricesWs.current)
  
    }


    async function sendSubscribeMessage(newSymbols){

      //sendUnSubscribeMessage(subscribeSymbols)

    let subscribeMessage = {"action":"subscribe", "instruments":newSymbols}
      sendWebsocketMessageToServer(subscribeMessage)
      
    }


    async function sendUnSubscribeMessage(oldSymbols) {
      let unsubscribeMessage = {"action":"unsubscribe", "instruments":oldSymbols} 
      if(oldSymbols.length > 0 ){
        sendWebsocketMessageToServer(unsubscribeMessage)
      }
     
      
    }




    function updateAssetPrice (symbol, price)  {
     
  

      if(displayPageRef.current === "WatchList"){
        setWatchListArray((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.symbol === symbol) {
              return { ...obj, price: price };
            }
    
            //  otherwise return object as is
            return obj;
          });
    
   
          return newState;
        });
      }

      else if (displayPageRef.current==="Stocks"|| displayPageRef.current === "StocksFutures" || displayPageRef.current === "StocksOptions" || displayPageRef.current === "StocksOptionsDetails"){
        setStocksAssetArray((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.symbol === symbol) {
              return { ...obj, price: price };
            }
    
            //  otherwise return object as is
            return obj;
          });
    
   
          return newState;
        });
      } 
      
      else if (displayPageRef.current === "PlaceOrderForm"){
      
        const selectedAssetForPlaceOrder = (localStorage.getItem('selectedAsset') != null) ? JSON.parse(localStorage.getItem('selectedAsset')) : ""
        if (selectedAssetForPlaceOrder.symbol === symbol) {
          setSelectedAsset((prevState) => ({ ...prevState, price : price }));
       }
       
      }
      else if (displayPageRef.current === "Holdings"){

      /** setHoldingsAssetArray((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.symbol === symbol) {
              return { ...obj, price: price };
            }
    
         
            return obj;
          });
    
          return newState;
        }); */ 

        setHoldings((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.symbol === symbol) {
              return { ...obj, ltp: price };
            }
    
         
            return obj;
          });
    
          return newState;
        });
      }
      else if (displayPageRef.current === "Positions"){

      /**  setPositionsAssetArray((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.symbol === symbol) {
              return { ...obj, price: price };
            }
    
         
            return obj;
          });
    
          return newState;
        });**/
        setPositions((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.symbol === symbol) {
              return { ...obj, ltp: price };
            }
    
         
            return obj;
          });
    
          return newState;
        });
      }
      else if (displayPageRef.current === "Dashboard"){

        setPositions((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.symbol === symbol) {
              return { ...obj, ltp: price };
            }
    
         
            return obj;
          });
    
          return newState;
        });


        setHoldings((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.symbol === symbol) {
              return { ...obj, ltp: price };
            }
    
         
            return obj;
          });
    
          return newState;
        });

      }
      else if (displayPageRef.current === "PendingOrders"){


        setPendingOrders((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.symbol === symbol) {
              return { ...obj, ltp: price };
            }
    
         
            return obj;
          });
    
          return newState;
        });

      }

    };

    function initializeCurrentPriceArray() {
      
      if(displayPage==="WatchList"){
        api
        .getLatestRatesForWatchList("WatchList")
        .then(({ data }) => {
          setWatchListArray(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } 
    
   {/**  else if (displayPage==="Stocks"|| displayPage === "StocksFutures" || displayPage === "StocksOptions" || displayPage === "StocksOptionsDetails"){
      if(displayPage !== "StocksOptionsDetails"){
        setSelectedAssetOption({asset_name:"",option_type:""})
      }
      api
      .getAssetLatestRates(displayPage,selectedAssetOption)
      .then(({ data }) => {
        setStocksAssetArray(data);
      })
      .catch((error) => {
        console.log(error);
      });
    } */}


      }

    
   

    var currentAssetObject
    const handleSelectionChange = (assetSymbol, currentTransactionType) => {
     
      if(displayPage === "WatchList"){
         currentAssetObject = watchlistarray.find((obj) => {
          return obj.symbol === assetSymbol;
        });
      }else if(displayPage === "Stocks" || displayPage === "StocksFutures" || displayPage === "StocksOptions" || displayPage === "StocksOptionsDetails"){
         currentAssetObject = stocksAssetArray.find((obj) => {
          return obj.symbol === assetSymbol;
        });
      }
      else if(displayPage === "SearchResults"){
         currentAssetObject = searchResultsArray.find((obj) => {
          return obj.symbol === assetSymbol;
        });
      }
    

      
       setSelectedAsset(currentAssetObject);
   
    //  localStorage.setItem('selectedAsset', currentAssetObject.symbol);
     // localStorage.setItem("selectedAssetObject", JSON.stringify(currentAssetObject));
      //localStorage.setItem('selectedQuantityForTransaction', 1);
      //setSelectedQuantityForTransaction(1)
      setTransactionType(currentTransactionType)
      //localStorage.setItem('currentTransactionType', currentTransactionType);
      setPreviousPage(displayPage)
      setDisplayPage('PlaceOrderForm');
    };
  

    function handleDetailsPageOpen (asset_name,option_type) {
      if(displayPage === "StocksOptions"){
        setSelectedAssetName(asset_name)
        setSelectedOptionType(option_type)
       // setSelectedExpiryMonth(expiry_month)
         setDisplayPage("StocksOptionsDetails")
      }
    }
    const addInstrumentToWatchlist = (instrumentObject) => {
      if (!(watchlistarray.filter(e => e.symbol === instrumentObject.symbol).length > 0)) {
       
      const payload = {account_id :JSON.parse(localStorage.getItem('account_id')), symbol:instrumentObject.symbol}
     
      api
      .addInstrumentToWatchlist(payload)
      .then(({ data }) => {
        if(data.success){
          setWatchListArray(watchlistarray => [...watchlistarray,instrumentObject] );
         // let subscribeMessage = {"action":"subscribe", "instruments":[instrumentObject.symbol]}
         // sendWebsocketMessageToServer(JSON.stringify(subscribeMessage))
          setPreviousPage(displayPage)
          setDisplayPage("WatchList")
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
  
       } else {
        setDisplayPage("WatchList")
       }
    };
  
    const removeInstrumentFromWatchlist = (instrumentObject) => {
     
       
    
     

      const payload = { account_id :JSON.parse(localStorage.getItem('account_id')), symbol:instrumentObject.symbol}
    
    
      api
      .removeInstrumentFromWatchlist(payload)
      .then(({ data }) => {

        if(data.success){
          setWatchListArray(watchlistarray => watchlistarray.filter((data)=> data.symbol != instrumentObject.symbol ))
          let unsubscribeMessage = {"action":"unsubscribe", "instruments":[instrumentObject.symbol]} 
          sendWebsocketMessageToServer(JSON.stringify(unsubscribeMessage))
        }
       
      })
      .catch((error) => {
        console.log(error);
      });
  
    };
    

  return (
    <GlobalLayout
   
      displayPage={displayPage}
      setDisplayPage={setDisplayPage}
    
    
      refresh={refresh} 
      doRefresh={doRefresh}
      flashMessage={flashMessage}
      setFlashMessage={setFlashMessage}
      hideHeader={hideHeader}
      setHideHeader={setHideHeader}
    >

   
      
      <div className="container main-window trade-window">

      <div>{displayPage === "Dashboard"  && <Dashboard refresh={refresh} doRefresh={doRefresh}  
    displayPage={displayPage}
    setDisplayPage={setDisplayPage}
   
    selectedAsset={selectedAsset}
    setSelectedAsset={setSelectedAsset}
    holdingsAssetArray={holdingsAssetArray}
    positionsAssetArray={positionsAssetArray}
    setHoldingsAssetArray={setHoldingsAssetArray}
    setPositionsAssetArray={setPositionsAssetArray}
    positions={positions}
    setPositions={setPositions}
    holdings={holdings}
    setHoldings={setHoldings}
     sendWebsocketMessageToServer={sendWebsocketMessageToServer}
     subscribeSymbols={subscribeSymbols}
     setSubscribeSymbols={setSubscribeSymbols}

    
    />}</div>


          <div>
        { (displayPage === "Stocks" || displayPage === "StocksFutures" || displayPage === "StocksOptions" || displayPage === "StocksOptionsDetails")
    
    && (
            <AssetsListing
            refresh={refresh} 
            doRefresh={doRefresh}
            displayPage={displayPage}
            setDisplayPage={setDisplayPage}
            stocksAssetArray={stocksAssetArray}
            setStocksAssetArray={setStocksAssetArray}
            setSearchResultsArray={setSearchResultsArray}
            setParentSearchValue={setParentSearchValue}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            selectedAssetName={selectedAssetName}
            selectedOptionType={selectedOptionType}
            selectedExpiryMonth={selectedExpiryMonth}
            setSelectedExpiryMonth={setSelectedExpiryMonth}
            handleDetailsPageOpen={handleDetailsPageOpen}
            handleSelectionChange={handleSelectionChange}
            removeInstrumentFromWatchlist={removeInstrumentFromWatchlist}
            sendWebsocketMessageToServer={sendWebsocketMessageToServer}
            subscribeSymbols={subscribeSymbols}
            setSubscribeSymbols={setSubscribeSymbols}
            hideHeader={hideHeader}
            setHideHeader={setHideHeader}
            siteObject={siteObject}
        
            />
            )
    }  
    </div>
    <div>
    {displayPage === "WatchList"  && (
        <AssetsDisplayWatchList 
            refresh={refresh} 
            doRefresh={doRefresh}
            displayPage={displayPage}
            setDisplayPage={setDisplayPage}
            previousPage={previousPage}
            setPreviousPage={setPreviousPage}
            watchlistarray={watchlistarray}
            setWatchListArray={setWatchListArray}
            setSearchResultsArray={setSearchResultsArray}
            setParentSearchValue={setParentSearchValue}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            handleDetailsPageOpen={handleDetailsPageOpen}
            handleSelectionChange={handleSelectionChange}
            removeInstrumentFromWatchlist={removeInstrumentFromWatchlist}
            sendWebsocketMessageToServer={sendWebsocketMessageToServer}
            subscribeSymbols={subscribeSymbols}
            setSubscribeSymbols={setSubscribeSymbols}
            hideHeader={hideHeader}
            setHideHeader={setHideHeader}
            
        />
    )}
    </div>
    <div>
    {displayPage === "SearchResults"  && (
        <SearchResults 
            refresh={refresh} 
            doRefresh={doRefresh}
            displayPage={displayPage}
            setDisplayPage={setDisplayPage}
            previousPage={previousPage}
            watchlistarray={watchlistarray}
            searchResultsArray={searchResultsArray}
            setSearchResultsArray={setSearchResultsArray}
            parentSearchValue={parentSearchValue}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            handleSelectionChange={handleSelectionChange}
            addInstrumentToWatchlist={addInstrumentToWatchlist}
            sendWebsocketMessageToServer={sendWebsocketMessageToServer}
            hideHeader={hideHeader}
            setHideHeader={setHideHeader}
            subscribeSymbols={subscribeSymbols}
            setSubscribeSymbols={setSubscribeSymbols}
            
        />
    )}
    </div>


    <div>{displayPage === "Holdings"  && <Holdings 
      refresh={refresh} 
      doRefresh={doRefresh}
    displayPage={displayPage}
    setDisplayPage={setDisplayPage}
    setPreviousPage={setPreviousPage}
    selectedAsset={selectedAsset}
    setSelectedAsset={setSelectedAsset}
    setSelectedAssetName={setSelectedAssetName}
    selectedQuantityForTransaction={selectedQuantityForTransaction}
    setSelectedQuantityForTransaction={setSelectedQuantityForTransaction}
    orderTimeType={orderTimeType}
    setOrderTimeType={setOrderTimeType}
    transactionType={transactionType}
    setTransactionType={setTransactionType}
    holdingsAssetArray={holdingsAssetArray}
    setHoldingsAssetArray={setHoldingsAssetArray}
    holdings={holdings}
    setHoldings={setHoldings}
     sendWebsocketMessageToServer={sendWebsocketMessageToServer}
     subscribeSymbols={subscribeSymbols}
     setSubscribeSymbols={setSubscribeSymbols}
     hideHeader={hideHeader}
     setHideHeader={setHideHeader}
     holdingsCount={holdingsCount}
     setHoldingsCount={setHoldingsCount}
     positionsCount={positionsCount}
     setPositionsCount={setPositionsCount}
    
    />}</div>

<div>{displayPage === "Positions"  && <Positions 
  refresh={refresh} doRefresh={doRefresh}  
    displayPage={displayPage}
    setDisplayPage={setDisplayPage}
    setPreviousPage={setPreviousPage}
    selectedAsset={selectedAsset}
    setSelectedAsset={setSelectedAsset}
    setSelectedAssetName={setSelectedAssetName}
    selectedQuantityForTransaction={selectedQuantityForTransaction}
    setSelectedQuantityForTransaction={setSelectedQuantityForTransaction}
    orderTimeType={orderTimeType}
    setOrderTimeType={setOrderTimeType}
    transactionType={transactionType}
    setTransactionType={setTransactionType}
    positionsAssetArray={positionsAssetArray}
    setPositionsAssetArray={setPositionsAssetArray}
    positions={positions}
    setPositions={setPositions}
     sendWebsocketMessageToServer={sendWebsocketMessageToServer}
     subscribeSymbols={subscribeSymbols}
            setSubscribeSymbols={setSubscribeSymbols}
     hideHeader={hideHeader}
            setHideHeader={setHideHeader}
            holdingsCount={holdingsCount}
            setHoldingsCount={setHoldingsCount}
            positionsCount={positionsCount}
            setPositionsCount={setPositionsCount}

    
    />}</div>

    

    <div>{displayPage === "PendingOrders"  && <PendingOrders 
    refresh={refresh} doRefresh={doRefresh}  
    displayPage={displayPage}
    setDisplayPage={setDisplayPage}
    pendingOrders={pendingOrders}
    setPendingOrders={setPendingOrders}
    sendWebsocketMessageToServer={sendWebsocketMessageToServer}
    subscribeSymbols={subscribeSymbols}
            setSubscribeSymbols={setSubscribeSymbols}
    hideHeader={hideHeader}
    setHideHeader={setHideHeader}
    pendingOrdersCount={pendingOrdersCount}
    setPendingOrdersCount={setPendingOrdersCount}
    
    
    />
    }</div>


  


    <div>{displayPage === "PlaceOrderForm" && <PlaceOrderForm 
      refresh={refresh} 
      doRefresh={doRefresh}
    displayPage={displayPage}
    setDisplayPage={setDisplayPage}
    previousPage={previousPage}
    selectedAsset={selectedAsset}
    setSelectedAsset={setSelectedAsset}
    selectedAssetName={selectedAssetName}
    selectedQuantityForTransaction={selectedQuantityForTransaction}
    setSelectedQuantityForTransaction={setSelectedQuantityForTransaction}
    orderTimeType={orderTimeType}
    setOrderTimeType={setOrderTimeType}
    transactionType={transactionType}
    setTransactionType={setTransactionType}
    orderPriceType={orderPriceType}
    setOrderPriceType={setOrderPriceType}
    flashMessage={flashMessage}
    setFlashMessage={setFlashMessage}
    sendWebsocketMessageToServer={sendWebsocketMessageToServer}
    subscribeSymbols={subscribeSymbols}
            setSubscribeSymbols={setSubscribeSymbols}
  
    
    />
    
    
    }</div>
        


<div>{displayPage === "ClosedPositions" && <ClosedPositions refresh={refresh} doRefresh={doRefresh}
displayPage={displayPage}
setDisplayPage={setDisplayPage}
hideHeader={hideHeader}
            setHideHeader={setHideHeader}
            holdingsCount={holdingsCount}
            setHoldingsCount={setHoldingsCount}
            positionsCount={positionsCount}
            setPositionsCount={setPositionsCount}
            subscribeSymbols={subscribeSymbols}
            setSubscribeSymbols={setSubscribeSymbols}
/>}</div>


<div>{displayPage === "ExecutedOrders" && <ExecutedOrders
  refresh={refresh} 
  doRefresh={doRefresh}
    displayPage={displayPage}
    setDisplayPage={setDisplayPage}
    hideHeader={hideHeader}
            setHideHeader={setHideHeader}
            pendingOrdersCount={pendingOrdersCount}
            setPendingOrdersCount={setPendingOrdersCount}
            subscribeSymbols={subscribeSymbols}
            setSubscribeSymbols={setSubscribeSymbols}
    />}</div>

<div>{displayPage === "Tournament" && <Tournament refresh={refresh} doRefresh={doRefresh}  
    displayPage={displayPage}
    setDisplayPage={setDisplayPage}/>}</div>

<div>{websocketExpired && <WebsocketExpiredAlert websocketExpired={websocketExpired} setWebsocketExpired={setWebsocketExpired} />}</div>
       
    
      </div>
    </GlobalLayout>
  );
}

export default Trade;
