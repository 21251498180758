import { createContext, useState, useEffect } from "react";

import api from "../services/api";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({ token:localStorage.getItem("token"), customerid:localStorage.getItem("customerId"), email:localStorage.getItem("email"), updatedPoints:localStorage.getItem("updatedPoints"), openOrders:localStorage.getItem("openOrders"), selectedAsset:localStorage.getItem("selectedAsset"), currentTransactionType:localStorage.getItem("currentTransactionType"), selectedQuantityForTransaction:localStorage.getItem("selectedQuantityForTransaction")});

  return (
    <UserContext.Provider value={{user, setUser}}>
      { children }
    </UserContext.Provider>
  );
}