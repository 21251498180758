import { useState, useContext, useEffect } from 'react';
import AssetRow from './AssetRow';


import { UserContext } from '../contexts/UserContext';

import SearchIcon from '@mui/icons-material/Search';

import CloseIcon from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import SortIcon from '@mui/icons-material/Sort';
import api from '../services/api';
import SearchResultsRow from './SearchResultsRow';

  

  
function SearchResults(
 
    props) {

 

  const [selectedMenu, setSelectedMenu] = useState('All Holdings');
  const [anchorEl, setAnchorEl] = useState(null);
  const percentChangeDisplayText = '24h changes';


  const options = [
    'All Holdings',
    'Amount Invested: Low to High',
    'Amount Invested: High to Low',
    'Performance: Low to High',
    'Performance: High to Low',
  ];
  const open = Boolean(anchorEl);

  const [searchValue, setSearchValue] = useState(props.parentSearchValue);

 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };



  const handleClose = () => {
    setAnchorEl(null);
  };


 
  const handleSearchClear = (event) => {
    props.setDisplayPage("WatchList")
  };
  



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      //console.log(searchValue)
      // Send Axios request here
     // searchInstruments(token,searchValue)

     if(props.parentSearchValue !== searchValue && searchValue.length >= 3){
      api
      .searchInstruments(searchValue.toUpperCase())
      .then(({ data }) => {
        props.setSearchResultsArray(data);
        props.setSubscribeSymbols([])
      })
      .catch((error) => {
        console.log(error);
      });
      }

    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchValue])

 


  return (
    <>
      <div className='' id='tradeView'>

        {(
          <>
             <div className='d-flex align-items-baseline justify-content-between search-main'>
            <div className='search-box'>
              <input
                autoFocus
                type='text'
                className='search-bar'
                name='search'
                placeholder='Search eg: tcs, tcs fut, tcs '
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span>
                <SearchIcon />
              </span>
              <span className='name-header' onClick={handleSearchClear} >
             <CloseIcon/>
            </span>
            </div>
       
            
            <div className='ms-1'>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <SortIcon sx={{ fill: 'white' }} />
              </IconButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disabled>Sort By</MenuItem>
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    onClick={() => {
                      handleClose, setSelectedMenu(option);
                    }}
                  >
                    <div className='d-flex justify-content-between w-100'>
                      <span>{option}</span>
                      <span>
                        {selectedMenu === option ? (
                          <CheckCircleTwoToneIcon
                            sx={{ fill: 'green', marginLeft: '10px' }}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </div>
            </div>
          </>
        )}

        {/* (
          <div className='d-flex justify-content-between d-block-list assest-padding'>
            <p className='name-header'>Name</p>
            <p className='name-header'>Price, {percentChangeDisplayText}</p>
          </div>
        )*/}

 
      {props.searchResultsArray && 
          props.searchResultsArray
            .map((assetRow, index) => (
              <SearchResultsRow
                assetRow={assetRow}
                index={index}
                key={assetRow.symbol}
                displayPage={props.displayPage}
                setDisplayPage={props.setDisplayPage}
                handleSelectionChange={props.handleSelectionChange}
                setSelectedAsset={props.setSelectedAsset}
                addInstrumentToWatchlist={props.addInstrumentToWatchlist}
                searchResultsArray={props.searchResultsArray} 
                watchlistarray={props.watchlistarray}

              />
            ))}
      </div>
    </>
  );
}

export default SearchResults;
