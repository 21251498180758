

    
const LengthDisplayCircle = (props) => {




  // If show is true this will be returned
  return (
    <div >
   {props.label}  {'('}{props.count}{')'}
    </div>
  )
}


export default LengthDisplayCircle;