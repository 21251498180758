import React from 'react';
import { useState, useContext, useEffect } from 'react';
import api from '../services/api';


import { Form, FormCheck, Image, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


import { Chip, selectClasses } from '@mui/material';
import Stack from '@mui/material/Stack';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import constants from '../constants/constants';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Divider from '@mui/material/Divider';



  const PlaceOrderForm = (props) => {

  const [inputs, setInputs] = useState({});
  const [open, setOpen] = useState(false);
  
  let asset_symbol_to_fetch = (props.selectedAsset && props.selectedAsset.symbol) ? props.selectedAsset.symbol : props.selectedAssetName
  if(!asset_symbol_to_fetch && localStorage.getItem("selectedAsset")){
    asset_symbol_to_fetch = JSON.parse(localStorage.getItem("selectedAsset")).symbol
  }
  //let subscribeSymbols = []

  

  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    api
    .fetchLatestPriceForSelectedAsset(asset_symbol_to_fetch)
    .then(({ data }) => { 
      if(data.error){
        //setErrorMessage(data.error);
        props.setFlashMessage({"message":data.error_message, "color":"red"})
        props.setDisplayPage(props.previousPage)
        //setLoadingApiData(false)
      }

      if(data.price){
        console.log(data)
       
        props.setSelectedAsset(data)
        localStorage.setItem("selectedAsset", JSON.stringify(data));
        
        
        if(!props.orderTimeType){
          props.setOrderTimeType(localStorage.getItem("orderTimeType"))
         }
 
         if(!props.orderPriceType){
           props.setOrderPriceType(localStorage.getItem("orderPriceType"))
         }

         if(!props.transactionType){
          props.setTransactionType(localStorage.getItem("transactionType"))
        }

        inputs.order_time_type=props.orderTimeType
        inputs.limit_price=data.price
        inputs.orderPriceType=props.orderPriceType


        localStorage.setItem("order_time_type", props.orderTimeType);
        localStorage.setItem("orderPriceType", props.orderPriceType);
        localStorage.setItem("transactionType", props.transactionType);
     
       

        if(props.previousPage == "Holdings" || props.previousPage == "Positions"){
          if(data.lot_size > 1){
           inputs.quantity = Number(props.selectedQuantityForTransaction/data.lot_size)  
           props.setSelectedQuantityForTransaction(inputs.quantity)     
          }else {
            inputs.quantity = props.selectedQuantityForTransaction
          }
          
        } else {
          
             inputs.quantity = 1
             props.setSelectedQuantityForTransaction(inputs.quantity)     
           

        }
        setLoadingApiData(false)
       // subscribeSymbols = [data.symbol]
        props.setSubscribeSymbols([data.symbol])
       
       // let subscribeMessage = {"action":"subscribe", "instruments":subscribeSymbols}
       // props.sendWebsocketMessageToServer(subscribeMessage)
       
      }
    
      
    
    })
    .catch((error) => {
      console.log(error);
      setLoadingApiData(false)
    });


  /*return () => {

    let unsubscribeMessage = {"action":"unsubscribe", "instruments":[subscribeSymbols]}
   props.sendWebsocketMessageToServer(unsubscribeMessage)
  
}*/


  }, [props.refresh]);

  useEffect(() => {
    inputs.limit_price=props.selectedAsset.price
}, [props.selectedAsset]);

  useEffect(() => {
        inputs.limit_price=props.selectedAsset.price
  }, [props.selectedAsset.price]);

  const navigate = useNavigate();

const [inputsStoploss, setInputsStoploss] = useState({});
const [taxesAndCharges, setTaxesAndCharges] = useState(0.0);

  const priceChange = (
  Number((props.selectedAsset.price - props.selectedAsset.close).toFixed(2))
  )
  const changePercent24Hr = (
    Number(((priceChange/props.selectedAsset.close)*100)).toFixed(2));

const [marginAvailable, setMarginAvailable] = useState(0.0);

  const handleChangeStoploss = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: checked }));
  };

  //const initialQuantity = (localStorage.getItem("props.selectedQuantityForTransaction")) ? JSON.parse(localStorage.getItem("props.selectedQuantityForTransaction")) : 1
  //const initial_order_time_type = ( localStorage.getItem("order_time_type") && localStorage.getItem("order_time_type")!== "undefined" ) ? (localStorage.getItem("order_time_type")) : "MIS"

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingApiData, setLoadingApiData] = useState(true);



  const [show, setshow] = useState(false);
  const [isbuttonActive, setisbuttonActive] = useState('');
  const [placeOrderButtonDisabled, setPlaceOrderButtonDisabled] =
    useState(false);
    //TODO change to true
  const [limitPriceDisabled, setLimitPriceDisabled] = useState(false);

  const activebtn = (value) => {
    setisbuttonActive(value);
  };


 
  const [showInputFields, setshowInputFields] = useState(false);


 // const [props.transactionType, props.setTransactionType] = useState(
 //   localStorage.getItem("currentTransactionType")
 // );

  //const [toggle, setToggle] = useState();
  const [stockMarket, setStockMarket] = useState('BSE');



var toggle
if(props.transactionType === "buy" || props.transactionType === "squareoff"){
  toggle = false
   }else if (props.transactionType === "sell" || props.transactionType === "shortsell"){
      toggle = true
   }





  
  function handleEnableLimitPrice () {
    console.log("Called")
    setLimitPriceDisabled(false)
    setInputs((values) => ({ ...values, orderPriceType: "limit" }));
    
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    
       
   /* if (event.target.name === 'props.orderPriceType') {
      if (event.target.value === 'limit') {
        setLimitPriceDisabled(false);
      } else {
       
      setLimitPriceDisabled(true);
      }
    }*/
  };

  const handleSubmitOrder = (type, event) => {
    setLoading(true);
    event.preventDefault();
    
   
    let account_id = JSON.parse(localStorage.getItem('account_id'));
    inputs.account_id = account_id;

    if(inputs.orderPriceType==="market"){
      console.log("setting limit price to 0")
      inputs.limit_price=0
    }

    inputs.symbol = props.selectedAsset.symbol;
    inputs.transactionType = type;

    if(props.selectedAsset.lot_size > 1){
      inputs.quantity = inputs.quantity * props.selectedAsset.lot_size
    }
 

    api
      .submitOrder(inputs)
      .then(({ data }) => {
      
        if (data.error) {
          setLoading(false);
          if(props.selectedAsset.lot_size > 1){
            inputs.quantity = (inputs.quantity / props.selectedAsset.lot_size)
          }
          setErrorMessage(data.error);
   
         
        
      
        }

        if (data.success) {
          setLoading(false);
          props.setFlashMessage({"message":data.message, "color":"blue"})
          // props.setDisplayPage("WatchList")
         // props.setDisplayPage("PendingOrders")
         setSuccessMessage(data);
         setOpen(true)
      
        
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrorMessage(err);
        console.log(err);
      
      });
    
  };

  const handleCancel = (type, event) => {
   
    event.preventDefault();
    
   props.setDisplayPage(props.previousPage)
   
    
  };


  useEffect(() => {
    let account_id = JSON.parse(localStorage.getItem('account_id'));
    api
    .getAccount(account_id)
    .then(({ data }) => { 
      if(data.success){
        setMarginAvailable( Number(data.account.points).toFixed(2))
      }
    })
    .catch((error) => {
      console.log(error);
    
    });
  }, [props.refresh]);

  function handleChangeTransactionType() {
  
    if (props.transactionType === 'buy') {
     localStorage.setItem("currentTransactionType", "shortsell" );
      setToggle(true);
      props.setTransactionType('shortsell');
    
    } else if (props.transactionType === 'shortsell') {
      localStorage.setItem("currentTransactionType", "buy" );
      props.setTransactionType('buy');
      setToggle(false);
     
    }
   
  }

  function getMargin() {
    let margin = 0
    let leverage= 1

    if(props.selectedAsset.instrument_type === "FUT"){
      leverage= constants.futures_leverage
    }
  else if(inputs.order_time_type === "MIS" && props.selectedAsset.instrument_type === "EQ"){
    leverage= constants.intraday_leverage
  }

    if(props.transactionType === "buy" || props.transactionType === "shortsell"){
      let quantity = inputs.quantity ? inputs.quantity : props.selectedQuantityForTransaction
      let price = (inputs.orderPriceType==="market") ? props.selectedAsset.price : inputs.limit_price
     
       if(props.selectedAsset.lot_size > 1){
        margin = ((quantity * props.selectedAsset.lot_size * price)/leverage)
       }else {
        margin = ((quantity * price)/leverage)
       }

    
    }
   
   return margin.toFixed(2)
  }

  function getCharges() {
    if (inputs.quantity) {
      return Number(inputs.quantity * props.selectedAsset.price * 0.04).toFixed(2);
    } else {
      return Number(props.selectedQuantityForTransaction * props.selectedAsset.price * 0.04).toFixed(2);
    }
  }

  function calculateTaxesAndCharges( ){

    let quantity = inputs.quantity ? inputs.quantity : props.selectedQuantityForTransaction
    let order_time_type = inputs.order_time_type
   
    let price = (inputs.orderPriceType==="market") ? props.selectedAsset.price : inputs.limit_price
    let turnover = Number(quantity * price)
    let brokerage =0 , stt_tax=0 , exchange_txn_tax=0 , sebi_tax=0 , stamp_duty_tax=0 , gst_tax=0 , total_charges_taxes=0 
  
      if(order_time_type === "MIS"){
        brokerage = (0.03/100 * turnover)
        if(brokerage > 20){
          brokerage = 20
        }
      }
     
  
      if(order_time_type === "CNC" || order_time_type === "NRML"){
        stt_tax =( 0.1/100)  * turnover
      } else if (order_time_type === "MIS" && (props.transactionType === "sell" || props.transactionType === "squareoff" )){
        stt_tax =( 0.025/100)  * turnover
      }
  
      exchange_txn_tax =  (0.00325/100) * turnover
  
      sebi_tax = (0.0001/100) * turnover
  
      if((order_time_type === "CNC" || order_time_type === "NRML") && props.transactionType === "buy"){
        stamp_duty_tax = (0.015/100) * turnover
      } else if (order_time_type === "MIS" && (props.transactionType === "buy")){
        stamp_duty_tax =( 0.003/100)  * turnover
      }
      
      gst_tax = Number((18/100)* (brokerage + sebi_tax + exchange_txn_tax))
  
      total_charges_taxes= Number(brokerage + stt_tax + exchange_txn_tax + sebi_tax + stamp_duty_tax + gst_tax).toFixed(2)
   
   return total_charges_taxes
     // setTaxesAndCharges(total_charges_taxes)
    
    }



  let assetImage;
  assetImage = (
    <Image
      className='rounded'
      style={{ height: 30 }}
      src={`https://storage.googleapis.com/stocks_logo/stocks/${props.selectedAsset.symbol}.png`}
      loading='lazy'
    />
  );



  function getCssClass() {
  if(changePercent24Hr){
    return changePercent24Hr
  }
  
}

  return (
    <>
     {loadingApiData ?    <div className="loader-image"><img src='/images/loader.gif'/></div>  : 
    <div className='place-order-popup'>
      {loading && (
        <div className='loading d-flex justify-content-center align-items-center'>
          <div>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        </div>
      )}
      <div className={toggle ? 'error-message' : 'success-message'}>
        <div className='top_message d-flex align-items-start'>
          {successMessage && (
            
<>
{/*
          <React.Fragment>
      
          <div  style={{ color: 'white' }}>
          <Dialog  className="place-order-success"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className='sweet-alert ' id="alert-dialog-title" style={{ color: 'white' }}>
            {'\t'} {"Order Placed"}
             
            </DialogTitle>
            <DialogContent>
              <DialogContentText  id="alert-dialog-description">
              {"                                "}
                <div style={{ color: 'white' }}>
                <div
                  className='card grey-border'
                  style={{ background: '#24262e' }}
                >
                  <div className='card-body'>
                    <div className='d-flex align-items-center justify-content-between succ-stock'>
                      <div>
                      
                        {successMessage.order.symbol}
                      </div>
                      <Chip
                        className='text-capitalize'
                        variant='outlined'
                        color='success'
                        label={successMessage.order.orderPriceType}
                      />
                       <Chip
                        className='text-capitalize'
                        variant='outlined'
                        color='success'
                        label={successMessage.order.order_time_type}
                      />
                      
                    </div>
                  </div>
                </div>
                
                <div
                  className='card mt-4 border succ-stats'
                  style={{ background: '#24262e' }}
                >
                  <div className='card-body'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='d-flex flex-column col-6 b-r'>
                        <h6 className='text-muted'>Quantity</h6>
                        <span>{successMessage.order.quantity}</span>
                      </div>
                      <div className='col-6'>
                        <h6 className='text-muted'>Price</h6>
                        <span>{successMessage.order.entryprice}</span>
                      </div>
                     
                    </div>
                  </div>




                </div>
              </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Stack
 

  justifyContent="flex-start"
  alignItems="flex-start"
  spacing={2}
>

            <Button className="confirmBtnStyle" onClick={handleClose}>Disagree</Button>
              <Button className="confirmBtnStyle" onClick={handleClose}>Disagree</Button>
              <Button className="confirmBtnStyle" onClick={handleClose}>Disagree</Button>
              <Button className="cancelBtnStyle" onClick={handleClose}>
                Agree
              </Button>
</Stack>
             
            </DialogActions>
          </Dialog>
          </div>
        
          </React.Fragment>

            */ } <>{
            <SweetAlert
           // timeout={2000}
              onConfirm={() => {
                setSuccessMessage(''), props.setDisplayPage('ExecutedOrders');
              }}
              confirmBtnText='VIEW PORTFOLIO'
              confirmBtnStyle={{
                backgroundColor: '#6046cf',
                fontSize: '12px',
                boxShadow: 'none',
                borderColor: '#6046cf',
                width: '100%',
                marginBottom:'10px',
                marginRight: '0px'
              
              }}
              onCancel={() => {setSuccessMessage('')}}
              customButtons={
                <React.Fragment>
                   <button className='confirmBtnStyle' onClick={() => {
                setSuccessMessage(''), props.setDisplayPage('WatchList');
              }} >Explore Market</button>
                  <button onClick={() => {
                setSuccessMessage(''), props.setDisplayPage('ExecutedOrders');
              }}    className='confirmBtnStyle'>View Orders</button>
                  <button onClick={() => {
                setSuccessMessage(''), props.setDisplayPage('Holdings');
              }} className='confirmBtnStyle'>View Portfolio</button>
                  
                 
                </React.Fragment>
              }
              
              cancelBtnText='EXPLORE MARKET'
              cancelBtnStyle={{
                fontSize: '12px',
                boxShadow: 'none',
                width: '100%',
                marginBottom:'10px',
                marginRight: '0px'
              }}
              cancelBtnBsStyle='secondary'
             
              style={{ backgroundColor: '#24262e', color: 'white' }}
              
              customIcon='images/success.png'
              custom
              title='Order Successfully Placed'
              className="succ-img"
              showCloseButton={true}
            
            >
          
              <div style={{ color: 'white' }}>
                <div
                  className='card grey-border'
                  style={{ background: '#24262e' }}
                >
                  <div className='card-body'>
               
                    <div className='align-items-center justify-content-between succ-stock'>
                      <p>
                        
                        {successMessage.order.symbol}
                      </p>
                     
                      <p>
                      <Chip
                        className='text-capitalize'
                        variant='outlined'
                        color='success'
                        label={successMessage.order.orderPriceType}
                        size="small"
                      />
                       <Chip
                        className='text-capitalize'
                        variant='outlined'
                        color='success'
                        label={successMessage.order.order_time_type}
                        size="small"
                      />
                        <Chip
                        className='text-capitalize'
                        variant='outlined'
                        color='success'
                        label={successMessage.order.transactionType}
                        size="small"
                      />
                        </p>
                     
                    </div>
                  </div>
                </div>
                <div
                  className='card mt-3 border succ-stats'
                  style={{ background: '#24262e' }}
                >
                  <div className='card-body'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='d-flex flex-column col-6 b-r'>
                        <h6 className='text-muted'>Quantity</h6>
                        <span>{successMessage.order.quantity}</span>
                      </div>
                      <div className='col-6'>
                        <h6 className='text-muted'>Unit Price</h6>
                        <span>{successMessage.order.entryprice}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </SweetAlert> 
            }
            </>
            </>
          )}
          {errorMessage && (
            

              <SweetAlert
             
              onConfirm={() => {
                setSuccessMessage(''), setErrorMessage('');
              }}
              onCancel={() => {setSuccessMessage(''),  setErrorMessage('');}}
              confirmBtnText='OK'
              confirmBtnStyle={{
                backgroundColor: '#0094ff',
                fontSize: '12px',
                boxShadow: 'none',
                borderColor: '#6046cf',
                width: '100%',
                marginBottom:'10px',
                marginRight: '0px'
              
              }}
              
              style={{ backgroundColor: '#24262e', color: 'white' }}
              customIcon='images/success.png'
              custom
              title='Order Failed'
              className="succ-img"
              showCloseButton={true}
             

              >
              {errorMessage}
              </SweetAlert> 
            
          )}
          <div className='d-flex align-items-center justify-content-between'>
         {/** <span className='back'><ArrowBackIcon/></span> */}  
            <div className='stock-info'>
           {  /**{assetImage}{' '} */ }
              <span>
              <bdi>
              {props.selectedAsset.display_name}
              </bdi>
            
              {  (props.selectedAsset.display_name).includes("CE") || (props.selectedAsset.display_name).includes("PE") || (props.selectedAsset.display_name).includes("FUT") ? 
             <>{props.selectedAsset.display_name}{' '}</>
             :
             <> {props.selectedAsset.symbol}{' '}</>  }

               {/**  {props.selectedAsset.display_name}  <sup>{stockMarket}</sup> **/}X{' '}
                {props.selectedAsset.lot_size > 1 ? <>{inputs.quantity ? Number(inputs.quantity * props.selectedAsset.lot_size) : '1'} Qty.</>: <>{inputs.quantity ? inputs.quantity : '1'} Qty.</>}            
              </span>
    
            </div>
          {/**  <div className='form-switch-wrap'>Buy
            {(props.transactionType === 'buy' ||
              props.transactionType === 'shortsell') && (
              <Form.Check // prettier-ignore
                type='switch'
                id='custom-switch'
                onClick={handleChangeTransactionType}
              />
            )}Sell
              </div> */}
          {/* <p>{props.selectedAsset.name}</p> */}
          {/* <p>{props.selectedAsset.price}</p> */}
        </div>

        <div className='d-flex  justify-content-end selected-stock'>
           
              <div className='d-flex price-block '>
               
              <div className='d-flex  justify-content-end'>   <span className='target-price'>₹{props.selectedAsset.price }</span></div>

              <div className='selected-price'>   
              <p className={`d-block ${getCssClass()} title`}>
                  {priceChange && Number(priceChange).toFixed(2)}
                </p>
                |
                <p className={`d-block ${getCssClass()} title`}>
                  {changePercent24Hr && Number(changePercent24Hr).toFixed(2)}%{' '}
                </p>
              </div>
           
                <span>
                  {' '}
                
                </span>
              </div>
            </div>

          {/* <p></p> */}
        </div>
      {/* <div className='bottom_message d-flex align-items-end mt-3'>
        <div className='main_text'>
              {'BSE:'}
            <input
              type='radio'
              value={'bse'}
              className='stockMarket-radio'
              checked={stockMarket === 'BSE'}
              name='abc'
              id='abc'
              onChange={() => setStockMarket('BSE')}
            />
            ₹{props.selectedAsset.price}
            <span className='ms-2'>
              {'NSE:'}
              <input
                type='radio'
                value={'nse'}
                className='stockMarket-radio'
                name='abc'
                checked={stockMarket === 'NSE'}
                id='abc'
                onChange={() => setStockMarket('NSE')}
              />
              ₹{props.selectedAsset.price}
            </span>
          </div>
          <p></p>
        </div> */}
      </div>

      <form>
        {/* <div className='radio'>
          <label>
            <input
              type='radio'
              value='market'
              name='props.orderPriceType'
              checked={
                inputs.orderPriceType === 'market' || !inputs.orderPriceType
              }
              onChange={handleChange}
            />
            Market
          </label>
        </div>
        <div className='radio'>
          <label>
            <input
              type='radio'
              value='limit'
              name='props.orderPriceType'
              checked={inputs.orderPriceType === 'limit'}
              onChange={handleChange}
            />
            Limit
          </label>
        </div> */}

{/**
<div className='d-flex justify-content-start my-3'>
          <div className='d-flex'>
            <div className='radio me-3'>
              <label>
                <input
                  type='radio'
                  value='market'
                  name='props.orderPriceType'
                  checked={
                    inputs.orderPriceType === 'market' || !inputs.orderPriceType
                  }
                  onChange={handleChange}
                />
                 <label className='text-light'>Market</label>
                
              </label>
            </div>
            <div className='radio'>
              <label>
                <input
                  type='radio'
                  value='limit'
                  name='props.orderPriceType'
                  checked={inputs.orderPriceType === 'limit'}
                  onChange={handleChange}
                />
                     <label className='text-light'>Limit</label>
                
              </label>
            </div>
          </div>
                </div> */}



                
  <div className='market-title'>Product</div>
<div className="btn-group col-12 radio-btn mt-1">
<div className='selection-tab'>
  <span>
            <input
                  type='radio'
                  value='MIS'
                 
                  name='order_time_type'
                  checked={
                    inputs.order_time_type === 'MIS' 
                  }
                  onChange={handleChange}
                />
                <label className="flash-message">
            <b>Intraday</b>  (MIS) </label>
            </span>
            </div> 
            <div className='selection-tab'>
            <span>
             <input
                  type='radio'
                  value='CNC'
                 
                  name='order_time_type'
                  checked={inputs.order_time_type === 'CNC'}
                  onChange={handleChange}
                />
             <label className="flash-message">
            <b>Longterm</b> (CNC)  </label>
            </span>
            </div>
           
    </div>

    <div className='quanity-price d-flex quantity-box'>
          <div className='inner-quantity'>
          <div className='quantitiy-wrap'>
        
          <label className='text-light'>{props.selectedAsset.lot_size > 1 ? <div>Lots</div> : <div>Quantity</div>}</label>
            <span>{props.selectedAsset.lot_size > 1 && <div>1 Lot = {props.selectedAsset.lot_size}</div>}</span>
            </div>
            <input
              type='number'
              className='number unit-qty bg-light w-100 py-2'
              value={inputs.quantity}
              onChange={handleChange}
              name='quantity'
              placeholder=''
              id='quantity'
              min={0}
              step={1} 
            />

            
          </div>
          <div className='inner-quantity'  onClick={handleEnableLimitPrice}>
          <div className='quantitiy-wrap'>
          <label className='text-light'>Price</label>
          <span>Tick size {props.selectedAsset.tick_size}</span>
          </div>
            <input
              type='number'
              inputMode='Decimal'
              className='number unit bg-light w-100 py-2'
              name='limit_price'
              value={inputs.orderPriceType === "limit" ? inputs.limit_price : props.selectedAsset.price}
              onChange={handleChange}
              disabled={limitPriceDisabled}
              min={0}
              step={props.selectedAsset.tick_size} 
             
            />
          </div>
        </div>

    
    <div className='market-title'>Type</div>

        <div className="btn-group col-12 radio-btn mt-1">

        <div className='selection-tab'>
           <span className='limit-wrap'>       
            <input
                  type='radio'
                  value='market'
                  id="market-btn"
                  name='orderPriceType'
                  checked={
                    inputs.orderPriceType === 'market'
                  }
                  onChange={handleChange}
                />
              <label className="btn btn-rounded btn-md form-check-label" >
            Market </label>
            </span>
            </div>
            <div className='selection-tab'>
            <span className='limit-wrap'>   

             <input
                  type='radio'
                  value='limit'
                  id="limit-btn"
                  name='orderPriceType'
                  checked={inputs.orderPriceType === 'limit'}
                  onChange={handleChange}
                />
             <label className="btn btn-rounded btn-md form-check-label" >
            Limit  </label>
    
            </span>
            </div>
    </div>

        

        {/**props.transactionType !== 'sell' && props.transactionType !== 'squareoff' && (
          <div className='stoploss-checkboxes'>
            <div className='d-flex align-items-center'>
              <input type='checkbox'  name='stoplossCheckbox'
          onChange={handleCheckboxChange}></input>
              <span style={{ marginRight: '44px', marginLeft: '5px' }}>
           <label className='text-light'>  Stoploss :{' '}</label>
              
              </span>
              <input
                type='number'
                inputMode='Decimal'
                className={`number unit-qty w-25 ${inputs.stoplossCheckbox ? 'enabled' : ''}`}

                name='stoploss'
                value={inputs.stoploss || ''}
                placeholder='stoploss'
                onChange={handleChange}
                readOnly={!inputs.stoplossCheckbox} // Add the readOnly attribute here

              />{' '}
               <bdi>%</bdi>
            </div>
            <div className='d-flex align-items-center mt-3'>
              <input type='checkbox'     name='targetprofitCheckbox'
          onChange={handleCheckboxChange}></input>
              <span style={{ marginRight: '25px', marginLeft: '5px' }}>
              <label className='text-light'>   Targetprofit :</label>
               
              </span>
              <input
                type='number'
                inputMode='Decimal'
                className={`number unit-qty w-25 ${inputs.targetprofitCheckbox ? 'enabled' : ''}`}

                name='targetprofit'
                value={inputs.targetprofit || ''}
                placeholder='targetprofit'
                onChange={handleChange}
                readOnly={!inputs.targetprofitCheckbox} // Add the readOnly attribute here

                
              />
              
               <bdi>%</bdi>
            </div>{' '}
        
          </div>
        )**/}

        <div className='margin-charges quantity-box mb-2 align-items-left' >
          <div className='d-flex'>
            <div className='d-flex me-5 align-items-center'>
              <p className='text-light placeorder-margin-required'>Margin Required: </p>
              <p className='buttontext1'>{getMargin()}</p>

              
             <p className='text-light placeorder-charges'>Charges : </p>
              <p className='buttontext1'>{calculateTaxesAndCharges()}</p>

            

           
        
              
            </div>
         {/*  <div className='d-flex me-5 '>
              <span className='text-light placeorder-margin-available'>Margin Available: </span>
              <p className='buttontext1'>{marginAvailable}</p>
            </div>*/}  
        
          </div>

          <div className='d-flex me-5 align-items-center'>
          <p className='text-light placeorder-margin-available'>Margin Available: </p>
          <p className='buttontext1'>{marginAvailable}</p>
           

            </div>
     
        </div>

    {/**    <div className='margin-charges quantity-box mb-3 ' >
          <div className='d-flex'>
  
            <div className='d-flex align-items-center'>
              <span className='text-light placeorder-charges'>Charges : </span>
              <p className='buttontext1'>{calculateTaxesAndCharges()}</p>

            </div>
          </div>
     
        </div> */}

        <div className='d-flex order-btns'>
            
            { props.transactionType !== "null"  && <button
                disabled={placeOrderButtonDisabled}
                onClick={(event) => handleSubmitOrder(props.transactionType, event)}
                className={`btn w-100 ${
                  toggle ? 'btn-danger' : 'btn-primary'
                }`}
              >
                {props.transactionType.replaceAll('"', '')}
              </button>} 
  
              { props.transactionType === "null" && <button
                disabled={placeOrderButtonDisabled}
                onClick={(event) => handleSubmitOrder("buy", event)}
                className={`btn w-100 ${
                  toggle ? 'btn-danger' : 'btn-primary'
                }`}
              >
                Buy
              </button>} 
  
              { props.transactionType === "null" && <button
                disabled={placeOrderButtonDisabled}
                onClick={(event) => handleSubmitOrder("shortsell", event)}
                className={`btn w-100 ${
                  toggle ? 'btn-danger' : 'btn-primary'
                }`}
              >
                ShortSell
              </button>} 
            
             <button
               
               onClick={(event) => handleCancel("cancel", event)}
                className='btn  w-100 cancelbtn'
                variant='light'
              >
                Cancel
              </button> 
            </div>
      </form>
      </div>
      }
    </>
  );
};

export default PlaceOrderForm;
