import React from "react";
// import  Img from '../../public/images/Holdings.svg'
// import market from '../../public/images/Market.svg'
// import orders from '../../public/images/Orders.svg'
import { useState, useContext, useEffect } from "react";
import useWindowSize from '../components/useWindowSize';
import ViewListIcon from '@mui/icons-material/ViewList';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
function FooterNew({
  displayPage,
  user,
  setDisplayPage,
  setshowCategory,
}) {

  const windowSize = useWindowSize(); 


  return (
    <div className="main-footer">
      {windowSize.width >= 768 ? (
        <footer className="footer_stage">
       
            <button
              className={`${
                displayPage == "Dashboard" ? "active-btn" : null
              }  btn `}
              onClick={() => {
           
                setDisplayPage("Dashboard");
              }}
            >
              {/* {console.log(Img,'img')} */}
              <div className="menu-item">
                <ImportExportIcon/>
                {/* <img src="/images/trade.png" className="fotter-img"></img> */}
                <span className="footer-label">Dashboard</span>{" "}
              </div>
            </button>
        
          <div>
            <button
              className={`${
               (displayPage == "WatchList" || displayPage === "Stocks" || displayPage === "StocksFutures" || displayPage === "StocksOptions" || displayPage === "StocksOptionsDetails" || displayPage === "SearchResults") ? "active-btn" : null
              }  btn `}
              onClick={() => {
           
                setDisplayPage("WatchList");
              }}
            >
              {/* {console.log(Img,'img')} */}
              <div className="menu-item">
                <QueryStatsIcon/>
                {/* <img src="/images/trade.png" className="fotter-img"></img> */}
                <span className="footer-label">Market</span>{" "}
              </div>
            </button>
          </div>

          <button
            className={`${
              displayPage === "PendingOrders" ||
              displayPage === "ExecutedOrders"
                ? "active-btn"
                : null
            }  btn `}
            onClick={() => {
         
              setDisplayPage("PendingOrders");
            }}
          >
            {/* <span className="counts">4</span> */}
            <div className="menu-item">
              <ViewListIcon/>
              {/* <img src="/images/listing.png" className="fotter-img"></img> */}
              <span className="footer-label">Orders</span>{" "}
            </div>
          </button>
          <button
            className={`${
              displayPage === "Holdings" || displayPage === "ClosedPositions"
                ? "active-btn"
                : null
            }  btn `}
            onClick={() => {
   
              setDisplayPage("Holdings");
            }}
          >
            <div  className="menu-item">
              <DonutSmallIcon/>
              {/* <img src="/images/holdings.png" className="fotter-img"></img> */}
              <span className="footer-label">Portfolio</span>{" "}
            </div>
            {/* <span className="counts">4</span> */}
          </button>
       {/**   <button
            className={`${
              displayPage === "Tournament" ? "active-btn" : null
            }  btn tournament-btn`}
            onClick={() => {
          
              setDisplayPage("Tournament");
            }}
          >
             <div  className="menu-item">
              <img src="/images/tournament.png" className="fotter-img"></img>
              <span className="footer-label">Tournament</span>
            </div>
            
            <span className="counts">4</span> 
          </button> */}
        </footer>
      ) : (
        <div className="container footer-main position-fixed bottom-0 w-100 ">
          <footer className="d-flex footer-btns flex-row justify-content-between w-100">
         
            <button
              className={`${
                displayPage == "Dashboard" ? "active-btn" : null
              }  btn `}
              onClick={() => {
           
                setDisplayPage("Dashboard");
              }}
            >
              {/* {console.log(Img,'img')} */}
              <div className="menu-item">
                <ImportExportIcon/>
                {/* <img src="/images/trade.png" className="fotter-img"></img> */}
                <span className="footer-label">Dashboard</span>{" "}
              </div>
            </button>
       
         
            <div>
              <button
                className={`${
                  (displayPage == "WatchList" || displayPage === "SearchResults" || displayPage === "Stocks" || displayPage === "StocksFutures" || displayPage === "StocksOptions" || displayPage === "StocksOptionsDetails") ? "active-btn" : null
                }  btn `}
                onClick={() => {
               
                  setDisplayPage("WatchList");
                }}
              >
                {/* {console.log(Img,'img')} */}
                <div>
                  <QueryStatsIcon/>
                   {/* <img src="/images/trade.png" className="fotter-img"></img> */}
                  <span className="footer-label">Market</span>{" "}
                </div>
              </button>
            </div>

            <button
              className={`${
                displayPage === "PendingOrders" ||
                displayPage === "ExecutedOrders"
                  ? "active-btn"
                  : null
              }  btn `}
              onClick={() => {
             
                setDisplayPage("PendingOrders");
              }}
            >
              {/* <span className="counts">4</span> */}
              <div>
               <ViewListIcon/>
                <span className="footer-label">Orders</span>{" "}
              </div>
            </button>
            <button
              className={`${
                displayPage === "Holdings" || displayPage === "ClosedPositions"
                  ? "active-btn"
                  : null
              }  btn `}
              onClick={() => {

                setDisplayPage("Holdings");
              }}
            >
              <div>
                <DonutSmallIcon/>
                 {/* <img src="/images/holdings.png" className="fotter-img"></img> */}
                <span className="footer-label">Portfolio</span>{" "}
              </div>
              {/* <span className="counts">4</span> */}
            </button>
          {/**     <button
              className={`${
                displayPage === "Tournament" ? "active-btn" : null
              }  btn tournament-btn`}
              onClick={() => {
              
                setDisplayPage("Tournament");
              }}
            >
            <img src="/images/tournament.png" className="fotter-img"></img>
              <span className="footer-label">Tournament</span>
           <span className="counts">4</span> 
            </button> **/}
          </footer>
        </div>
      )}
    </div>
  );
}

export default FooterNew;
