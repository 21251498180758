import { Image } from 'react-bootstrap';
import { useState, useContext, useEffect } from 'react';
import ForwardIcon from '@mui/icons-material/Forward';
import { UserContext } from '../contexts/UserContext';
import { Chip } from '@mui/material';

const HoldingRowDisplay = (props) => {

   

  let buymoreButton, sellButton, shortsellmoreButton, squareoffButton;

 const holding_type = (props.holding.side == 1 ? "buy" : "shortsell")
  const assetSymbol = props.holding.symbol;

  //var currentAssetObjectPrice = props.holding.ltp
 //  var currentAssetObjectPrice = props.holding.avgPrice
  //const currentAssetObject = props.holdingsAssetArray.find((obj) => {
   //  return obj.symbol === props.holding.symbol;
  // });
  // if(currentAssetObject){
  //  currentAssetObjectPrice = currentAssetObject.price
  // }

  // props.holding.ltp = currentAssetObjectPrice

   props.holding.profitloss = Number(((props.holding.ltp - props.holding.avgPrice) * props.holding.quantity * props.holding.side));
   props.holding.pnlPercent = Number((((props.holding.ltp - props.holding.avgPrice) * props.holding.side)/props.holding.avgPrice)*100)
  props.holding.marketvalue = Number(props.holding.ltp * props.holding.quantity)
  props.holding.amount_invested = Number(props.holding.quantity *  props.holding.avgPrice)
 
  const openPlaceOrderForm = (transactionType, event, symbol, quantity) => {
    event.preventDefault();

   // const object = props.holdingsAssetArray.find((obj) => {
    //  return obj.symbol === assetSymbol;
   // });

   // if(!object){
   //   console.log("price not found")
   //   return
   // }
   props.setSelectedAsset({"symbol":symbol});
   localStorage.setItem("selectedAsset", JSON.stringify(props.selectedAsset));
   //localStorage.setItem('selectedAsset', symbol);
   props.setSelectedAssetName(symbol)
   
    ///let selectedQuantityForTransaction = quantity
    //if(object.lot_size > 1){
    //  selectedQuantityForTransaction = Number(quantity/object.lot_size)
    //}
    // user.selectedAsset = object.symbol;
    // localStorage.setItem('selectedAsset', symbol);
    // localStorage.setItem("selectedAssetObject", JSON.stringify(props.holding));
    props.setSelectedQuantityForTransaction(quantity)
    // localStorage.setItem('selectedQuantityForTransaction', quantity);
    // localStorage.setItem('currentTransactionType', transactionType);
    props.setTransactionType(transactionType)
    // localStorage.setItem('order_time_type', props.holding.order_time_type);
    if(props.IsPosition){
      props.setOrderTimeType("MIS")
    }else {
      props.setOrderTimeType("CNC")
    }
   
     props.setPreviousPage(props.displayPage)
    props.setDisplayPage('PlaceOrderForm');
  };

  if (holding_type === 'buy') {
    sellButton = (
      <button
        onClick={(event) =>
          openPlaceOrderForm(
            'sell',
            event,
            props.holding.symbol,
            props.holding.quantity
          )
        }
        className='btn btn-red  w-100  close-tab'
      >
        Sell <span></span>
      </button>
    );

    buymoreButton = (
      <button
        onClick={(event) =>
          openPlaceOrderForm(
            'buy',
            event,
            props.holding.symbol,
            props.holding.quantity
          )
        }
        className='btn btn-primary w-100 close-tab'
      >
        Buy More <span></span>
      </button>
    );
  } else if (holding_type === 'shortsell') {
    squareoffButton = (
      <button
        onClick={(event) =>
          openPlaceOrderForm(
            'squareoff',
            event,
            props.holding.symbol,
            props.holding.quantity
          )
        }
        className='btn btn-primary  w-100  close-tab'
      >
        Square Off <span></span>
      </button>
    );

    shortsellmoreButton = (
      <button
        onClick={(event) =>
          openPlaceOrderForm(
            'shortsell',
            event,
            props.holding.symbol,
            props.holding.quantity
          )
        }
        className='btn btn-red  w-100  close-tab'
      >
        Short Sell More <span></span>
      </button>
    );
  }

  let assetImage;
  assetImage = (
    <Image
      className='rounded w-[24px] h-[24px]'
      style={{ height: 34 }}
      src={`https://storage.googleapis.com/stocks_logo/stocks/${props.holding.symbol}.png`}
      loading='lazy'
    />
  );

  

  function getPurchaseValue(quantity, avgPrice) {
    return quantity * avgPrice;
  }

  function calculateProfitLoss(openorder) {
    let side;
    if (openorder.type === 'buy') {
      side = 1;
    } else if (openorder.type === 'shortsell') {
      side = -1;
    }
    let currentPriceOfAsset = openorder.avgPrice;
    if (currentAssetObject) {
      currentPriceOfAsset = currentAssetObjectPrice;
    }

    let profitloss =
      openorder.quantity * side * (currentPriceOfAsset - openorder.avgPrice);
    return profitloss;
  }

  function calculateProfitLossPercentage(openorder) {
    let side;
    if (openorder.type === 'buy') {
      side = 1;
    } else if (openorder.type === 'shortsell') {
      side = -1;
    }
    let currentPriceOfAsset = openorder.avgPrice;
    if (currentAssetObject) {
      currentPriceOfAsset = currentAssetObjectPrice;
    }

    let profitloss = side * (currentPriceOfAsset - openorder.avgPrice);

    return ((profitloss / openorder.avgPrice) * 100);
  }

  const calculateMarketValue = (marketPrice, quantity) => {
    return marketPrice * quantity;
  };

  // if order type === limit then show limit_price otherwise show entryprice
  return (
    <div
      className='holding-main'
      style={{ cursor: 'pointer' }}
     /** onClick={() => {
        // windowWidth <= 1000 ? {props.setSelectedHoldings(props.holding)
        //   props.setShowSelectedHoldings(true)}: props.setShowSelectedHoldings(false)
        windowWidth >= 1000 &&
          (props.setSelectedHoldings(props.holding),
          props.setShowSelectedHoldings(true));
      }}**/
    >
      {/* Type{' '}
      <p>
        {holding_type}
        {'\n'}{' '}
      </p>
      <div className='d-flex align-items-center'>{assetImage}</div>
      Symbol{' '}
      <p>
        {props.holding.symbol}
        {'\n'}
      </p>
      asset_name<p>{props.holding.asset_name}</p>
      quantity<p>{props.holding.quantity}</p>
      avgPrice <p> {props.holding.avgPrice}</p>
      asset_sector<p>{props.holding.asset_sector}</p>
      purchase value
      <p>{getPurchaseValue(props.holding.quantity, props.holding.avgPrice)}</p>
      LTP <p>{currentAssetObject && currentAssetObjectPrice}</p>
      profitLoss<p>{calculateProfitLoss(props.holding)}</p>
      P&L % <p>{calculateProfitLossPercentage(props.holding)}</p>
      <div className='d-flex justify-content-around'>
        <div className='col-5'>{sellButton}</div>
        <div className='col-5'>{buymoreButton}</div>
      </div>
      <div className='d-flex justify-content-around'>
        <div className='col-5'>{squareoffButton}</div>
        <div className='col-6'>{shortsellmoreButton}</div>
      </div> */}
      <div
        className='card mb-3 py-2 px-2 position-relative '
      >
        {/* <div
          className='position-absolute top-50 left-30'
          style={{ transform: 'rotate(-360deg)', left: '-7%' }}
        >
          {holding_type === 'shortsell' ? (
            <Chip label='short sell' variant='outlined' color='error' />
          ) :<Chip label='Buy' variant='outlined' color='success' /> }
        </div> */}
        <div className='card-body'>
          <div className='d-flex align-items-center headerbox'>
            <div className='d-flex main-holding-row'>
            {/**<div className='d-flex'>{assetImage}</div> */}  
              <div className='holding-stock'>
              <h6 className='card-subtitle text-muted holding-subtitle'>
                  {props.holding.asset_name}
                </h6>
                {  (props.holding.asset_name).includes("CE") || (props.holding.asset_name).includes("PE") || (props.holding.asset_name).includes("FUT") ? 
             <></>
             :
             <h6 className='card-title holding-title'>
                  {props.holding.symbol}
                </h6> }  
         
                
            
              </div>

              <div
                  className='btn-container'
                  // style={{ transform: 'rotate(-360deg)', left: '100%' }}
                  // variant='outlined'
                >
                  {holding_type === 'buy' ? (
                    <>
                      <div className=' buy-btn'>Buy</div>
                    </>
                  ) : (
                    <>
                      <div className='short_cell-btn set_font '>Short Sell</div>
                    </>
                  )}
                </div>

             
              <div className={`card-title holding-title holding-percent qty-side ${ Number(props.holding.pnlPercent).toFixed(2) < 0 ? "negative" : "positive"}`}>
                    { Number(props.holding.pnlPercent).toFixed(2)}
                    {' % '}
                    {props.holding.pnlPercent
                      .toString()
                      .charAt(0) === '-' ? (
                      <ForwardIcon
                        sx={{ transform: 'rotate(90deg)', fill: 'red' }}
                      />
                    ) : (
                      <ForwardIcon
                        sx={{ transform: 'rotate(-90deg)', fill: 'green' }}
                      />
                    )}
                  </div>
            </div>
          {/**  <div className='hide-pc'>  
              <p className='d-lg-none d-block '>
                {props.holding.pnlPercent &&
                props.holding.pnlPercent.toString().charAt(0) ===
                  '-' ? (
                  <div className='d-flex'>
                    <p className='text-danger text-light holding-percentage'>
                      {Number(props.holding.pnlPercent).toFixed(2)}%
                    </p>
                    <ForwardIcon
                      sx={{ transform: 'rotate(90deg)', fill: '#FE6244' }}
                    />
                  </div>
                ) : null}
              </p>
      
            </div> */}
            <div className='holding-all-btns'>
            {sellButton && (
              <div className='d-flex d-none d-md-flex justify-content-around  button  holding-btns'>
                <div className='col-6 col-md-2 sellandbuybtn '>{sellButton}</div>
                <div className='col-6 col-md-2 sellandbuybtn '>{buymoreButton}</div>
              </div>
            )}

            {squareoffButton && (
              <div className='d-flex  d-none d-md-flex justify-content-around  button holding-btns'>
                <div className='col-6 col-md-2 sellandbuybtn'>{squareoffButton}</div>
                <div className='col-6 col-md-2 p-0 sellandbuybtn'>
                  {shortsellmoreButton}
                </div>
              </div>
            )}

</div>


          </div>
         

          <div className='card-text d-flex info '>
            <div className='col-4 d-flex  justify-content-evenly  flex-md-row flex-column  first'>
              <div className='avg'>
                <div className='avgPrice'>
                  Avg. Price
                  <h6 className='avgPriceval'>
                    ₹{Number(props.holding.avgPrice).toFixed(2)}
                  </h6>
                </div>
                {/* <p className='avgPriceval'>₹{props.holding.avgPrice.toLocaleString()}</p> */}
              </div>
              <div className='avg'>
                <div className='avgPrice'>
                  Purchase Val.
                  <h6 className='avgPriceval'>
                    {' '}
                    ₹
                    {
                     props.holding.amount_invested.toFixed(2)
                   }
                  </h6>
                </div>
                {/* <p className='avgPriceval'>
                  ₹
                  {getPurchaseValue(
                    props.holding.quantity,
                    props.holding.avgPrice
                  ).toLocaleString()}
                </p> */}
              </div>
            </div>
            <div className='col-4 d-flex flex-md-row flex-column  justify-content-evenly second'>
              <div className='market '>
                <div className='avgPrice'>
                  Market Price
                  <h6 className='avgPriceval'>₹{Number(props.holding.ltp).toFixed(2)}</h6>
                </div>
                {/* <p className='avgPriceval'>₹{props.holding.avgPrice}</p> */}
              </div>
              <div className='market'>
                <div className='avgPrice'>
                  Market Val.
                  <h6 className='avgPriceval'>
                    {' '}
                    ₹
                    {props.holding.marketvalue.toFixed(2)}
                  </h6>
                </div>
                {/* <p className='avgPriceval'>
                  ₹
                  {calculateMarketValue(
                    props.holding.avgPrice,
                    props.holding.quantity
                  ).toLocaleString()}
                </p> */}
              </div>
            </div>
            <div className='col-4 d-flex  flex-md-row flex-column justify-content-evenly'>
              <div className=''>
                <div className='avgPrice'>
        
                  P&L{' '}
                  <h6 className='avgPriceval'>
                    <div className={`${ props.holding.profitloss.toFixed(2) < 0 ? "negative" : "positive"}`}>
                    ₹{props.holding.profitloss.toFixed(2)}
                    </div>
                  </h6>
                </div>
                {/* <p className='avgPriceval'>₹{calculateProfitLoss(props.holding)}</p> */}
              </div>
              <div className='qty-p'>
                <div className='avgPrice'>
                  Quantity{' '}
                  <h6 className='avgPriceval'>{props.holding.quantity}</h6>
                </div>
                {/* <p className='avgPriceval' >{props.holding.quantity}</p> */}
              </div>
       
            </div>
          </div>
          <div className='d-flex d-md-none action-btn-wrap'>
            <div className='sellandbuybtn'>{sellButton}</div>
            <div className='sellandbuybtn'>{buymoreButton}</div>
          </div>
          <div className='d-flex d-md-none   action-btn-wrap'>
            <div className='sellandbuybtn'>{squareoffButton}</div>
            <div className='sellandbuybtn'>{shortsellmoreButton}</div>
          </div>
        </div>
        {/* <hr className='individual-card'></hr> */}
      </div>
      {/* <hr className='individual-card'></hr> */}
    </div>
  );
};
export default HoldingRowDisplay;
