import { Image } from 'react-bootstrap';
import api from '../services/api';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';


const DisplayRow = (props) => {

  const [displayTaxesFees,setDisplayTaxesFees]= useState(false)
  const [taxesAndFees,setTaxesAndFees]= useState({})

  props.order.amount_invested = (props.order.limit_price)
                  ? Number(props.order.limit_price * props.order.quantity)
                  : Number(props.order.entryprice * props.order.quantity)

  const handleGetFeeAndTaxes = () => {
    const account_id = JSON.parse(localStorage.getItem('account_id'));
    const trade_id = props.order._id
    const inputs = {account_id:account_id, trade_id:trade_id}
    api
      .getTradeFeeAndTaxes(inputs)
      .then(({ data }) => {
      
        if (data.error) {
        
          console.log(data.error);
        
         
        
      
        }

        if (data.success && data.data) {
          setTaxesAndFees(data.data)
         setDisplayTaxesFees(true)
          //setSuccessMessage(data);
      
        
        }
      })
      .catch((err) => {
      
        console.log(err);
      
      });
    
  };

  const handleDisplayCard = (event) => {
   setDisplayTaxesFees(false)
   };

  let assetImage;
  assetImage = (
    <Image
      className='rounded w-[24px] h-[24px]'
      style={{ height: 28, paddingRight: 10, marginTop:2}}
      src={`https://storage.googleapis.com/stocks_logo/stocks/${props.order.symbol}.png`}
      loading='lazy'
    />
  );

  // if order type === limit then show limit_price otherwise show entryprice
  return (
    <div className='closed-holding'>
      {displayTaxesFees &&
      <>
     {/** TODO Display Taxes here  */}
        <div className=' tax-tooltip'>
        <h6 className=''>Total Taxes & Fee</h6>
          
        <div className="card-text d-flex closed-box">
   
          <div className="col-4 d-flex flex-column closed-top align-items-center1 flex-column">
            <div className="text-center">
              <p className="closed-label">Brokerage</p>
              <p className="closed-value">{Number(taxesAndFees.brokerage).toFixed(2)}</p>
              </div>
              <div className="text-center">
                <p className="closed-label">STT Tax</p>
                <p className="closed-value date-text">{Number(taxesAndFees.stt_tax).toFixed(2)}</p>
                </div>
                </div>
                
                <div className="col-4 d-flex flex-column  closed-top align-items-center1 flex-column">
                  <div className="text-center">
                    <p className="closed-label">Transaction Tax</p>
                    <p className="closed-value">{Number(taxesAndFees.exchange_txn_tax).toFixed(2)}</p>
                    </div>
                    
                    <div className="text-center">
                      <p className="closed-label">GST Tax</p>
                      <p className="closed-value date-text">{Number(taxesAndFees.gst_tax).toFixed(2)}</p>
                      </div>
                </div>
                      
                <div className="col-4 d-flex flex-column  closed-top align-items-center1 flex-column">
                  <div className="text-center">
                    <p className="closed-label">SEBI Tax</p>
                    <p className="closed-value ">{Number(taxesAndFees.sebi_tax).toFixed(2)}</p>
                  </div>
                  
                  <div className="text-center tax-btn">
                    <p className="closed-label">Stamp Duty Tax</p>
                    <p className="closed-value date-text">{Number(taxesAndFees.stamp_duty_tax).toFixed(2)}</p>
                  </div>
                </div>
                {/* {taxesAndFees.stt_tax}, {taxesAndFees.exchange_txn_tax} */}
              </div>
        
        <span className='name-header' onClick={handleDisplayCard} > <CloseIcon/> </span>
        </div>
      </>
      }

 {!displayTaxesFees && 
      <div className='card mb-2 closed-box'>
  
        <div className='card-body'>
          <div className='executed-stock'>
           {/**<div className='d-flex'>{assetImage}</div> */} 
            <div className='executed-part'>
            <h6 className='card-subtitle text-muted holding-subtitle'>{props.order.asset_name}
            
            {  (props.order.asset_name).includes("CE") || (props.order.asset_name).includes("PE") || (props.order.asset_name).includes("FUT") ? 
             <></>
             :
             <> ({props.order.symbol}) </>  }
            
            </h6>
            
            {/**<h6 className='card-title executed-title'>{props.order.asset_name} </h6>**/ }
            </div>

            <div className='tags-badges'>
            <div className='mob_set o-type'>
            
            {/** <span className='badge bg-opacity-25 text-warning border-warning  text-bg-warning  limit-btn pending-limit_btnn'>   </span> **/}
             
              <span className='flash-message'>  {' '}{props.order.orderPriceType} </span>
         
          </div>
          <div className='mob_set o-type'>
            
            {/** <span className='badge bg-opacity-25 text-warning border-warning  text-bg-warning  limit-btn pending-limit_btnn'>  </span>**/}
            <span className='flash-message'>
              {' '}
              {props.order.order_time_type}
              </span>
          </div>
            <div className='mob_set lp-type'>
            
              {props.order.transactionType === 'buy' ? (
                <div className='executed-buy-btn '>
                  Buy
                </div>
              ) : (
                <div className='executed_shoret-cell'>
                  Short sell
                </div>
              )}
           
            </div>
                </div>
          </div>
          {props.order.status === "cancelled" && <div className="flash-message cancelled">Cancelled</div>}
     
         {/**  <div className='card-text d-flex holding-detail'>
        windowSize.width > 1000 && <div className='col-4 d-flex justify-content-center align-items-center flex-column hide-mbl'>
              <p className='executed-label'>Executed Date</p>
              <p className='executed-value'>{props.order.entryTimeString}</p>
              </div>

            <div className='col-4 d-flex align-items-center flex-column'>
            <div className='text-center'>
              <p className='executed-label'>Quantity</p>
              <p className='executed-value'>{props.order.quantity}</p>
              </div>
          
            <div className='text-center'>
                  <p className='executed-label'>Ordered At</p>
                  <p className='executed-value'>{props.order.entryTimeString}</p>
                </div>
           </div>
            <div className='col-4 d-flex align-items-center flex-column'>
            <div className='text-center'>
              <p className='executed-label'>Price</p>
              <p className='executed-value'>₹{props.order.entryprice}</p>
              </div>
              <div className='text-center'>
                <p className='executed-label'>Executed At</p>
                  <p className='executed-value'>{props.order.executionTimeString}</p>
                </div>
            </div>
            <div className='col-4 d-flex align-items-center flex-column'>
            <div className='text-center'>
              <p className='executed-label'>Amount</p>
              <p className='executed-value'>
                ₹
                {props.order.limit_price
                  ? props.order.limit_price
                  : props.order.entryprice * props.order.quantity}
              </p>
              </div>
              <div className='text-center'>
                  <p className='executed-label'>Entry Date</p>
                  <p className='executed-value'>{props.order.entryTimeString}</p>
                </div>
            </div>
          </div>**/} 

          <div className='card-text d-flex closed-box'>
              <div className='col-4 d-flex flex-column closed-top align-items-center1 flex-column'>
                <div className='text-center'>
                  <p className='closed-label'>Quantity</p>
                  <h6 className='closed-value'>{props.order.quantity}</h6>
                </div>
                <div className='text-center'>
                  <p className='closed-label'>Ordered At</p>
                  <h6 className='closed-value date-text'>{props.order.entryTimeString}</h6>
                </div>
              </div>
              <div className='col-4 d-flex flex-column  closed-top align-items-center1 flex-column'>
                <div className='text-center'>
                <p className='closed-label'>Price</p>
                  <h6 className='closed-value'>₹{Number(props.order.entryprice).toFixed(2)}</h6>
                </div>
                <div className='text-center'>
                <p className='closed-label'>Executed At</p>
                  <h6 className='closed-value date-text'>{props.order.executionTimeString}</h6>
                </div>
              </div>
              <div className='col-4 d-flex flex-column  closed-top align-items-center1 flex-column'>
                <div className='text-center'>
                  <p className='closed-label'>Amount</p>
                  <h6 className='closed-value '>₹
              {Number(props.order.amount_invested).toFixed(2)}</h6>
                </div>
                <div className='text-center tax-btn' onClick={() => handleGetFeeAndTaxes()}>
                <p className='closed-label'>Fee/Taxes</p>
                {  props.order.total_charges_taxes ? 
                  <h6 className='closed-value date-text'><a className="tax-link">{Number(props.order.total_charges_taxes).toFixed(2)}</a></h6>
                :
                <h6 className='closed-value date-text'> 0.00</h6>
                }
                  </div>
              </div>
            </div>


  

        </div>
      </div>
}
    </div>
  );
};
export default DisplayRow;
