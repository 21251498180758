import { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';  
const FlashMessage = ({ setFlashMessage, flashMessage }) => {
  const [show, setShow] = useState(true)

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)
      setFlashMessage({})
    }, 5000)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  const handleFlashMessageClear = (event) => {
    setFlashMessage({})
  };

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }

  // If show is true this will be returned
  return (
    <div className={`flash-message flash-msg-wrap ${flashMessage.color}`}>
      <div className={flashMessage.color}></div>
      {flashMessage.message} 

      {flashMessage.belowLine && <div>below line  {flashMessage.belowLine}</div>}
      <div className='name-header' onClick={handleFlashMessageClear} >
      <CloseIcon/>
            </div>
    </div>
  )
}

//FlashMessage.defaultPros = {
  //variant: 'info',
//}

export default FlashMessage;