
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

  
function ExpiryMonthDisplay(props) {

  let toshow_expiry_date =  (props.siteObject.toshow_expiry_date) ? props.siteObject.toshow_expiry_date : (JSON.parse(localStorage.getItem("siteObject")).toshow_expiry_date);
  let selectedExpiryMonth =  (props.selectedExpiryMonth) ? props.selectedExpiryMonth: (localStorage.getItem("selectedExpiryMonth"));



  return (
    <>
   {/**  <div className='d-flex justify-content-between d-block-list assest-padding'>

     <p  className={`${
          props.selectedExpiryMonth === "5"? "active-btn" : null
        }  btn `}
        onClick={() => props.setSelectedExpiryMonth(5)} ><button>30 May</button> </p>
      <p className='name-header' onClick={() => props.setSelectedExpiryMonth(6)} ><button>27 June</button> </p>
      <p className='name-header'   onClick={() => props.setSelectedExpiryMonth(7)} ><button>25 July</button> </p>
 }


          props.siteObject.toshow_expiry_date
            .map((expiry_date, _id) => (
              
              <p className={`${
                (props.selectedExpiryMonth == Number(new Date().getMonth())) ? "active-btn" : null
              }  btn name-header`} onClick={() => props.setSelectedExpiryMonth(Number(new Date(expiry_date).getMonth()))} ><button>{(new Date(expiry_date).getDate()) + new Date(expiry_date).toLocaleString("default", { month: "short" })}</button> </p>
            ))

   
    </div> **/}


    <div className='d-flex justify-content-between d-block-list assest-padding'>

    <Stack direction="row" spacing={2}>
    <div className='expiry-date'>{"Expiry:"}</div>
{
      toshow_expiry_date
         .map((expiry_date, _id) => (
           
           <Chip 
           key={_id}
           variant={(selectedExpiryMonth ==  Number(new Date(expiry_date).getMonth())  ) ? "filled" : "outlined"}
         
           size="small" color='primary' clickable  onClick={() => props.setSelectedExpiryMonth(Number(new Date(expiry_date).getMonth()))} label={(new Date(expiry_date).getDate()) + new Date(expiry_date).toLocaleString("default", { month: "short" })}
           
           
           />
         )
         
         )}
         </Stack>




 </div>

   

</>
  );
}

export default ExpiryMonthDisplay;
