import { createContext, useState, useEffect } from 'react';


import api from '../services/api';


import PendingOrderRowDisplay from '../components/PendingOrderRowDisplay';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Tab, Tabs } from '@mui/material';
import HeaderShowHide from '../components/HeaderShowHide';
import LengthDisplayCircle from '../components/LengthDisplayCircle';


import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import SortIcon from '@mui/icons-material/Sort';
import CloseIcon from '@mui/icons-material/Close';


const PendingOrders = (props) => {
 
  console.log("calling Pending order componenet")

 
  const [tabValue, setTabValue] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  const [selectedMenu, setSelectedMenu] = useState('quantity');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //let subscribeSymbols = []



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSearchClear = (event) => {
    setSearchValue('')
   };
   const handleSort = (sortName, sortProperty,sortDirection) => {
    setSelectedMenu(sortName)
   
      const sorted = [...props.pendingOrders]; // create a new array & not mutate state
  
      sorted.sort((a, b) => {
        return sortDirection === 1 ? a[sortProperty] - b[sortProperty] : b[sortProperty] - a[sortProperty];
      });
      props.setPendingOrders(sorted); //re-render
      handleClose()
    
   };

   const options = [
  
    {option_name: "Amount Invested (Low to High)", option_property:"amount_invested" , sort_order:1},
    {option_name: "Amount Invested (High to Low)", option_property:"amount_invested", sort_order:-1},
    {option_name: "Date (Ascending)", option_property:"entryTimeString" , sort_order:1},
    {option_name: "Date (Descending)", option_property:"entryTimeString" , sort_order:-1},
    {option_name: "Quantity (Low to High)", option_property:"quantity" , sort_order:1},
    {option_name: "Quantity (High to Low)", option_property:"quantity" , sort_order:-1},
    
  ];




  useEffect(() => {
  
    let account_id = JSON.parse(localStorage.getItem('account_id'));
  
    let customerInfo = {
    
      account_id: account_id,
      status: 'pending',
    };
    setLoading(true)
    api
      .getPendingOrders(customerInfo)
      .then(({ data }) => {
        if(data.success){
          props.setPendingOrders(data.orders_array.orders);
          props.setPendingOrdersCount(data.orders_array.orders.length)
          //props.setPendingOrdersAssetArray(data.pendingOrdersAssetArray)
          setLoading(false)
  
          //subscribeSymbols = data.orders_array.orders.map(object => object.symbol);
          props.setSubscribeSymbols(data.orders_array.orders.map(object => object.symbol))
          
          //let subscribeMessage = {"action":"subscribe", "instruments":subscribeSymbols}
         // props.sendWebsocketMessageToServer(subscribeMessage)
        }
       
      })
      .catch((error) => {
        console.log(error);
      });


     /* return () => {
        let unsubscribeMessage = {"action":"unsubscribe", "instruments":subscribeSymbols}
        props.sendWebsocketMessageToServer(unsubscribeMessage)
    }*/


  }, [props.refresh]);

  return (
    <>
    {loading ?    <div className="loader-image"><img src='/images/loader.gif'/></div>  :  
    <div className='container tab-btns holding-wrap pending-wrap'>
      <div className='order-open'>
       
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor='inherit'
            aria-label='basic tabs example'
            className='tabs'
          >
            <Tab
              value={0}
              onClick={() => {
             
                props.setDisplayPage('PendingOrders');
              }}
              label={<LengthDisplayCircle label="Open" count={props.pendingOrdersCount}/>}
            />
            <Tab
              value={1}
              onClick={() => {
                  props.setDisplayPage('ExecutedOrders');
              }}
              label='Executed'
            />
        <HeaderShowHide hideHeader={props.hideHeader} setHideHeader={props.setHideHeader}/>
          </Tabs>
   
        </Box>
   
      </div>

      <>
      <div className='d-flex align-items-baseline justify-content-between search-main-orders'>
            <div className='search-box'>
              <input
                type='text'
                className='search-bar'
                name='search'
                placeholder='Search'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span>
                <SearchIcon />
              </span>

              {searchValue  && <span className='name-header' onClick={handleSearchClear} >  <CloseIcon/> </span>}
            </div>
            <div className='ms-1'>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <SortIcon sx={{ fill: 'white' }} />
              </IconButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disabled>Sort By</MenuItem>
                {options.map((option) => (
                  <MenuItem
                    key={option.option_name}
                    onClick={() => {
                      handleSort(option.option_name,option.option_property,option.sort_order)
                    }}
                  >
                    <div className='d-flex justify-content-between w-100'>
                      <span>{option.option_name}</span>
                      <span>
                        {selectedMenu === option.option_name ? (
                          <CheckCircleTwoToneIcon
                            sx={{ fill: 'green', marginLeft: '10px' }}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </div> 
          </div>
        </>

        {props.pendingOrders.length == 0 && 
         <div className='d-flex d-block-list'>
          
            <p className='name-header'>No Open Orders. Place Order From Market Tab</p>
            
          </div>
          
          }

      <div className='tabs-box'>
        {props.pendingOrders &&
          props.pendingOrders
            .filter((pendingOrder) =>
              pendingOrder.symbol.match(new RegExp(searchValue, 'i'))
            )
            .map((pendingOrder) => (
              <PendingOrderRowDisplay
                order={pendingOrder}
                key={pendingOrder._id}
                setPendingOrdersCount={props.setPendingOrdersCount}
              />
            ))}
      </div>
    </div>
}
</>
  );
};

export default PendingOrders;
