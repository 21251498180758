import { useNavigate,Navigate } from "react-router-dom";
import moment from 'moment-timezone';

function RequireAuth({ children }) {

  const expirationtime = localStorage.getItem('expirationtime') ? moment(localStorage.getItem('expirationtime') ) : moment().subtract(1,"seconds")
  const currentTime = moment()
  console.log(currentTime);
  console.log(expirationtime);


    const isLoggedIn =  currentTime.isAfter(expirationtime) ? false : true


    return isLoggedIn ? children : <Navigate to="/login_register" replace />;
  }

  export default RequireAuth