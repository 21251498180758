import { useState, useContext, useEffect } from 'react';
import AssetRow from './AssetRow';


import { UserContext } from '../contexts/UserContext';

import SearchIcon from '@mui/icons-material/Search';



import IconButton from '@mui/material/IconButton';

import SortIcon from '@mui/icons-material/Sort';
import api from '../services/api';
import HeaderShowHide from './HeaderShowHide';
import StockOptionsBreadcrumb from './StockOptionsBreadcrumb';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';


  
function AssetsDisplayWatchList({
  refresh,
  doRefresh,
  displayPage,
  setDisplayPage,
  previousPage,
  setPreviousPage,
  watchlistarray,
  setWatchListArray,
  setSearchResultsArray,
  setParentSearchValue,
 selectedAsset,
 setSelectedAsset,
 handleDetailsPageOpen,
 handleSelectionChange,
 removeInstrumentFromWatchlist,
 sendWebsocketMessageToServer,
 subscribeSymbols,
 setSubscribeSymbols,
 hideHeader,
 setHideHeader
}) {

 
  
  const [selectedMenu, setSelectedMenu] = useState('All Holdings');
  const [anchorEl, setAnchorEl] = useState(null);
  const percentChangeDisplayText = '24h changes';
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);


  //let subscribeSymbols = []


  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 
   const handleSort = (sortName, sortProperty,sortDirection) => {
    setSelectedMenu(sortName)
   
      const sorted = [...watchlistarray]; // create a new array & not mutate state
  
      sorted.sort((a, b) => {
        return sortDirection === 1 ? a[sortProperty] - b[sortProperty] : b[sortProperty] - a[sortProperty];
      });
      setWatchListArray(sorted); //re-render
      handleClose()
    
   };

  
  const options = [
    {option_name: "Price (Low to High)", option_property:"price" , sort_order:1},
    {option_name: "Price (High to Low)", option_property:"price" , sort_order:-1},
    {option_name: "Price Change(Low to High)", option_property:"priceChange" , sort_order:1},
    {option_name: "Price Change (High to Low)", option_property:"priceChange" , sort_order:-1},
    {option_name: "Percentage Change (Low to High)", option_property:"percentage" , sort_order:1},
    {option_name: "Percentage Change (High to Low)", option_property:"percentage" , sort_order:-1},

    
  ];

  useEffect(() => {

    if(previousPage !== "SearchResults"){
      console.log("inside +"+previousPage)
    setLoading(true)
    // initializeCurrentPriceArray();
    api
    .getLatestRatesForWatchList("WatchList")
    .then(({ data }) => {
      setWatchListArray(data);
      setLoading(false)
      setSubscribeSymbols(data.map(a => a.symbol))
     // subscribeSymbols = data.map(a => a.symbol)
     // let subscribeMessage = {"action":"subscribe", "instruments":subscribeSymbols}
     // sendWebsocketMessageToServer(subscribeMessage)
    })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    }); 
  } else {
    setLoading(false)
    setSubscribeSymbols(watchlistarray.map(a => a.symbol))
   // subscribeSymbols = watchlistarray.map(a => a.symbol)
   // let subscribeMessage = {"action":"subscribe", "instruments":subscribeSymbols}
   // sendWebsocketMessageToServer(subscribeMessage)
  }
    
   
   
    return () => {
      //  let unsubscribeMessage = {"action":"unsubscribe", "instruments":subscribeSymbols}
      // sendWebsocketMessageToServer(unsubscribeMessage)
      
  }

   }, [displayPage,refresh]);




  {/**useEffect(() => {
      

    //  startUpdatingCurrentPriceUsingWebsocket(); 
    if(localStorage.getItem('instruments') !== null){
      let subscribeMessage = {"action":"subscribe", "instruments":JSON.parse(localStorage.getItem('instruments'))}
      sendWebsocketMessageToServer(subscribeMessage)
      
    }
  
  
    return () => {
      if(localStorage.getItem('instruments') !== null){
        let unsubscribeMessage = {"action":"unsubscribe", "instruments":JSON.parse(localStorage.getItem('instruments'))}
       sendWebsocketMessageToServer(unsubscribeMessage)
      }
  }

  }, []);**/}

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
   
      // Send Axios request here
     // searchInstruments(token,searchValue)

     if(searchValue.length >= 3){
      api
      .searchInstruments(searchValue.toUpperCase())
      .then(({ data }) => {
        setSearchResultsArray(data);
        setParentSearchValue(searchValue)
        setDisplayPage("SearchResults")
      })
      .catch((error) => {
        console.log(error);
      });
      }

    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchValue])

 







  
 

  

  return (
    <>
  {loading ?    <div className="loader-image"><img src='/images/loader.gif'/></div>  :  
      <div className='' id='tradeView'>
        
      <StockOptionsBreadcrumb setDisplayPage={setDisplayPage} displayPage={displayPage}/>
      
        {(
          <>
             <div className='d-flex align-items-baseline justify-content-between search-main'>
            <div className='search-box'>
              <input
                
                type='text'
                className='search-bar'
                name='search'
                placeholder='Search eg: tcs, tcs fut, tcs 4000 ce'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                
              />
              <span>
                <SearchIcon />
              </span>
             
            </div>
        
            
            
            <div className='ms-1'>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <SortIcon sx={{ fill: 'white' }} />
              </IconButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disabled>Sort By</MenuItem>
                {options.map((option) => (
                  <MenuItem
                    key={option.option_name}
                    onClick={() => {
                      handleSort(option.option_name,option.option_property,option.sort_order)
                    }}
                  >
                    <div className='d-flex justify-content-between w-100'>
                      <span>{option.option_name}</span>
                      <span>
                        {selectedMenu === option.option_name ? (
                          <CheckCircleTwoToneIcon
                            sx={{ fill: 'green', marginLeft: '10px' }}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </div> 

            <HeaderShowHide hideHeader={hideHeader} setHideHeader={setHideHeader}/>
            </div>
    
          </>
        )}

        { /*(
          <div className='d-flex justify-content-between d-block-list assest-padding'>
            <p className='name-header'>Name</p>
            <p className='name-header'>Price, {percentChangeDisplayText}</p>
          </div>
        )*/}

        {watchlistarray && watchlistarray.length == 0 && 
         <div className='d-flex d-block-list'>
          
            <p className='name-header'>Search Instruments Above and Add to WatchList</p>
            
          </div>
          
          }

        {watchlistarray && watchlistarray.length > 0 &&
          watchlistarray
            .map((assetRow, index) => (
              <AssetRow
                assetRow={assetRow}
                index={index}
                key={assetRow.symbol}
                displayPage={displayPage}
                setDisplayPage={setDisplayPage}
                setSelectedAsset={setSelectedAsset}
                handleSelectionChange={handleSelectionChange}
                removeInstrumentFromWatchlist={removeInstrumentFromWatchlist}
                watchlistarray={watchlistarray}
                handleDetailsPageOpen={handleDetailsPageOpen}

              />
            ))}
      
      </div>
       }
     
    </>
  );
}

export default AssetsDisplayWatchList;
