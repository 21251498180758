
import React from "react";

import { Button } from "react-bootstrap";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState, useEffect } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from "../services/api";
import {  useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
export default function ForgotPassword() {

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);


  const navigate = useNavigate();
    const [forgotPasswordInfo, setForgotPassswordInfo] = useState({

      });
      const [showOTPField, setShowOTPField] = useState(false)
      const [showChangePassword, setShowChangePassword] = useState(false)
      const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(true)
    
     
      const [isResendOTPButtonVisible,setIsResendOTPButtonVisible]= useState(false)
  
  const [timeLeft, setTimeLeft] = useState(null);

      function updateState(event) {
        const { name, value } = event.target;
        setForgotPassswordInfo((prevState) => ({ ...prevState, [name]: value }));
      }
    

  
  const [errorMessage, setErrorMessage] = useState("");
  const [submissionMessage, setSubmissionMessage] = useState("");
  

  useEffect(() => {
    if(timeLeft===0){
    
       setIsResendOTPButtonVisible(true)
       setTimeLeft(null)
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  
 
  function sendOTPToPhone(event) {
   
    event.preventDefault();
  
    
    api
      .sendOTPToPhone(forgotPasswordInfo)
      .then(({ data }) => {
        if(data.error){
          setSubmissionMessage("")
            setErrorMessage(data.error);
            return;
          }
          if(data.success){
            setErrorMessage("");
            setForgotPassswordInfo((prevState) => ({ ...prevState, verification_key: data.verification_key }));
            setShowForgotPasswordForm(false)
            setShowOTPField(true)
            setIsResendOTPButtonVisible(false)
            setTimeLeft(5)
            setSubmissionMessage("We've sent OTP to your phone")
          }
      
        

      })
      .catch((error) => {
        console.log(error);
        setSubmissionMessage("")
        setErrorMessage(error);
      });
    
  }

  function verifyPhoneOTP(event) {
   
    event.preventDefault();
    
    api
      .verifyPhoneOTP(forgotPasswordInfo)
      .then(({ data }) => {
        if(data.error){
          setSubmissionMessage("")
            setErrorMessage(data.error);
            return;
          }
          if(data.success){
           setErrorMessage("");
           setSubmissionMessage("Please set new password below")
           setShowOTPField(false)
           setShowChangePassword(true)

          }
      
        

      })
      .catch((error) => {
        console.log(error);
        setSubmissionMessage("")
        setErrorMessage(error);
      
      });
    
  }
 

  function updatePassword(event) {
   
    event.preventDefault();
   
    
    api
      .updatePassword(forgotPasswordInfo)
      .then(({ data }) => {
        if(data.error){
          setSubmissionMessage("")
            setErrorMessage(data.error);
            return;
          }
          if(data.success){
            navigate('/login',{state:{display_message:"Password Successfully changed Please login with New Password"}});
          }
      
        

      })
      .catch((error) => {
        console.log(error);
        setSubmissionMessage("")
        setErrorMessage(error);
      
      });
    
  }
 
  // Password Show and hide
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };
  // Password Show and hide


  return (

    <div
    className="bg-dark text-white px-4 registration"
    style={{ minHeight: "100vh", paddingTop: "200px" }}
  >
   
  

   <div className="login-form">

    <button className="login-back-btn"><ArrowBackIcon/> Back</button>

      <h4 className="mb-5">Restore Password</h4>

    </div>

    <div className="login-form">
      <span className="sent-otp-msg">
        {submissionMessage}
      </span>
      </div>

   
  
  
  <div>

 
  

    <div><span className="error-msg login-form mb-2">{errorMessage}</span></div>


    <div className="login-form">

        <form>
        {showForgotPasswordForm &&  <div>
          <div className="otp-text pt-2">
     
     Please enter Phone number to receive OTP 
   </div>
        <div className="d-block phone-field">
            <label>Phone Number</label>            
            <span className="phone-f">+91</span>

            <input
              className="d-block p-1 bg-light rounded"
              name="phoneNumber"
              type="number"
              onChange={updateState}
              value={forgotPasswordInfo.phoneNumber}
             
              required
            />
          </div>
      
          <Button
            variant="contained"
            className="btn btn-primary main-btn reg-btn"
            onClick={(event) => sendOTPToPhone(event)}
          
          >
            Restore
          </Button>
          </div> }

          {showOTPField &&        <div>
        OTP
    <div className="d-block mt-2">
          <input
            name="otp"
            className="d-block p-1 bg-light rounded"
          
            label="otp"
            type="text"
            placeholder="OTP"
            onChange={updateState}
            value={forgotPasswordInfo.otp}
            
          /> </div> 



<div className="resent-row">
<span className="sent-otp my-3" style={{display: "block"}}><CheckCircleIcon/> OTP Sent</span> 
<span className="resendd">{!isResendOTPButtonVisible && <span className="resending-line">
       Resend OTP in  {timeLeft} seconds
      </span>}
{isResendOTPButtonVisible && 
<span onClick={(event) => sendOTPToPhone(event) } className="resend-link">Resend OTP</span>}</span>
</div>  

      <div className="otp-btn-wrap row align-items-center">


<div className="col-md-6 col-6">
        <a
            variant="contained"
            className="btn btn-primary main-btn resend-btn"
            onClick={(event) => verifyPhoneOTP(event)}
            
          >
            Back
          </a>
          </div>
<div className="col-md-6 col-6">
        <Button
            variant="contained"
            className="btn btn-primary main-btn continue-btn"
            onClick={(event) => verifyPhoneOTP(event)}
            
          >
            Continue
          </Button>
          </div>
          </div>

    </div> }



{ showChangePassword && <div>
  <div className="mt-2 password-field">
  <label>Password</label> 
    <input
            name="newPassword"
              className="rounded"
         
            label="Password"
            type={passwordVisible ? "text" : "password"}
          
            onChange={updateState}
            value={forgotPasswordInfo.newPassword}
        
          />
              <span className="password-v" onClick={togglePasswordVisibility}>
        {passwordVisible ? <VisibilityIcon/> : <VisibilityOffIcon/>} 
      </span>
</div>

<div className="mt-2 password-field">
<label>Confirm Password</label>
<input
            name="confirmPassword"
      
         className="rounded"
            label="confirmPassword"
            type={passwordVisible2 ? "text" : "password"}
          
              onChange={updateState}
              value={forgotPasswordInfo.confirmPassword}
        
          />
              <span className="password-v" onClick={togglePasswordVisibility2}>
        {passwordVisible2 ? <VisibilityIcon/> : <VisibilityOffIcon/>} 
      </span>
</div>

          <Button
            variant="contained"
            className="btn btn-primary main-btn main-btn changed-pass"
            onClick={(event) => updatePassword(event)}
          >
            Change Password
          </Button>
          </div> }

        </form>
      </div>

      </div> 


      
    </div>
  );
}
